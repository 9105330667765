import { DatabaseRecord } from "../mixins/database-record";

export interface LandingPageItem {
  action: 'embed' | 'email' | 'redirect';

  /**
   * Only available for action === 'email'
   */
  advisor_emailTemplate?: string;

  /**
   * Only available for action === 'email'
   */
  advisor_url?: string;

  /**
   * Only available for action === 'email'
   */
  client_emailTemplate?: string;

  /**
   * Only available for action === 'email'
   */
  client_url?: string;

  /**
   * Only available for action === 'email'
   */
  emailTemplate?: string;

  /**
   * Displayed to the user
   */
  description: string;

  /**
   * Displayed to the user
   */
  duration: string;

  /**
   * Only available for action === 'embed'
   */
  embed?: string;

  /**
   * Displayed to the user
   */
  people: string;

  /**
   * Only available for action === 'email'
   */
  show_dropdown?: boolean;

  /**
   * The title of the item. Displayed to the user
   */
  title: string;

  /**
   * Only available for action === 'email' or 'redirect'
   */
  new_tab?: boolean;

  /**
   * Only available for action === 'email' or 'redirect'
   */
  url?: string;
}

export class LandingPage extends DatabaseRecord() {
  public back_button = false;
  public public = false;
  public title = false;

  public label = '';
  public group = '';
  public needle = '';
  public items: LandingPageItem[] = [];
  public landing_page_gateway = false;
  public gateway?: LandingPageGateway;
  public landing_page_gateway_id = 0;

  constructor(data?: LandingPage) {
    super(data);
    Object.assign(this, data);
  }
}

export class LandingPageGateway extends DatabaseRecord() {
  public gateway_title = '';
  public group = '';
  public gateway_button_text = '';
  public modal_title = '';
  public modal_body = '';
  public must_accept_terms = false;
  public bypass_modal = false;
  public disable_header_and_footer = false;

  constructor(data?: LandingPageGateway) {
    super(data);
  }
}
