import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, of, tap, throwError } from 'rxjs';
import { SharedFormService } from './shared-form.service';

@Injectable()
export class FormInterceptor implements HttpInterceptor {
  constructor(private formService: SharedFormService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    // Only intercept the request if the form is currently submitting
    if (this.formService.onStatusChange$.value) {
      return next.handle(request).pipe(
        catchError((error: HttpErrorResponse) => {
          this.formService.onStatusChange$.next(undefined);

          return throwError(() => new Error(error.message));
        }),

        // If the request has ended, then we want to emit that in the Service
        tap((request) => {
          this.formService.onStatusChange$.next(undefined);
          return of(request);
        })
      );
    }

    return next.handle(request);
  }
}
