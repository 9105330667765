import { HasSubscriptionsToClose, SafePipe, scopes } from '@agingplan';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { AfterViewInit, Component, ElementRef, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { SafeStyle } from '@angular/platform-browser';
import { ActivationEnd, NavigationEnd, Router } from '@angular/router';
import { Subject, firstValueFrom } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { CommonService, Disclosure } from 'src/app/services/common.service';
import { environment } from 'src/environments/environment';

/**
 * FooterComponent handles the application's footer display and functionality.
 * It manages footer visibility based on routes, displays disclosures for advisor portals,
 * and handles dynamic styling including background images.
 */
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent extends HasSubscriptionsToClose() implements AfterViewInit, OnInit, OnDestroy {
  /** Background image styling for the footer */
  @HostBinding('style') backgroundImage: SafeStyle | undefined;

  /** Controls the display property of the footer */
  @HostBinding('style.display') display = 'none';

  /** Environment configuration reference */
  public readonly env = environment;

  constructor(
    private readonly router: Router,
    private readonly elementRef: ElementRef,
    private readonly safePipe: SafePipe,
    private readonly http: HttpClient,
    private readonly authService: AuthenticationService,
    public readonly commonService: CommonService,
  ) {
    super();
    this.initializeBackgroundImage();
    this.setupRouteListener();
  }

  /**
   * Initializes the footer's background image using the environment configuration
   */
  private initializeBackgroundImage(): void {
    const path = `${environment.google.bucket}/assets/linen-texture/Linen_Black.png`;
    this.backgroundImage = this.safePipe.transform(`background-image: url('${path}')`, 'style');
  }

  /**
   * Sets up the route listener to handle footer visibility based on current route
   */
  private setupRouteListener(): void {
    this.router.events
      .pipe(
        filter((event): event is NavigationEnd => event instanceof NavigationEnd),
        takeUntil(this.destroy$),
      )
      .subscribe((event: NavigationEnd) => {
        const url = event.urlAfterRedirects;
        this.display = this.shouldDisplayFooter(url) ? 'block' : 'none';
      });

    // Initial check for advisor portal
    if (this.router.url.includes('advisor-portal')) {
      this.display = 'block';
    }
  }

  /**
   * Determines if the footer should be displayed based on the current URL
   * @param url - Current route URL
   * @returns boolean indicating if footer should be displayed
   */
  private shouldDisplayFooter(url: string): boolean {
    return !url.includes('admin-portal') && !url.includes('our-data');
  }

  ngOnInit(): void {
    this.setupDisclosureListener();
  }

  /**
   * Sets up the disclosure listener for advisor portal routes
   */
  private setupDisclosureListener(): void {
    this.subscribeTo(
      this.router.events
        .pipe(
          filter((event): event is ActivationEnd => event instanceof ActivationEnd),
          takeUntil(this.destroy$),
        )
        .subscribe((event: ActivationEnd) => {
          this.handleDisclosureRouting(event);
        }),
    );
  }

  /**
   * Handles disclosure routing based on route parameters
   * @param event - ActivationEnd event containing route information
   */
  private handleDisclosureRouting(event: ActivationEnd): void {
    const { article_id, disclosure } = event.snapshot.params;

    if (!article_id) return;

    if (window.location.href.includes('advisor-portal')) {
      const user = this.authService.user.getValue();
      if (!user?.portal?.id) {
        console.error('User portal ID not found');
        return;
      }
      this.getDisclouse(user.portal.id);
    } else if (disclosure) {
      const portalId = this.getPortalIdFromDisclosure(disclosure);
      if (portalId !== -1) {
        this.getDisclouse(portalId);
      }
    }
  }

  /**
   * Gets the portal ID based on the disclosure parameter
   * @param disclosure - Disclosure parameter from route
   * @returns Portal ID or -1 if not found
   */
  private getPortalIdFromDisclosure(disclosure: string): number {
    return scopes.findIndex((scope) => disclosure.toLowerCase() === scope.label?.toLowerCase());
  }

  /** Returns the current year as a string */
  public get year(): string {
    return new Date().getFullYear().toString();
  }

  /**
   * Fetches advisor portal disclosure information
   * @param portalId - Portal ID to fetch disclosure for
   */
  private async getDisclouse(portalId: number): Promise<void> {
    try {
      const disclosure = await firstValueFrom(this.http.get<Disclosure>(`${environment.api_url}/advisor-portals/disclosure/${portalId}`));
      this.commonService.footer_disclosure = disclosure;
    } catch (error) {
      const errorMessage = error instanceof HttpErrorResponse ? `Error fetching disclosure: ${error.message}` : 'Unknown error occurred while fetching disclosure';
      console.error(errorMessage, error);
    }
  }

  ngAfterViewInit(): void {
    this.updateFooterHeight();
  }

  /**
   * Updates the footer height CSS variable
   */
  private updateFooterHeight(): void {
    const height = this.elementRef.nativeElement.offsetHeight;
    document.documentElement.style.setProperty('--footer-height', `${height}px`);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
