import { Component, EventEmitter, HostBinding, Input, OnDestroy, Output } from '@angular/core';
import { PopoverMenuService } from '../../popover.service';

/**
 * IFrameComponent provides a popover container for embedding external content via iframe
 * with configurable positioning, animations, and navigation controls.
 */
@Component({
  selector: 'popover-iframe',
  templateUrl: './iframe.component.html',
  styleUrls: ['./iframe.component.scss', './../../scss/global.scss', './../../scss/container.scss'],
})
export class IFrameComponent implements OnDestroy {
  /** Tracks the visibility state of the popover */
  public visible = false;

  /** Stores event listener cleanup functions */
  private cleanupFns: (() => void)[] = [];

  /**
   * Sets the top spacing to accommodate navbar if needed
   * @param top - The spacing in pixels to set from the top
   */
  @Input() set navbar_spacing(top: number) {
    this.top = `${top}px`;
  }

  /**
   * Controls visibility of close button
   * @default true
   */
  @Input() show_close_button = true;

  /**
   * Sets the box shadow style
   * @default 'none'
   */
  @Input() shadow = 'none';

  /**
   * Sets the slide animation direction
   * @default 'slide-left'
   */
  @Input() slide: 'slide-up' | 'slide-left' | 'slide-down' | 'slide-right' = 'slide-left';

  /**
   * Binds to host element's top CSS property
   */
  @HostBinding('style.top') top = '0';

  /**
   * The URL to be loaded in the iframe
   */
  @Input() iframe_url?: string;

  /**
   * Emits when the popover opens
   */
  @Output() onOpen: EventEmitter<boolean> = new EventEmitter();

  /**
   * Emits when the popover closes
   */
  @Output() onClosed: EventEmitter<boolean> = new EventEmitter();

  constructor(private popoverSvc: PopoverMenuService) {
    // Handle ESC key press to close iframe
    const escHandler = (event: KeyboardEvent) => {
      if (event.key === 'Escape') this.close();
    };
    window.addEventListener('keydown', escHandler);
    this.cleanupFns.push(() => window.removeEventListener('keydown', escHandler));

    // Handle close message from iframe content
    const messageHandler = (message: MessageEvent) => {
      try {
        if (message.data === 'closeIframePopover') this.close();
      } catch (error) {
        console.error('Error processing iframe message:', error);
      }
    };
    parent.addEventListener('message', messageHandler, false);
    this.cleanupFns.push(() => parent.removeEventListener('message', messageHandler));
  }

  /**
   * Toggles the visibility state of the popover
   */
  public toggle(): void {
    this.visible = !this.visible;
    this.updateScrollState();
  }

  /**
   * Opens the popover and disables body scrolling
   */
  public open(): void {
    this.visible = true;
    this.updateScrollState();
    this.onOpen.emit(true);
  }

  /**
   * Closes the popover, enables body scrolling, and reloads iframe content
   */
  public close(): void {
    this.visible = false;
    this.updateScrollState();
    this.onClosed.emit(true);
    this.reloadIframe();
  }

  /**
   * Cleanup event listeners on component destruction
   */
  ngOnDestroy(): void {
    this.cleanupFns.forEach((cleanup) => cleanup());
    this.cleanupFns = [];
    this.popoverSvc.enableBodyScrolling();
  }

  /**
   * Updates body scroll state based on popover visibility
   */
  private updateScrollState(): void {
    if (this.visible) {
      this.popoverSvc.disableBodyScrolling();
    } else {
      this.popoverSvc.enableBodyScrolling();
    }
  }

  /**
   * Reloads the iframe content by forcing URL refresh
   */
  private reloadIframe(): void {
    try {
      const iframe = document.getElementById('popover-iframe') as HTMLIFrameElement | null;
      if (iframe && iframe.src) {
        iframe.src = iframe.src;
      }
    } catch (error) {
      console.error('Error reloading iframe:', error);
    }
  }
}
