import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: 'errors', loadChildren: () => import('./pages/errors/errors.module').then((m) => m.ErrorsModule) },
  { path: 'auth', loadChildren: () => import('./pages/auth/authentication.module').then((m) => m.AuthenticationModule) },
  { path: 'forms', loadChildren: () => import('./pages/forms/our-forms.module').then((m) => m.OurFormsModule) },
  { path: 'file-viewer', loadChildren: () => import('./pages/file-viewer/file-viewer.module').then((m) => m.FileViewerModule) },
  { path: '', loadChildren: () => import('./pages/public/public.module').then((m) => m.PublicModule) },
  { path: '', loadChildren: () => import('./pages/secure/secure.module').then((m) => m.SecureModule) },
  { path: '**', pathMatch: 'full', redirectTo: '/errors/not-found' },
];

@NgModule({
  declarations: [],
  exports: [RouterModule],
  imports: [CommonModule, RouterModule.forRoot(routes)],
})
export class AppRoutingModule {}
