import { AfterViewInit, Component, ElementRef, EventEmitter, forwardRef, HostBinding, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR, ValidationErrors, Validator, ValidatorFn, Validators } from '@angular/forms';
import { HasSubscriptionsToClose } from '../../../mixins/has-subscriptions-to-close';

/**
 * A radio button group component that implements ControlValueAccessor for form integration.
 * Provides customizable radio options with support for validation, disabled state, and various display options.
 *
 * @implements {ControlValueAccessor}
 * @implements {Validator}
 * @implements {OnInit}
 * @implements {AfterViewInit}
 * @implements {OnDestroy}
 *
 * @example
 * <form-radio
 *   [options]="[{value: 'option1', label: 'Option 1'}, {value: 'option2', label: 'Option 2'}]"
 *   [required]="true"
 *   [label]="'Select an option'"
 *   (disabledChange)="onDisabledChange($event)">
 * </form-radio>
 */
@Component({
  selector: 'form-radio',
  templateUrl: './form-radio.component.html',
  styleUrls: ['../form-checkbox/form-checkbox.component.scss', './form-radio.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormRadioComponent),
      multi: true,
    },
  ],
})
export class FormRadioComponent extends HasSubscriptionsToClose() implements ControlValueAccessor, Validator, OnInit, AfterViewInit, OnDestroy {
  /**
   * Determines if the input is grouped with the label
   */
  @Input() grouped = false;

  /**
   * (Optional) The value of the label for the input
   */
  @HostBinding('attr.label') @Input() label?: string;

  /**
   * Determines if the input is required
   * @default false
   */
  @HostBinding('attr.required') @Input() required = false;

  /**
   * A name for the input
   * @default random string
   */
  @HostBinding('name') @Input() name = Math.random().toString(36).substring(7);

  /**
   * If true, the input will be displayed inline
   */
  @Input() inline = false;

  /**
   * The form control instance for managing the radio group's state and validation
   * @private
   */
  public formControl = new FormControl();

  /**
   * Determines if the input is disabled
   */
  @HostBinding('attr.disabled') @Input() disabled = false;

  /**
   * When the value of the input changes we emit the new value
   */
  @Output('disabledChange') disabledChange = new EventEmitter();

  /**
   * The value of the RadioGroup
   */
  @Output() value?: string | number | boolean;

  /**
   * The default radio options
   */
  private _options: FormRadioOption[] = [
    { value: true, label: 'Yes', checked: true },
    { value: false, label: 'No' },
  ];

  /**
   * The radio options to display. Defaults to Yes/No
   */
  @Input()
  set options(options: FormRadioOption[]) {
    this._options = JSON.parse(JSON.stringify(options));
  }

  get options(): FormRadioOption[] {
    return this._options;
  }

  /**
   * (Optional) The value of the help text for the controls
   */
  @HostBinding('attr.help_text') @Input() help_text?: string;

  /**
   * Determines if the input has errors
   */
  public get errors(): ValidationErrors | null {
    if (this.formControl.touched) {
      return this.formControl.errors;
    }

    return null;
  }

  constructor(private elementRef: ElementRef<HTMLElement>) {
    super();
  }

  /**
   * Initializes the component, sets up validators, and processes initial options
   * @throws {Error} If options are malformed or required properties are missing
   */
  ngOnInit(): void {
    try {
      // Subscribe to value changes and handle them
      this.subscribeTo(
        this.formControl.valueChanges.subscribe((value) => {
          try {
            if (value !== undefined && value !== null) {
              this.value = value;
              this.onChange(value);
            }
          } catch (error) {
            console.error('Error handling radio value change:', error);
          }
        }),
      );

      this.validate(this.formControl);
      this.elementRef.nativeElement.classList.add('custom-control');

      if (!Array.isArray(this.options)) {
        throw new Error('Radio options must be an array');
      }

      // Generate an id and add the control for each option
      for (const option of this.options) {
        if (!option.label) {
          throw new Error('Radio option must have a label');
        }

        option.id = option.id || this.sanitizeId(option.label);
        option.checked = option.checked || false;

        if (option.checked) {
          this.writeValue(option.value);
          this.value = option.value;
        }
      }
    } catch (error) {
      console.error('Error initializing radio component:', error);
      // Set default options in case of error
      this._options = [
        { value: true, label: 'Yes', checked: true },
        { value: false, label: 'No' },
      ];
    }
  }

  /**
   * Sanitizes a string to create a valid HTML ID
   * @param {string} str - The string to sanitize
   * @returns {string} A valid HTML ID
   * @private
   */
  private sanitizeId(str: string): string {
    return str.toLowerCase().replace(/[^a-z0-9]/g, '_');
  }

  /**
   * Sets up accessibility attributes after the view is initialized
   */
  ngAfterViewInit(): void {
    try {
      // Add the aria-describedby attribute for accessibility
      this.elementRef.nativeElement.setAttribute('aria-describedby', this.name + '_invalid_feedback');
    } catch (error) {
      console.error('Error setting up accessibility attributes:', error);
    }
  }

  /**
   * Handles cleanup when the component is destroyed
   */
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  /**
   * Handles the change event when a radio option is selected
   * @param {FormRadioOption} option - The selected radio option
   * @throws {Error} If the option is invalid
   */
  public onChanged(option: FormRadioOption): void {
    try {
      if (!option || typeof option.value === 'undefined') {
        throw new Error('Invalid radio option selected');
      }
      this.value = option.value;
      this.writeValue(option.value);
      this.onChange(option.value);
      this.onTouched();
    } catch (error) {
      console.error('Error handling radio change:', error);
    }
  }

  /**
   * Pass the element's change event to the parent
   */
  public onChange(value: any): void {}

  /**
   * The onTouched() method is called when the control status changes to or from "untouched".
   * This event is fired whenever the value changes, but not when the control status changes to or from "pristine"
   */
  public onTouched(): void {}

  /**
   * Write the value of the input to the FormGroup
   * @param {string | number | boolean} value - The value to be set on the FormGroup.
   */
  public writeValue(value: string | number | boolean): void {
    this.formControl.setValue(value);

    for (const option of this.options) {
      if (option.value == value) {
        option.checked = true;
      } else {
        option.checked = false;
      }
    }
  }

  /**
   * It tells the component that when the value of the input changes, the function onChange will be called.
   * @param {fn: (_: any) => void} fn - any
   */
  public registerOnChange(fn: (_: any) => void): void {
    this.onChange = fn;
  }

  /**
   * The registerOnTouched function is used to register a callback that will be called when the control is touched
   * @param {any} fn - any
   */
  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  /**
   * Sets the disabled state of the radio group
   * @param {boolean} disabled - Whether the radio group should be disabled
   * @emits {boolean} disabledChange - Emits the new disabled state
   */
  public setDisabledState(disabled: boolean): void {
    try {
      if (disabled) {
        this.formControl.disable({ emitEvent: false });
      } else {
        this.formControl.enable({ emitEvent: false });
      }
      this.disabled = disabled;
      this.disabledChange.emit(disabled);
    } catch (error) {
      console.error('Error setting disabled state:', error);
    }
  }

  /**
   * Validates the form control
   * @param {FormControl} control - The form control to validate
   * @returns {ValidatorFn[]} An array of validator functions
   */
  public validate(control: FormControl): ValidatorFn[] {
    try {
      const validators: ValidatorFn[] = [];

      if (this.formControl.validator) {
        validators.push(this.formControl.validator);
      }

      if (this.required) {
        validators.push(Validators.required);
      }

      control.setValidators(validators);
      control.updateValueAndValidity({ emitEvent: false });

      return validators;
    } catch (error) {
      console.error('Error during validation:', error);
      return [];
    }
  }
}

/**
 * The FormRadioOption interface
 */
export interface FormRadioOption {
  /**
   * The id of the option. If not provided, it will be generated
   */
  id?: string;

  /**
   * The label of the option
   */
  label: string;

  /**
   * The value of the option
   */
  value: string | number | boolean;

  /**
   * Whether the option is checked by default
   */
  checked?: boolean;
}
