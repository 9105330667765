import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, HostBinding, Input, OnDestroy, OnInit, Optional, Self, ViewChild } from '@angular/core';
import { ControlContainer, FormGroup, FormGroupDirective } from '@angular/forms';
import { HasSubscriptionsToClose } from '../../../mixins/has-subscriptions-to-close';
import { SharedFormService } from '../../shared-form.service';

/**
 * A button component specifically designed for form submission and interaction.
 * Provides automatic form validation state handling, loading states, and customizable styling.
 *
 * @example
 * <form [formGroup]="form">
 *   <form-btn class="btn btn-primary" [disabled]="customDisabled">Submit</form-btn>
 * </form>
 *
 * @example
 * <form id="myForm">
 *   <form-btn form_id="myForm" [spinning]="isLoading">Submit</form-btn>
 * </form>
 */
@Component({
  selector: 'form-btn',
  templateUrl: './form-btn.component.html',
  styleUrls: ['./form-btn.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
})
export class FormBtnComponent extends HasSubscriptionsToClose() implements OnInit, AfterViewInit, OnDestroy {
  /**
   * Reference to the button element in the template
   * @private
   */
  @ViewChild('button', { static: false }) private button?: ElementRef<HTMLButtonElement>;

  /**
   * CSS classes to be applied to the button
   * @default 'btn btn-white'
   */
  @HostBinding('attr.class') @Input() class = 'btn btn-white';

  /**
   * Controls the disabled state of the button
   * @default false
   */
  @HostBinding('attr.disabled') @Input() disabled = false;

  /**
   * Controls the visibility of directional arrows
   * @default false
   */
  @Input() arrows = false;

  /**
   * Controls whether the button should maintain its initial width
   * @default false
   */
  @Input() hide_offset_width = false;

  /**
   * Specifies the button type
   * @default 'submit'
   */
  @HostBinding('attr.type') @Input() type: 'button' | 'submit' | 'reset' = 'submit';

  /**
   * Unique identifier for the button
   * @default Random string
   */
  @HostBinding('attr.id') @Input() id = `form-btn-${Math.random().toString(36).substring(7)}`;

  /**
   * Observable tracking the form processing state
   */
  public readonly processing$ = this.formService.onStatusChange$;

  /**
   * Forces the spinner state regardless of processing status
   * @default false
   */
  @Input() spinning = false;

  /**
   * Optional form ID to bind the button to a specific form
   */
  @Input() form_id?: string;

  /**
   * Reference to the associated form element
   * @private
   */
  private form?: HTMLFormElement;

  /**
   * Reference to the parent FormGroup
   */
  public parent?: FormGroup;

  /**
   * CSS class for the spinner size based on button size
   * @private
   */
  public spinner_size = '';

  constructor(
    @Self() @Optional() private formGroupDirective: FormGroupDirective,
    private formService: SharedFormService,
    private elementRef: ElementRef<HTMLDivElement>,
    private cdr: ChangeDetectorRef,
  ) {
    super();
  }

  ngOnInit(): void {
    // Initialize any necessary setup
  }

  ngAfterViewInit(): void {
    try {
      this.initializeFormValidation();
      this.setupFormBinding();
      this.configureButtonStyles();
      this.cdr.detectChanges();
    } catch (error) {
      console.error('Error initializing form button:', error);
    }
  }

  /**
   * Initializes form validation subscription
   * @private
   */
  private initializeFormValidation(): void {
    if (!this.parent && this.formGroupDirective?.form) {
      this.parent = this.formGroupDirective.form;
      this.subscribeTo(
        this.parent.statusChanges.subscribe({
          next: (status) => {
            this.disabled = status === 'INVALID';
            this.cdr.markForCheck();
          },
          error: (error) => console.error('Form validation subscription error:', error),
        }),
      );
    }
  }

  /**
   * Sets up form binding and button attributes
   * @private
   */
  private setupFormBinding(): void {
    let form = this.elementRef.nativeElement.closest('form') as HTMLFormElement;

    if (!form && this.form_id) {
      form = document.getElementById(this.form_id) as HTMLFormElement;
    }

    if (form) {
      this.form = form;
      if (!form.id) {
        form.id = `form-${Math.random().toString(36).substring(7)}`;
      }
      this.button?.nativeElement.setAttribute('form', form.id);
    }
  }

  /**
   * Configures button styles and classes
   * @private
   */
  private configureButtonStyles(): void {
    if (!this.hide_offset_width && this.elementRef.nativeElement.offsetWidth) {
      this.elementRef.nativeElement.style.minWidth = `${this.elementRef.nativeElement.offsetWidth}px`;
    }

    this.setSpinnerSize();
    this.applyButtonClasses();
  }

  /**
   * Sets the spinner size based on button size class
   * @private
   */
  private setSpinnerSize(): void {
    if (this.class.includes('btn-sm')) {
      this.spinner_size = 'spinner-border-sm';
    } else if (this.class.includes('btn-lg')) {
      this.spinner_size = 'spinner-border-lg';
    } else if (this.class.includes('btn-xl')) {
      this.spinner_size = 'spinner-border-xl';
    }
  }

  /**
   * Applies CSS classes to the button element
   * @private
   */
  private applyButtonClasses(): void {
    const classes = this.class.trim().split(' ');
    if (this.button?.nativeElement) {
      classes.forEach((c) => this.button?.nativeElement.classList.add(c));
    }

    if (this.class.includes('btn-block')) {
      this.elementRef.nativeElement.style.width = '100%';
    }

    this.elementRef.nativeElement.classList.remove(...classes);
  }

  /**
   * Handles button click events and notifies the form service
   * @public
   */
  public onClick(): void {
    try {
      this.formService.onBtnClicked$.next(this);
      this.formService.onStatusChange$.next(this.form);
    } catch (error) {
      console.error('Error handling button click:', error);
    }
  }

  /**
   * Cleanup subscriptions and references on component destruction
   */
  override ngOnDestroy(): void {
    super.ngOnDestroy();
    this.form = undefined;
    this.parent = undefined;
    this.button = undefined;
  }
}
