<label #labelElement class="form-label" [for]="id" [innerText]="label" *ngIf="label"></label>
<ngp-image-picker
  #inputElement
  [id]="id"
  [class.is-invalid]="hasErrors"
  [attr.aria-describedby]="id + '_invalid_feedback'"
  [_config]="config"
  ($imageChanged)="onChange($event)"></ngp-image-picker>

<div [id]="id + '_invalid_feedback'" class="invalid-feedback">
  <ng-container *ngIf="ngControl.control!.errors?.required">This is required</ng-container>
  <ng-container *ngIf="ngControl.control!.errors?.pattern">This value is not valid.</ng-container>
  <ng-container *ngIf="ngControl.control!.errors?.minlength">
    Minimum length not met by {{ ngControl.control!.errors?.minlength.requiredLength - ngControl.control!.errors?.minlength.actualLength }} characters.
  </ng-container>
  <ng-container *ngIf="ngControl.control!.errors?.maxlength">
    Maximum length exceeded by {{ ngControl.control!.errors?.maxlength.actualLength - ngControl.control!.errors?.maxlength.requiredLength }} characters.
  </ng-container>
</div>

<div class="p-0 m-0 help-text" *ngIf="help_text">
  <div class="form-text text-muted" [id]="id + '_help_text'" [innerText]="help_text"></div>
</div>
