import { OnDestroy } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { Constructor } from './constructor';

/**
 * Automagically unsubscribe from all subscriptions that are added using the subscribeTo method
 * when the component is destroyed.
 *
 * Be careful: we implemented the ngOnDestroy method in our mixin: if you are going to use it in
 * a class, which will also itself implement the ngOnDestroy method, be sure to call super.ngOnDestroy() inside it!
 *
 * References:
 *  https://javascript.plainenglish.io/harnessing-the-power-of-mixins-in-angular-f2faa432add2
 *
 */
export function HasSubscriptionsToClose<T extends Constructor<{}>>(Base: T = class {} as any) {
  return class extends Base implements OnDestroy {
    public destroy$ = new Subject<void>();

    /**
     * The Subscriptions we will be unsubscribing from
     */
    public subscriptions: Subscription[] = [];

    /**
     * Unsubscribe from all given Subscription
     */
    ngOnDestroy(): void {
      for (const subscription of this.subscriptions) {
        if (subscription) subscription.unsubscribe();
      }

      this.destroy$.next();
      this.destroy$.complete();
    }

    /**
     * Unsubscribe from the given Subscription when ngOnDestroy is called
     * @param subscription Subscription
     */
    public subscribeTo(subscription: Subscription): void {
      this.subscriptions.push(subscription);
    }
  };
}
