import { HttpClient } from '@angular/common/http';
import { Component, HostBinding, Inject, Input, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter, firstValueFrom } from 'rxjs';
import { HasSubscriptionsToClose } from '../../../mixins/has-subscriptions-to-close';
import { Environment } from '../../../objects/environment';

interface PermLinkResponse {
  top_heading: string;
  needle: string;
}

/**
 * A section heading component for forms that displays title, subtitle, and optional telephone icon.
 * It automatically fetches and updates lead text based on the current route.
 *
 * @example
 * <form-section-heading title="Form Title" subtitle="Form Subtitle"></form-section-heading>
 *
 * @example
 * <form-section-heading title="Form Title" subtitle="Form Subtitle" telephone="true"></form-section-heading>
 *
 * @example
 * <form-section-heading title="Form Title" subtitle="Form Subtitle" telephone="true" lead="Form Lead"></form-section-heading>
 */
@Component({
  selector: 'form-section-heading',
  templateUrl: './form-section-heading.component.html',
  styleUrls: ['./form-section-heading.component.scss'],
})
export class FormSectionHeadingComponent extends HasSubscriptionsToClose() implements OnDestroy {
  /**
   * The Form section title
   */
  @HostBinding() @Input() title = '';

  /**
   * The Form section subtitle
   */
  @HostBinding() @Input() subtitle = '';

  /**
   * Should the telephone image be added to the header?
   */
  @Input() telephone = false;

  /**
   * The form lead text below the subtitle, automatically populated based on the current route
   */
  @Input() lead?: string;

  /**
   * Creates an instance of FormSectionHeadingComponent.
   * Sets up route change subscription to update heading lead text.
   *
   * @param http - Angular HttpClient for making API requests
   * @param router - Angular Router for navigation events
   * @param environment - Environment configuration containing API URLs
   */
  constructor(
    private http: HttpClient,
    private router: Router,
    @Inject('environment') private environment: Environment,
  ) {
    super();

    // Subscribe to route changes to update heading lead text
    this.subscribeTo(this.router.events.pipe(filter((event): event is NavigationEnd => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => this.getHeadingLeadText(event)));
  }

  /**
   * Fetch the lead text for the header based on the current route.
   * Updates the lead property with the fetched text.
   *
   * @param event - The navigation event containing the new URL
   */
  private async getHeadingLeadText(event: NavigationEnd): Promise<void> {
    try {
      const needle = event.urlAfterRedirects.split('/').pop();
      if (!needle) return;

      const response = await firstValueFrom(this.http.post<PermLinkResponse>(`${this.environment.api_url}/perm-links/get-by-needle`, { needle }));

      if (response?.top_heading) {
        this.lead = response.top_heading;
      }
    } catch (error) {
      console.error('Failed to fetch heading lead text:', error);
    }
  }

  /**
   * Cleanup method called on component destruction.
   * Parent class HasSubscriptionsToClose handles subscription cleanup.
   */
  override ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}
