import { Constructor } from './constructor';
import { DatabaseRecord } from './database-record';

/**
 * If a Model has roles, they can be checked for using `hasRole`
 */
export function HasRoles<T extends Constructor<{}>>(Base: T = class {} as any) {
  return class extends Base {
    constructor(...args: any[]) {
      super(...args);
    }

    /**
     * Used to determine access to articles and their sections
     */
    public roles: Role[] = [];

    /**
     * Check if the user has the role
     * @param {Role} role - Role
     * @returns {boolean}
     */
    public hasRole(role: RoleName): boolean {
      return this.roles.some((assigned) => assigned.name === role);
    }

    /**
     * Check if the user has the permission.
     * @param {RolePermissions} permission - The permission to check.
     * @returns {boolean} - True if the user has access, false otherwise.
     */
    public hasPermissionTo(permission: RolePermissions): boolean {
      const allGuards = this.roles.flatMap((role) => role.guards);

      // Create a unique array of guards using Set
      const uniqueGuards = [...new Set(allGuards)];

      // Check if the user has the required permission
      return uniqueGuards.some((guard) => {
        if (guard.includes('|')) {
          const [primary, secondary] = guard.split('|').map((part) => part.trim());
          return permission === primary || permission === secondary;
        }

        // For guards without a '|', match the entire guard
        return guard.trim() === permission;
      });
    }

    /**
     * Returns an array of all the unique guards associated with the roles.
     */
    public associatedGuards(): string[] {
      const allGuards = this.roles.flatMap((role) => role.guards);
      return [...new Set(allGuards)];
    }

    /**
     * Returns an array of all the unique features associated with the roles.
     */
    public associatedFeatures(): string[] {
        const allFeatures = this.roles.flatMap((role) => role.features);
        return [...new Set(allFeatures)];
    }

  };
}

/**
 * The roles that a user can have
 * @enum {string}
 */
export enum RoleName {
  /**
   * TOP LEVEL USER ROLE
   */
  SUPER_ADMINISTRATOR = 'super-administrator',

  /**
   * Access to all public resources, including personally identifying sensitive data.
   */
  ADMINISTRATOR = 'administrator',

  /**
   *  Access to all public resources, and Admin Portal.
   */
  EMPLOYEE = 'employee',

  /**
   * Access to all public resources, with limited access to the Admin Portal.
   */
  CONTRACTOR = 'contractor',

  /**
   * Access to all public resources, and Advisor Portal documents.
   */
  AGENT = 'agent',

  /**
   * Access to all public resources, Advisor Portal documents, and Our-Data.
   */
  LTCI_SPECIALIST = 'ltci-specialist',

  /**
   * Only access to their specific Advisor Portal which governs their team.
   */
  LEADERSHIP = 'leadership',

  /**
   * Default user role. Access to all public resources
   */
  DEFAULT = 'default',
}

export enum RolePermissions {
  /**
   * Access to the Admin Portal
   */
  ADMIN_PORTAL = 'admin-portal',

  /**
   * Access to the Advisor Portal
   */
  ADVISOR_PORTAL = 'advisor-portal',

  /**
   * Access to the Employee Portal
   */
  EMPLOYEE_PORTAL = 'employee-portal',

  /**
   * Access to personally identifying sensitive data
   */
  HIPPA = 'hipaa',

  /**
   * Access to the Our Data page
   */
  OUR_DATA = 'our-data',

  /**
   * Access to the Our Goals page
   */
  OUR_GOALS = 'our-goals',
}

/**
 * The Role model - used to determine access to portals, articles and their sections, and other resources
 * @extends DatabaseRecord
 */
export class Role extends DatabaseRecord() {
  constructor(data?: Partial<Role>) {
    super(data);
    Object.assign(this, data);

    if (data) {
      if (this.created_at) this.created_at = new Date(this.created_at);
      if (this.updated_at) this.updated_at = new Date(this.updated_at);
    }
  }

  /**
   * The name of the role
   */
  public name: RoleName = RoleName.DEFAULT;

  /**
   * The description of the role
   */
  public description = '';

  /**
   * The order of the role in the database. This is used to sort the roles in the Admin Portal.
   */
  public order: number = 0;

  /**
   * The routes that the role guards. Any routes with paths that match these will be restricted.
   */
  public guards: string[] = [];

  /**
   * The features allowed by the role
   */
  public features: string[] = [];

  /**
   * If the role requires verification to access
   */
  public verification_needed = false;

  /**
   * The permissions of the role
   */
  public permissions = {
    [RolePermissions.ADMIN_PORTAL]: false,
    [RolePermissions.ADVISOR_PORTAL]: false,
    [RolePermissions.EMPLOYEE_PORTAL]: false,
    [RolePermissions.HIPPA]: false,
    [RolePermissions.OUR_DATA]: false,
    [RolePermissions.OUR_GOALS]: false,
  };
}
