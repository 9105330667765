import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Component, ComponentRef, EventEmitter, Input, OnDestroy, Output, ViewChild, ViewContainerRef } from '@angular/core';
import { Subscription, firstValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import { PopoverMenuItem } from '../../popover-menu-item';
import { MenuComponent } from './menu/menu.component';

/**
 * Component responsible for managing popover menus in the application.
 * Handles creation, display, and cleanup of menu components.
 */
@Component({
  selector: 'popover-menu-outlet',
  templateUrl: './menu-outlet.component.html',
  styleUrls: ['./menu-outlet.component.scss'],
})
export class MenuOutletComponent implements OnDestroy {
  /**
   * The container holding the menus being displayed
   */
  @ViewChild('container', { read: ViewContainerRef }) container!: ViewContainerRef;

  /**
   * API domain for menu item fetching. Defaults to environment API URL
   */
  @Input() domain: string = `${environment.api_url}/`;

  /**
   * Emits when a new menu popover opens
   */
  @Output() onOpen: EventEmitter<ComponentRef<MenuComponent>> = new EventEmitter();

  /**
   * Emits when a menu popover closes
   */
  @Output() onClosed: EventEmitter<ComponentRef<MenuComponent>> = new EventEmitter();

  /**
   * Emits when a content outlet menu item is clicked
   */
  @Output() onContentOutletClick: EventEmitter<PopoverMenuItem> = new EventEmitter();

  /**
   * Emits when an iframe menu item is clicked
   */
  @Output() onIframeOutletClick: EventEmitter<PopoverMenuItem> = new EventEmitter();

  /**
   * Tracks all open popovers and their associated subscriptions
   * @private
   */
  private _popovers: { subscriptions: Subscription[]; component: ComponentRef<MenuComponent> }[] = [];

  /**
   * Gets the current open popovers
   * @returns Array of open popovers and their subscriptions
   */
  public get popovers(): { subscriptions: Subscription[]; component: ComponentRef<MenuComponent> }[] {
    return this._popovers;
  }

  constructor(private http: HttpClient) {}

  /**
   * Creates and displays a Menu component
   * @param item The menu item configuration to create the menu from
   * @throws Error if menu creation fails
   */
  public async createMenu(item: PopoverMenuItem): Promise<void> {
    try {
      if (!this.container) {
        throw new Error('Container not initialized');
      }

      const component = this.container.createComponent(MenuComponent);

      // Initialize menu items either from provided data or fetch from server
      if (item.our_tools || item.data) {
        component.instance.items = item.our_tools ?? item.data ?? [];
      } else {
        const items = await this.getMenuItems(item);
        component.instance.items = items;
      }

      // Setup subscriptions and store popover reference
      const popoverSubscriptions = this.setupPopoverSubscriptions(component);
      this._popovers.push({
        component,
        subscriptions: popoverSubscriptions,
      });

      component.instance.open();
      this.onOpen.emit(component);
    } catch (error) {
      console.error('Failed to create menu:', error);
      throw new Error('Menu creation failed');
    }
  }

  /**
   * Closes the most recently opened Popover
   */
  public close(): void {
    if (!this._popovers.length) return;

    const popover = this._popovers[this._popovers.length - 1];
    popover.component.instance.close(this._popovers.length === 1);
    this.cleanupPopoverSubscriptions(popover);
    this._popovers.pop();
  }

  /**
   * Cleanup subscriptions when component is destroyed
   */
  public ngOnDestroy(): void {
    this._popovers.forEach((popover) => {
      this.cleanupPopoverSubscriptions(popover);
      popover.component.destroy();
    });
    this._popovers = [];
  }

  /**
   * Sets up subscriptions for a menu component
   * @param component The menu component to setup subscriptions for
   * @returns Array of subscriptions
   */
  private setupPopoverSubscriptions(component: ComponentRef<MenuComponent>): Subscription[] {
    return [
      component.instance.onClosed.subscribe((closed: boolean) => {
        if (closed) {
          this.onClosed.emit(component);
          const index = this._popovers.findIndex((p) => p.component === component);
          if (index !== -1) {
            this._popovers.splice(index, 1);
          }
        }
      }),
      component.instance.onContentOutletClick.subscribe((item: PopoverMenuItem) => {
        if (item) this.onContentOutletClick.emit(item);
      }),
      component.instance.onIframeOutletClick.subscribe((item: PopoverMenuItem) => {
        if (item) this.onIframeOutletClick.emit(item);
      }),
      component.instance.onMenuOutletClick.subscribe((item: PopoverMenuItem) => {
        if (item) this.createMenu(item).catch(console.error);
      }),
    ];
  }

  /**
   * Cleans up subscriptions for a popover
   * @param popover The popover to cleanup
   */
  private cleanupPopoverSubscriptions(popover: { subscriptions: Subscription[] }): void {
    popover.subscriptions.forEach((subscription) => {
      if (subscription && !subscription.closed) {
        subscription.unsubscribe();
      }
    });
  }

  /**
   * Fetches menu items from the server
   * @param menu_item The menu item configuration
   * @returns Promise resolving to array of PopoverMenuItem
   * @throws Error if API request fails
   */
  private async getMenuItems(menu_item: PopoverMenuItem): Promise<PopoverMenuItem[]> {
    try {
      const items = await firstValueFrom(this.http.get<any[]>(this.domain + menu_item.action.url));
      if (!items?.length) return [];

      const website = this.detectWebsite();
      return this.processMenuItems(items, menu_item, website);
    } catch (error) {
      const message = error instanceof HttpErrorResponse ? error.message : 'Unknown error occurred';
      console.error('Failed to fetch menu items:', message);
      throw new Error(`Failed to fetch menu items: ${message}`);
    }
  }

  /**
   * Processes and filters menu items based on website and configuration
   * @param items Raw items from API
   * @param menu_item Parent menu item configuration
   * @param website Current website context
   * @returns Processed PopoverMenuItem array
   */
  private processMenuItems(items: any[], menu_item: PopoverMenuItem, website: string): PopoverMenuItem[] {
    // Filter items based on availability
    items = items.filter((item) => (item.availability && item.availability[website]) || !item.availability);

    // Additional filtering for LTC Planning
    if (menu_item.title.includes('LTC Planning')) {
      items = items.filter((item) => item.group?.includes('Long-Term Care'));
    }

    // Transform items to PopoverMenuItem format
    return items.map((item) => ({
      route_prefix: menu_item.route_prefix,
      title: item.title,
      classes: item.classes,
      action: {
        type: 'route',
        url: this.buildItemUrl(item, menu_item),
      },
    }));
  }

  /**
   * Builds the URL for a menu item
   * @param item The item to build URL for
   * @param menu_item Parent menu item configuration
   * @returns Constructed URL string
   */
  private buildItemUrl(item: any, menu_item: PopoverMenuItem): string {
    const slug = item.slug ? `/${item.slug}` : '';
    if (menu_item.omit_base_action_url) {
      return `${item.id}${slug}`;
    }
    return `${menu_item.action.url}/${item.id}${slug}`;
  }

  /**
   * Detects which website the user is currently on
   * @returns Website identifier string
   */
  private detectWebsite(): string {
    const hostname = window.location.hostname;
    const pathname = window.location.pathname;

    if (hostname.includes('agingplan.') && pathname.includes('advisor-portal')) {
      return 'advisor_portals';
    }
    if (hostname.includes('agingplan.')) {
      return 'public';
    }
    return 'my_aging_plan';
  }
}
