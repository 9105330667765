<div class="popup-terms">
  <div class="container">
    <lib-heading [lucid]="true" heading="Terms and Conditions" subheading="Wolf &amp; Associates">
      <p></p>
    </lib-heading>

    <p>
      Wolf &amp; Associates does not provide legal or accounting advice to its clients. The effectiveness of any strategies described is dependent on your individual situation and other complex
      factors.
    </p>
    <p>
      Our consulting services are designed to include your legal and/or accounting advisors in the design and implementation of any alternatives presented. You should consult with these advisors prior
      to implementing any proposed strategies.
    </p>
    <p>Information contained on our web site is the property of Wolf &amp; Associates and use, reproduction or dissemination of our material is strictly prohibited</p>
  </div>
</div>
