<button tabindex="-1" #button [type]="type" [disabled]="disabled" [class.disabled]="disabled">
  <ng-content *ngIf="!(processing$ | async) && !spinning; else spinner"></ng-content>
  <i class="bi bi-chevron-double-right" *ngIf="arrows"></i>
</button>

<ng-template #spinner>
  <div class="spinner-border" [class]="spinner_size" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</ng-template>
