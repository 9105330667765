import { DatabaseRecord } from '../mixins/database-record';

export class Carrier extends DatabaseRecord() {
  public title = '';
  public type = '';
  public label = '';
  public rating = '';
  public image_url = '';
  public image_hover_url = '';
  public order = 0;

  constructor(data: Carrier) {
    super(data);
    Object.assign(this, data);
  }
}
