import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { DateOrStringPipe } from './date-or-string.pipe';
import { EllipsisPipe } from './ellipsis.pipe';
import { KeysPipe } from './keys.pipe';
import { SafePipe } from './safe.pipe';
import { ColorPipe } from './color.pipe';
import { AsFormGroupPipe } from './as-form-group.pipe';
import { CamelToHumanPipe } from './camel-to-human.pipe';
import { AsFormArrayPipe } from './as-form-array.pipe';

@NgModule({
  declarations: [SafePipe, EllipsisPipe, KeysPipe, DateOrStringPipe, ColorPipe, AsFormGroupPipe, CamelToHumanPipe, AsFormArrayPipe],
  exports: [SafePipe, EllipsisPipe, KeysPipe, DateOrStringPipe, ColorPipe, AsFormGroupPipe, CamelToHumanPipe, AsFormArrayPipe],
  imports: [CommonModule],
  providers: [DatePipe, DateOrStringPipe, SafePipe, EllipsisPipe, KeysPipe, ColorPipe],
})
export class SharedPipesModule {}
