import { Component, HostBinding, Input, AfterViewInit, OnDestroy } from '@angular/core';
import { Title } from '@angular/platform-browser';

/**
 * A header that can be used within a form. Used to display the form title and subtitle
 *
 * @example
 * <form-header title="Form Title" subtitle="Form Subtitle" heading="Form Heading"></form-header>
 */
@Component({
  selector: 'form-header',
  templateUrl: './form-header.component.html',
  styleUrls: ['./form-header.component.scss'],
})
export class FormHeaderComponent implements AfterViewInit, OnDestroy {
  /**
   * The Form heading
   */
  @HostBinding('attr.heading') @Input() heading?: string;

  /**
   * The Form title
   */
  @HostBinding('attr.title') @Input() title = '';

  /**
   * The Form subtitle
   */
  @HostBinding('attr.subtitle') @Input() subtitle = '';

  constructor(private pageTitle: Title) {}

  ngAfterViewInit(): void {
    this.pageTitle.setTitle(`${this.title.capitalize()} | AgingPlan`);
  }

  ngOnDestroy(): void {
    this.pageTitle.setTitle('AgingPlan');
  }
}
