import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AOSDirective } from './aos.directive';
import { FeatureAuthorizationDirective } from './feature-authorization.directive';
import { FormatInputDirective } from './format-input.directive';
import { HrLabelDirective } from './hr-label.directive';
import { ImageSrcErrorDirective } from './image-src-error.directive';
import { ParallaxDirective } from './parallax.directive';
import { SmoothScrollDirective } from './smooth-scroll.directive';
import { WhatIsThisDirective } from './what-is-this.directive';

@NgModule({
  declarations: [FormatInputDirective, SmoothScrollDirective, ImageSrcErrorDirective, WhatIsThisDirective, AOSDirective, ParallaxDirective, HrLabelDirective, FeatureAuthorizationDirective],
  exports: [FormatInputDirective, SmoothScrollDirective, ImageSrcErrorDirective, WhatIsThisDirective, AOSDirective, ParallaxDirective, HrLabelDirective, FeatureAuthorizationDirective],
  imports: [CommonModule],
})
export class SharedDirectivesModule {}
