<input
  #inputElement
  class="custom-control-input"
  [type]="type"
  [id]="id"
  [name]="name"
  [value]="value"
  [checked]="checked"
  [disabled]="disabled"
  (change)="onChanged(inputElement)"
  (blur)="onTouched()" />

<label class="custom-control-label" [for]="id" (click)="inputElement.click()">
  <ng-content></ng-content>
</label>
