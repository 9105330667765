import { Directive, ElementRef, HostListener, Input, TemplateRef } from '@angular/core';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';

@Directive({
  selector: '[whatIsThis]',
  providers: [NgbPopover],
})
export class WhatIsThisDirective {
  /**
   * The popover title
   */
  @Input() popoverTitle: TemplateRef<any> | string = 'What is this?';

  /**
   * The popover content
   */
  @Input() whatIsThis: TemplateRef<any> | string = '';

  // Open the Popover when the user hovers over the element
  @HostListener('mouseenter') onMouseEnter() {
    this.popover.ngbPopover = this.whatIsThis;
    this.popover.popoverTitle = this.popoverTitle;
    this.popover.open();
  }

  // Close the Popover when the user leaves the element
  @HostListener('mouseleave') onMouseLeave() {
    this.popover.close();
  }

  constructor(
    private elementRef: ElementRef<HTMLElement>,
    private popover: NgbPopover,
  ) {
    this.elementRef.nativeElement.setAttribute('container', 'body');
    this.elementRef.nativeElement.setAttribute('placement', 'end');
  }
}
