import { Injectable } from '@angular/core';
import { NgOption } from '@ng-select/ng-select';
import { BehaviorSubject } from 'rxjs';

interface CacheEntry {
  at: Date;
  data: NgOption[];
}

@Injectable({
  providedIn: 'root',
})
export class PrefillCacheService {
  private cache$ = new BehaviorSubject<{ [key: string]: CacheEntry }>({});
  private pendingRequests: { [key: string]: Promise<NgOption[]> } = {};
  private readonly CACHE_EXPIRY_SECONDS = 60;

  constructor() {}

  /**
   * Gets cached data for a given key if it exists and is not expired
   * @param key The cache key to look up
   * @returns The cached data if valid, null otherwise
   */
  public getCached(key: string): NgOption[] | null {
    const cached = this.cache$.value[key];
    if (!cached) return null;

    const secondsSinceLastSync = (new Date().getTime() - cached.at.getTime()) / 1000;
    if (secondsSinceLastSync >= this.CACHE_EXPIRY_SECONDS) {
      return null;
    }

    return cached.data;
  }

  /**
   * Gets or creates a pending request for the given key
   * @param key The cache key
   * @param fetchFn The function to fetch data if not cached
   * @returns Promise that resolves with the data
   */
  public async getOrCreateRequest(key: string, fetchFn: () => Promise<NgOption[]>): Promise<NgOption[]> {
    // Check cache first
    const cachedData = this.getCached(key);
    if (cachedData) {
      return cachedData;
    }

    // If there's already a pending request, return it
    if (key in this.pendingRequests) {
      return this.pendingRequests[key];
    }

    // Create new request
    try {
      this.pendingRequests[key] = fetchFn();
      const data = await this.pendingRequests[key];
      this.setCache(key, data);
      return data;
    } finally {
      // Clean up pending request
      delete this.pendingRequests[key];
    }
  }

  /**
   * Sets data in the cache for a given key
   * @param key The cache key
   * @param data The data to cache
   */
  public setCache(key: string, data: NgOption[]): void {
    this.cache$.next({
      ...this.cache$.value,
      [key]: { at: new Date(), data },
    });
  }

  /**
   * Clears all cached data and pending requests
   */
  public clearCache(): void {
    this.cache$.next({});
    this.pendingRequests = {};
  }
}
