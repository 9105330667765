<ng-container #formsContainer></ng-container>

<button *ngFor="let item of forms?.controls; index as i" type="button" class="btn btn-sm btn-light" [class.active]="page === i" (click)="setPageTo(i)">
  {{ i + 1 }}
</button>

<button type="button" class="btn btn-sm btn-primary" (click)="addMore()">
  add more
  <i class="bi bi-chevron-double-right"></i>
</button>
