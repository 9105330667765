import { MsalGuardConfiguration, MsalInterceptorConfiguration } from '@azure/msal-angular';
import { BrowserCacheLocation, IPublicClientApplication, InteractionType, LogLevel, PublicClientApplication } from '@azure/msal-browser';
import { environment } from 'src/environments/environment';

/**
 * Callback function for logging messages.
 * @param logLevel - The log level of the message.
 * @param message - The log message.
 */
export function loggerCallback(logLevel: LogLevel, message: string) {
  if (!environment.production) console.log(message);
}

/**
 * Factory function that creates an instance of IPublicClientApplication.
 * @returns An instance of IPublicClientApplication.
 */
export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: '4039dbbe-e6f0-4699-bad7-0c273ccf7e74',
      authority: 'https://login.microsoftonline.com/87fe1fcb-cabf-45e1-818e-ed426e0336e7',
      redirectUri: environment.web_url,
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
    },
    system: {
      allowNativeBroker: false, // Disables WAM Broker
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Info,
        piiLoggingEnabled: false,
      },
    },
  });
}

/**
 * Factory function that returns the configuration for the MSAL interceptor.
 * The interceptor is used to handle authentication and authorization for protected resources.
 * @returns The MSAL interceptor configuration.
 */
export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map([
    ['https://graph.microsoft.com/v1.0/me', ['user.read']],
    ['https://api.myapplication.com/users/*', ['customscope.read']],
  ]);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
  };
}

/**
 * Factory function that returns the configuration for the MSALGuard.
 * @returns The configuration object for the MSALGuard.
 */
export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: ['user.read'],
    },
    loginFailedRoute: '/login-failed',
  };
}
