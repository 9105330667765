import { Component, ElementRef, HostBinding, Input, Optional, Self } from '@angular/core';
import { NgControl } from '@angular/forms';
import { FormInputBase } from '../form-input/form-input-base';
/**
 * Input type textarea
 * availavle sizes
 * 465 x 105 class="from-control-size-1"
 * 465 x 180 class="from-control-size-2"
 * 420 x 180 class="from-control-size-3"
 * 600 x 180 class="from-control-size-4"
 * 1440 x 165 class="from-control-size-5"
 */
@Component({
  selector: 'form-textarea',
  templateUrl: './form-textarea.component.html',
  styleUrls: ['./form-textarea.component.scss'],
})
export class FormTextareaComponent extends FormInputBase {
  /**
   * The number of rows to show
   */
  @HostBinding('attr.rows') @Input() rows = '3';

  constructor(@Self() @Optional() ngControl: NgControl, elementRef: ElementRef<HTMLElement>) {
    super(ngControl, elementRef);
  }
}
