import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'form-success',
  templateUrl: './form-success.component.html',
  styleUrls: ['./form-success.component.scss'],
})
export class FormSuccessComponent {
  /**
   * The message to display
   */
  @HostBinding('attr.message') @Input() message?: string;
}
