import { Pipe, PipeTransform } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';
import { SafePipe } from './safe.pipe';

/**
 * Add color to text
 * @example
 * <span [innerHTML]="text | color: 'primary'"></span>
 */
@Pipe({
  name: 'color',
})
export class ColorPipe implements PipeTransform {
  constructor(private safePipe: SafePipe) {}
  transform(text: string | number, color: 'primary' | 'secondary' | 'danger'): SafeHtml {
    const element = document.createElement('span');
    element.innerText = text.toString();
    element.classList.add(`text-${color}`);
    return this.safePipe.transform(element.outerHTML, 'html');
  }
}
