import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class PopoverMenuService {
  constructor() {}

  public close(): void {
    this.enableBodyScrolling();
  }

  public disableBodyScrolling(): void {

    document.getElementsByTagName('body')[0].style.overflow = 'hidden';
  }

  public enableBodyScrolling(): void {
    document.getElementsByTagName('body')[0].style.overflow = 'auto';
  }

  private get navbar(): HTMLElement {
    return <HTMLElement>document.querySelectorAll('nav.navbar')[0];
  }
}
