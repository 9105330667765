<label #labelElement class="form-label" [for]="id" [innerText]="label" *ngIf="label && !grouped"></label>

<ng-container *ngIf="grouped">
  <div class="input-group-prepend">
    <span class="input-group-text">
      <span *ngIf="label" [innerText]="label"></span>
      <ng-content *ngIf="!label"></ng-content>
    </span>
  </div>
</ng-container>
<div class="form-input-color-width">
  <input
    #inputElement
    class="form-control"
    [id]="id"
    [value]="value"
    [disabled]="disabled"
    [placeholder]="placeholder"
    [required]="required"
    [class.is-invalid]="errors"
    [attr.aria-describedby]="id + '_invalid_feedback'"
    (input)="onChangeColorHexCode(inputElement.value)" />
  <div class="box" (click)="openColorSelector()" >
    <div class="selected-color" [style.background-color]="inputElement.value"></div>
  </div>
  <div class="position-relative">
    <color-chrome *ngIf="show_color_selector" #colorInputElement [color]="inputElement.value" (onChange)="onColorChange($event)" />
    <div class="d-flex justify-content-center picker-btns" *ngIf="show_color_selector">
      <div>
        <form-btn class="picker-cancel-btn btn-md btn-black" (click)="closeColorSector()">CANCEL</form-btn>
      </div>
      <div>
        <form-btn class="picker-apply-btn btn-md btn-light-brown" (click)="applyChangedColor()">APPLY</form-btn>
      </div>
    </div>
  </div>
</div>

<div [id]="id + '_invalid_feedback'" class="invalid-feedback">
  <ng-container *ngIf="errors?.required">This is required</ng-container>
  <ng-container *ngIf="errors?.pattern">This value is not valid.</ng-container>
  <ng-container *ngIf="errors?.email">Not a valid email address.</ng-container>
  <ng-container *ngIf="errors?.minlength">Minimum length not met by {{ errors?.minlength.requiredLength - errors?.minlength.actualLength }} characters.</ng-container>
  <ng-container *ngIf="errors?.maxlength">Maximum length exceeded by {{ errors?.maxlength.actualLength - errors?.maxlength.requiredLength }} characters.</ng-container>
</div>

<div class="p-0 m-0 help-text" [class.input-group]="grouped" *ngIf="help_text">
  <div class="form-text text-muted" [id]="id + '_help_text'" [innerText]="help_text"></div>
</div>
