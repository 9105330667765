import { Environment } from '@agingplan';

export const environment: Environment = {
  production: true,
  web_url: 'https://www.agingplan.com',
  api_url: 'https://www.agingplan.com/api',
  websocket_url: 'https://www.agingplan.com',
  google: {
    bucket: 'https://storage.googleapis.com/agingplan-production',
    analytics: 'G-18EKW4NX62',
  },
  adobe_dc_api_key: '',
  agencybloc_web_url: 'https://app.agencybloc.com',
};
