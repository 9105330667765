import { Directive, ElementRef, HostBinding, Input, OnChanges, SimpleChanges } from '@angular/core';

/**
 * Animate on Scroll (AOS) directive
 */
@Directive({
  selector: '[aos]',
})
export class AOSDirective implements OnChanges {
  @HostBinding('attr.data-aos') @Input() aos?: string;
  @HostBinding('attr.data-aos-delay') @Input() delay?: number;
  @HostBinding('attr.data-aos-duration') @Input() duration?: number;

  constructor(private element: ElementRef) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.element.nativeElement.setAttribute('data-aos-once', true);
    this.element.nativeElement.setAttribute('data-aos-offset', 0);
    this.element.nativeElement.setAttribute('data-aos-easing', 'ease-out');
    this.element.nativeElement.setAttribute('data-aos-duration', this.duration ? this.duration : 500);
    this.element.nativeElement.setAttribute('data-aos-anchor-placement', 'top-center');
    this.element.nativeElement.setAttribute('data-aos-anchor', 'body');
    if (this.aos) {
      this.element.nativeElement.setAttribute('data-aos', this.aos);
    } else if (this.element.nativeElement.offsetLeft < this.element.nativeElement.offsetRight) {
      this.element.nativeElement.setAttribute('data-aos', 'fade-left');
    } else {
      this.element.nativeElement.setAttribute('data-aos', 'fade-right');
    }
    if (this.delay) {
      this.element.nativeElement.setAttribute('data-aos-delay', this.delay);
    }
    this.element.nativeElement.classList.add('aos-init');
  }
}
