<label #labelElement class="form-label" [for]="id" [innerText]="label" *ngIf="label && !grouped"></label>

<ng-container *ngIf="grouped">
  <div class="input-group-prepend">
    <span class="input-group-text">
      <span *ngIf="label" [innerText]="label"></span>
      <ng-content *ngIf="!label"></ng-content>
    </span>
  </div>
</ng-container>

<textarea
  #inputElement
  class="form-control"
  [rows]="rows"
  [id]="id"
  [value]="ngControl.value"
  [disabled]="ngControl.disabled"
  [placeholder]="placeholder"
  [required]="required"
  [class.is-invalid]="errors"
  [attr.aria-describedby]="id + '_invalid_feedback'"
  [format_input]="formatter"
  (input)="onChange(inputElement.value)"
  (blur)="onTouched()"></textarea>

<div [id]="id + '_invalid_feedback'" class="invalid-feedback">
  <ng-container *ngIf="errors?.required">This is required</ng-container>
  <ng-container *ngIf="errors?.pattern">This value is not valid.</ng-container>
  <ng-container *ngIf="errors?.minlength">Minimum length not met by {{ errors?.minlength.requiredLength - errors?.minlength.actualLength }} characters.</ng-container>
  <ng-container *ngIf="errors?.maxlength">Maximum length exceeded by {{ errors?.maxlength.actualLength - errors?.maxlength.requiredLength }} characters.</ng-container>
</div>

<div class="p-0 m-0 help-text" [class.input-group]="grouped" *ngIf="help_text">
  <div class="form-text text-muted" [id]="id + '_help_text'" [innerText]="help_text"></div>
</div>
