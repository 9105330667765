<div class="w-100">
  <div class="input-group">
    <div class="input-group-prepend" *ngIf="position == 'left'">
      <div *ngTemplateOutlet="fileInput"></div>
    </div>
    <form-select
      #formSelect
      (ngbModelChange)="onChangeImage($event)"
      [class]="class"
      class="file-selctor"
      [prefill]="prefill"
      [options]="options"
      [searchable]="true"
      title="Assets"
      placeholder="No file chosen">
    </form-select>
    <div class="input-group-append" *ngIf="position !== 'left'">
      <div *ngTemplateOutlet="fileInput"></div>
    </div>
  </div>

  <ng-container *ngIf="(type === 'image/*' || type === '.jpg' || type === '.png' || type === '.svg') && preview">
    <div class="place-image">
      <img *ngIf="new_file_value" [src]="new_file_value" class="img-fluid img-selected-logo" [style.background-color]="image_background_color" />
      <div *ngIf="!new_file_value" class="file-icon">
        <i class="bi bi-file-earmark-richtext-fill"></i>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="type === '.mp4' && preview">
    <div class="place-image">
      <video controls="controls" height="100%" *ngIf="new_file_value">
        <source [src]="new_file_value" type="video/mp4" />
      </video>
      <div *ngIf="!new_file_value" class="file-icon">
        <i class="bi bi-file-earmark-richtext-fill"></i>
      </div>
    </div>
  </ng-container>
</div>

<ng-template #fileInput>
  <label class="btn btn-md">
    {{ label }}
    <input #fileInputElement type="file" [accept]="type" hidden (change)="handleUploadLogo($event)" />
  </label>
</ng-template>

<ng-container *ngIf="show_select_file_thumbnail">
  <div class="row">
    <div class="col-md-12">Click on the thumbnail to select</div>
    <div class="col-md-6 pointer" (click)="onClickSelectThumbnail(new_file_value)">
      <ng-container *ngIf="type === '.mp4'">
        <video controls="controls" height="149px" *ngIf="new_file_value">
          <source [src]="new_file_value" type="video/mp4" />
        </video>
      </ng-container>
      <ng-container *ngIf="type === 'image/*' || type === '.jpg' || type === '.png'">
        <img *ngIf="new_file_value" [src]="new_file_value" class="img-fluid img-selected-logo" [style.background-color]="image_background_color" />
      </ng-container>
      <p>
        New : {{ new_file_size }} KB <b>{{ value == new_file_value ? '(Selected)' : '' }}</b>
      </p>
    </div>
    <div class="col-md-6 pointer" (click)="onClickSelectThumbnail(old_file_url)">
      <ng-container *ngIf="type === '.mp4'">
        <video controls="controls" height="149px" *ngIf="value">
          <source [src]="old_file_url" type="video/mp4" />
        </video>
      </ng-container>
      <ng-container *ngIf="type === 'image/*' || type === '.jpg' || type === '.png'">
        <img *ngIf="value" [src]="old_file_url" class="img-fluid img-selected-logo" [style.background-color]="image_background_color" />
      </ng-container>
      <p>
        Old : {{ old_file_size }} KB <b>{{ value == old_file_url ? '(Selected)' : '' }}</b>
      </p>
    </div>
  </div>
</ng-container>
