import { AfterContentInit, Directive, ElementRef, HostBinding, Input } from '@angular/core';
import { gsap } from 'gsap';

/**
 * Parallax directive
 *
 * **NOTE: parallax speed not less then 2**b
 */
@Directive({
  selector: '[Parallax]'
})
export class ParallaxDirective implements AfterContentInit {
  /**
   * default parallax speed
   *
   * **NOTE: parallax speed not less then 2**
   */
  @HostBinding() @Input() Parallax = 2;

  constructor(private elementRef: ElementRef) { }

  ngAfterContentInit(): void {
    const section = this.elementRef.nativeElement;

    // get HTMLElement div which has `.bg` class
    const background = section.querySelector('.bg');

    setTimeout(() => {
      // set height of section container
      section.style.height = background['style'].height;

      // check if image div has `.parallax` class
      if (background.classList.contains('parallax')) {
        // set background position
        background['style'].backgroundPosition = `50% ${-innerHeight / this.Parallax}px`;

        // set parallax effect
        gsap.to(background, {
          backgroundPosition: `50% ${innerHeight / this.Parallax}px`,
          ease: 'none',
          scrollTrigger: {
            trigger: section,
            scrub: true
          }
        })
      }
    }, 300);
  }
}
