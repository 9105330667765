import { FormatInputType } from '@agingplan/src';
import { AfterViewInit, Directive, ElementRef, EventEmitter, HostBinding, Input, OnDestroy, OnInit, Optional, Output, Self, ViewChild, forwardRef } from '@angular/core';
import { AbstractControl, ControlValueAccessor, NG_VALUE_ACCESSOR, NgControl, ValidationErrors, Validator, ValidatorFn, Validators } from '@angular/forms';
import { NgSelectComponent } from '@ng-select/ng-select';
import { takeUntil } from 'rxjs';
import { HasSubscriptionsToClose } from '../../../mixins/has-subscriptions-to-close';

/**
 * Base class for form input components providing common functionality for form controls
 * including value accessor implementation, validation, and view management.
 */
@Directive({
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormInputBase),
      multi: true,
    },
  ],
})
export abstract class FormInputBase extends HasSubscriptionsToClose() implements ControlValueAccessor, Validator, OnInit, AfterViewInit, OnDestroy {
  /** The Input element in the DOM */
  @ViewChild('inputElement') inputElement?: ElementRef<HTMLInputElement> | NgSelectComponent;

  /** The current value of the input */
  @Input() value: boolean | string = '';

  /** The Label element in the DOM */
  @ViewChild('labelElement') labelElement?: ElementRef<HTMLLabelElement | HTMLSpanElement>;

  /** Controls label padding - true for 15px, false for 30px */
  @Input() inline_label_padding_small? = true;

  /** Label text for the input */
  @HostBinding('attr.label') @Input() label?: string;

  /** Placeholder text for the input */
  @HostBinding('attr.placeholder') @Input() placeholder = '';

  /** Validation pattern for the input */
  @HostBinding('attr.pattern') @Input() pattern?: string;

  /** Help text displayed below the input */
  @HostBinding('attr.help_text') @Input() help_text?: string;

  /** Warning text displayed below the input */
  @HostBinding('attr.warning_text') @Input() warning_text?: string;

  /** Input type (text, number, etc.) */
  @HostBinding('attr.type') @Input() type?: string;

  /** Additional CSS classes */
  @HostBinding('attr.class') @Input() class = '';

  /** Disabled state of the input */
  @HostBinding('attr.disabled') @Input() disabled = false;

  /** Readonly state of the input */
  @HostBinding('attr.readonly') @Input() readonly = false;

  /** Required state of the input */
  @HostBinding('attr.required') @Input() required = false;

  /** Unique identifier for the input */
  @HostBinding('attr.id') @Input() id = `input_${Math.random().toString(36).substring(2, 11)}`;

  /** Whether the input is grouped with its label */
  @Input() grouped = false;

  /** Input value formatter */
  @HostBinding('formatter') @Input() formatter?: FormatInputType;

  /** Angular form control integration */
  @Input() formControl?: AbstractControl;

  /** NgbModel integration */
  @Input() ngbModel?: any;

  /** Event emitter for value changes */
  @Output('ngbModelChange') change = new EventEmitter<any>();

  /**
   * Returns validation errors if the control is touched
   */
  public get errors(): ValidationErrors | null {
    try {
      if (this.ngControl?.control?.touched) {
        return this.ngControl?.control?.errors;
      }
    } catch (error) {
      console.error('Error accessing control errors:', error);
    }
    return null;
  }

  constructor(
    @Self() @Optional() public ngControl: NgControl,
    private elementRef: ElementRef<HTMLElement>,
  ) {
    super();
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }

  /**
   * Initializes the component, sets up validators and updates control validity
   */
  ngOnInit(): void {
    try {
      const control = this.ngControl?.control;

      if (control) {
        const validators: ValidatorFn[] = control.validator ? [control.validator] : [];

        if (this.required) {
          validators.push(Validators.required);
        }

        if (this.pattern) {
          validators.push(Validators.pattern(this.pattern));
        }

        control.setValidators(validators);
        control.updateValueAndValidity();

        // Subscribe to value changes
        control.valueChanges.pipe(takeUntil(this.destroy$)).subscribe({
          next: (value) => this.writeValue(value),
          error: (error) => console.error('Error in value changes:', error),
        });
      }

      if (this.grouped) {
        this.class = `${this.class} input-group`.trim();
      }
    } catch (error) {
      console.error('Error in ngOnInit:', error);
    }
  }

  /**
   * Handles post-view-init tasks like applying CSS classes
   */
  ngAfterViewInit(): void {
    try {
      if (this.inputElement instanceof ElementRef) {
        const element = this.inputElement.nativeElement;
        const tokens = this.class
          .trim()
          .split(' ')
          .filter((token) => token.length > 0);

        tokens.forEach((token) => element.classList.add(token));

        this.handleFormControlSizeClasses(element);
      }
    } catch (error) {
      console.error('Error in ngAfterViewInit:', error);
    }
  }

  /**
   * Cleanup subscriptions and prevent memory leaks
   */
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    super.ngOnDestroy?.();
  }

  /**
   * Handles value changes from the input
   * @param value The new value
   */
  public onChange(value: any): void {}

  /**
   * Handles touch events on the input
   */
  public onTouched(): void {}

  /**
   * Updates the input value
   * @param value The new value to write
   */
  public writeValue(value: any): void {
    try {
      this.value = value;
    } catch (error) {
      console.error('Error writing value:', error);
    }
  }

  /**
   * Registers the onChange callback
   * @param fn Callback function
   */
  public registerOnChange(fn: (_: any) => void): void {
    this.onChange = fn;
  }

  /**
   * Registers the onTouched callback
   * @param fn Callback function
   */
  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  /**
   * Updates the disabled state of the input
   * @param isDisabled Whether the input should be disabled
   */
  public setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  /**
   * Performs validation on the control
   * @param control The control to validate
   * @returns Validation errors if any
   */
  public validate(control: AbstractControl): ValidationErrors | null {
    try {
      const validators: ValidatorFn[] = [];

      if (this.required) {
        validators.push(Validators.required);
      }
      if (this.pattern) {
        validators.push(Validators.pattern(this.pattern));
      }

      return validators.reduce((errors: ValidationErrors | null, validator) => {
        const validationResult = validator(control);
        return validationResult ? { ...errors, ...validationResult } : errors;
      }, null);
    } catch (error) {
      console.error('Error in validate:', error);
      return null;
    }
  }

  /**
   * Handles form control size classes
   * @param element The input element
   * @private
   */
  private handleFormControlSizeClasses(element: HTMLElement): void {
    if (this.class.includes('form-control-sm')) {
      this.elementRef?.nativeElement.classList.remove('form-control-sm');
      element.classList.add('form-control-sm');
    } else if (this.class.includes('form-control-lg')) {
      this.elementRef?.nativeElement.classList.remove('form-control-lg');
      element.classList.add('form-control-lg');
    }
  }
}
