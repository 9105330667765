import { Component, ElementRef, Input, Optional, Self } from '@angular/core';
import { NgControl } from '@angular/forms';
import { FormInputBase } from '../form-input/form-input-base';

@Component({
  selector: 'form-toggle',
  templateUrl: './form-toggle.component.html',
  styleUrls: ['./form-toggle.component.scss'],
})
export class FormToggleComponent extends FormInputBase {
  @Input() value: boolean = false;

  constructor(@Self() @Optional() ngControl: NgControl, elementRef: ElementRef<HTMLElement>) {
    super(ngControl, elementRef);
  }
}
