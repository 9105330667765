import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { Router } from '@angular/router';
import { PopoverMenuItem } from '../../../popover-menu-item';
import { PopoverMenuService } from '../../../popover.service';

/**
 * MenuComponent handles the display and interaction of a popover menu.
 * It manages visibility, scrolling behavior, and various menu item actions.
 */
@Component({
  selector: 'popover-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss', './../../../scss/global.scss'],
})
export class MenuComponent implements AfterViewInit, OnDestroy {
  /**
   * Controls the visibility of the menu
   * @default false
   */
  public visible = false;

  /**
   * Tracks if the page has been scrolled to adjust positioning
   * @default false
   */
  public is_scrolling = false;

  /**
   * The menu items to be displayed
   * @default []
   */
  @Input() items: PopoverMenuItem[] = [];

  /**
   * Emits when the popover is closed
   */
  @Output() onClosed: EventEmitter<boolean> = new EventEmitter();

  /**
   * Emits when a content outlet menu item is clicked
   */
  @Output() onContentOutletClick: EventEmitter<PopoverMenuItem> = new EventEmitter();

  /**
   * Emits when an iframe menu item is clicked
   */
  @Output() onIframeOutletClick: EventEmitter<PopoverMenuItem> = new EventEmitter();

  /**
   * Emits when a menu outlet item is clicked
   */
  @Output() onMenuOutletClick: EventEmitter<PopoverMenuItem> = new EventEmitter();

  private readonly scrollHandler: () => void;
  private readonly escapeHandler: (event: KeyboardEvent) => void;

  constructor(
    private popoverSvc: PopoverMenuService,
    private router: Router,
  ) {
    this.scrollHandler = () => this.handleScroll();
    this.escapeHandler = (event: KeyboardEvent) => {
      if (event.key === 'Escape') this.close(true);
    };
  }

  ngAfterViewInit(): void {
    window.addEventListener('scroll', this.scrollHandler);
    window.addEventListener('keydown', this.escapeHandler);
  }

  ngOnDestroy(): void {
    window.removeEventListener('scroll', this.scrollHandler);
    window.removeEventListener('keydown', this.escapeHandler);
    this.popoverSvc.enableBodyScrolling();
  }

  /**
   * Handles scroll events to adjust menu positioning
   */
  private handleScroll(): void {
    if (window.innerWidth > 1366) {
      this.is_scrolling = window.scrollY >= 50;
    }
  }

  /**
   * Opens the menu and disables body scrolling
   */
  public open(): void {
    this.visible = true;
    this.popoverSvc.disableBodyScrolling();
  }

  /**
   * Toggles the menu visibility and manages body scrolling
   */
  public toggle(): void {
    this.visible = !this.visible;

    if (this.visible) {
      this.popoverSvc.disableBodyScrolling();
    } else {
      this.popoverSvc.enableBodyScrolling();
      this.onClosed.emit(true);
    }
  }

  /**
   * Closes the menu and handles any associated actions
   * @param enable_body_scrolling - Whether to enable body scrolling after closing
   * @param item - Optional menu item that triggered the close
   */
  public close(enable_body_scrolling: boolean, item?: PopoverMenuItem): void {
    this.visible = false;

    if (enable_body_scrolling) {
      this.popoverSvc.enableBodyScrolling();
    }

    if (!item) return;

    try {
      if (item.action.type === 'route') {
        const route: string[] = [...(item.route_prefix || []), ...item.action.url.split('/')];
        this.onClosed.emit(true);
        this.router.navigate(route).catch((error) => {
          console.error('Navigation failed:', error);
        });
      } else if (item.action.type === 'new_tab') {
        const newWindow = window.open(item.action.url, '_blank');
        if (!newWindow) {
          console.warn('Popup blocker may have prevented opening the new window');
        }
      } else if (item.action.type === 'iframe') {
        this.onIframeOutletClick.emit(item);
      } else if (item.action.type === 'content_outlet') {
        this.onContentOutletClick.emit(item);
      } else if (item.action.type === 'menu') {
        this.onMenuOutletClick.emit(item);
      }
    } catch (error) {
      console.error('Error handling menu item action:', error);
    }
  }
}
