import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { FormBtnComponent } from './components/form-btn/form-btn.component';
import { AdvisoryType } from './components/form-select/types/advisor-types';

@Injectable({
  providedIn: 'root',
})
export class SharedFormService {
  /**
   * The form currently submitting
   */
  public onStatusChange$ = new BehaviorSubject<HTMLFormElement | undefined>(undefined);

  /**
   * The id of the form form-btn that was clicked
   */
  public onBtnClicked$ = new Subject<FormBtnComponent>();

  /**
   * While filling out a form, a particular advisor type may be selected to display different or additional fields.
   */
  public advisorType$ = new BehaviorSubject<AdvisoryType>(AdvisoryType.ADVISOR);

  /**
   * Is the type of advisor a group?
   */
  public get isAdvisoryGroup(): boolean {
    return this.advisorType$.value === AdvisoryType.GROUP;
  }
}
