import DeltaStatic, { Delta } from 'quill';
import { DatabaseRecord } from '../mixins/database-record';

/**
 * The Email Template Folder object
 */
export class EmailTemplateFolder extends DatabaseRecord() {
  /**
   * The ID of the parent folder
   */
  email_template_folder_id: null | number = null;

  /**
   * The name of the folder
   */
  name: string = '';

  /**
   * The child directories within this folder
   */
  children: EmailTemplateFolder[] = [];

  /**
   * The email templates within this folder
   */
  templates: EmailTemplate[] = [];

  constructor(data?: EmailTemplateFolder) {
    super(data);
    Object.assign(this, data);

    if (data) {
      if (data.children) this.children = data.children.map((child) => new EmailTemplateFolder(child));
      if (data.templates) this.templates = data.templates.map((template) => new EmailTemplate(template));
    }
  }
}

/**
 * The Email Template object
 * Note: This is a legacy class ported from the old project and should likely have some property names updated to be more consistent with the rest of the project.
 */
export class EmailTemplate extends DatabaseRecord() {
  /**
   * The display order of the template
   */
  public order?: number = 0;

  /**
   * Add the template to the email gallery for Advisors to access with a co-branded footer.
   */
  public email_gallery = true;

  /**
   * Add the template to the email gallery
   */
  public add_to_email_gallery = true;

  /**
   * Add the template to the email gallery
   */
  public add_to_advisor_portal = true;

  /**
   * Add the template to the email gallery
   */
  public add_to_compliance_portal = true;

  /**
   * Add the template to the email gallery
   */
  public add_to_ltci_specialist_portal = true;

  /**
   * Export group for email template
   */
  public export_group = '';

  /**
   * Has co brand footer
   */
  public has_footer_image = false;

  /**
   * The old template theme
   */
  public theme = '';

  /**
   * The label of the template. e.g. 'AG - Agreement Follow-Up: Schedule (INDEPENDENT)'
   */
  public label = '';

  /**
   * The type of the template. e.g. center, left, or ecard. Defaults to 'center'
   */
  public type: 'standard' | 'left_align' | 'ecard' = 'standard';

  /**
   * The export format of the template. Defaults to 'ablebits'
   */
  public export_as: 'ablebits' | 'mailchimp' | 'oncehub' | 'agencybloc' = 'ablebits';

  /**
   * The header image source for the template.
   */
  public backgroundImageName = 'https://placehold.it/600x800?text=Select+an+image';

  /**
   * The header image source for all the template in email gallery page.
   */
  public image_src?: string;

  /**
   * The profile image source for the template.
   */
  public profileImageName?: string;

  /**
   * The subject line of the sent email
   */
  public subject?: string;

  /**
   * The attachments to the email
   */
  public attachments: EmailAttachment[] = [];

  /**
   * The group of the template. e.g. 'Health (Macros)'
   */
  public template_group?: string;

  /**
   * The body of the email
   */
  public items?: EmailTemplateBody.Item[] = [];

  /**
   * The HTML of the email
   */
  public html?: string;

  /**
   * The Macros of the email template
   */
  public macros?: Macros = new Macros();

  /**
   * The footer of the email
   */
  public footer?: Footer = new Footer();

  /**
   * The signature
   */
  public add_signature?: Signature = new Signature();

  /**
   * The selected ltci specialist
   */
  public ltci_specialist?: LTCISPECIALIST = new LTCISPECIALIST();

  /**
   * The co brand logo
   */
  public co_brand_logo?: CoBrandLogo = new CoBrandLogo();

  /**
   * path for the template
   */
  public path?: string;

  /**
   * folder id for the template
   */
  public email_template_folder_id?: number = 0;

  /**
   * file name for the template
   */
  public file_name = '';

  constructor(data?: EmailTemplate) {
    super(data);
    Object.assign(this, data);
  }

  /**
   * Gets the macros from the template
   */
  public get html_macros(): { label: string; value: string }[] {
    // The regex to match the macros in the EmailTemplate
    const regex: RegExp = /~%WHAT_TO_ENTER\[(.*?)\]/gm;

    // The macros extracted from the EmailTemplate. Should return an array of strings like:
    // ["~%WHAT_TO_ENTER[Advisor First Name]", "~%WHAT_TO_ENTER[Custom message]"]
    const matches = this.html?.match(regex);

    // If there are macros, map them to an array of objects with a label and value property
    return (
      matches?.map((macro) => {
        const label = macro.replace('~%WHAT_TO_ENTER[', '').replace(']', '');
        return {
          label,
          value: '',
        };
      }) || []
    );
  }

  public exportAs = {
    /**
     * Export the template as an HTML string.
     * @returns {string}
     */
    html: () => {
      const textarea = document.createElement('textarea');
      if (this.html) textarea.innerHTML = this.html;
      return textarea.value;
    },

    /**
     * Export the template as an XML Ablebits templateItem.
     * @returns {XMLDocument}
     */
    ablebits: () => {
      const xml = document.implementation.createDocument('', '', null);
      const templateItem = xml.createElement('templateItem');
      templateItem.setAttribute('name', this.label);
      templateItem.setAttribute('group', 'false');
      if (this.html) templateItem.setAttribute('templatefiledata', this.html);
      templateItem.setAttribute('templatefilemetadata', this.subject || this.label);
      return templateItem;
    },
  };
}

export namespace EmailTemplateBody {
  export class Item {
    /**
     * The display order of the item
     */
    public order = 0;

    /**
     * What type of item this is
     */
    public casts: 'text' | 'button' | 'spacer' | 'conference' | 'footer' | 'left_align_content' = 'text';

    /**
     * The height of the containing element
     */
    public height?: string;

    /**
     * The width of the containing element
     */
    public width?: string;

    /**
     * The color of the text displayed in the item
     */
    public color?: string;

    /**
     * The font family of the item
     */
    public fontFamily = 'Century Gothic';

    /**
     * The font size of the item
     */
    public fontSize?: string;

    /**
     * The left padding of the item
     */
    public paddingLeft?: string;

    /**
     * The bottom padding of the item
     */
    public paddingBottom?: string;

    /**
     * The inner text of the item
     */
    public innerText?: string;

    /**
     * quill delta object of saved email template item
     */
    public delta?: DeltaStatic;

    /**
     * if the saved item is a html button
     */
    public isHtmlButton?: boolean;

    /**
     * the button link of saved email template item
     */
    public button_link?: string;

    /**
     * the button text color of saved email template item
     */
    public button_text_color?: string;

    /**
     * the button background color of saved email template item
     */
    public button_color?: string;

    /**
     * the button text of saved email template item
     */
    public button_text?: string;

    /**
     * the type of saved email template item
     */
    public type?: 'Spacer' | 'QuillText' | 'Conference' | 'Button';

    /**
     * the quill text message of saved email template item
     */
    public text?: string;

    /**
     * the spacer height of saved email template item
     */
    public spacer_height?: number;

    /**
     * the conference of saved email template item
     */
    public conference?: string;

    /**
     * the saved button has a url or not
     */
    public isUrl?: boolean;

    /**
     * Casts the object to either a Spacer, Button, or Item.
     *
     * @return {Spacer | Button | Item} The casted object.
     */
    public cast(): Spacer | Button | Item | Conference | QuillText | Footer | LeftAlignContent {
      if (this.casts === 'spacer') {
        return new Spacer(this);
      } else if (this.casts === 'button') {
        return new Button(this);
      } else if (this.casts === 'conference') {
        return new Conference(this);
      } else if (this.casts === 'text') {
        return new QuillText(this);
      } else if (this.casts === 'footer') {
        return new Footer(this);
      } else if (this.casts === 'left_align_content') {
        return new LeftAlignContent(this);
      }

      return this;
    }

    constructor(...args: any[]) {
      Object.assign(this, ...args);
    }
  }

  /**
   * A spacer item in the email body.
   */
  export class Spacer extends Item {
    public height = '50px';
    public width = '38px';
    public innerText = '&nbsp;';

    constructor(...args: any[]) {
      super(args);
      Object.assign(this, ...args);
      this.casts = 'spacer';
    }
  }

  /**
   * A button item in the email body.
   */
  export class Button extends Item {
    public height = '50px';
    public width = '38px';
    public innerText = '';
    public href = '';
    public lineHeight = '21px';
    public fontSize = '18px';
    public backgroundColor = '';
    public color = '';
    public isImageButton = false;

    constructor(...args: any[]) {
      super(args);
      Object.assign(this, ...args);
      this.casts = 'button';
    }
  }

  /**
   * The footer object for Email Templates
   */
  export class Footer extends Item {
    /**
     * The URL of the co-branded logo
     */
    // public cobrand_href = 'https://placehold.it/600x161?text=Co-branded+logo';
    public cobrand_href = '';
    /**
     * The URL of our branded footer logo
     */
    public brand_href = 'assets/agingplan/email-footer/AgingPlan_Standard.png';

    /**
     * The contact phone number
     */
    public contact_phone = '509.744.7065';
    /**
     * The contact email
     */
    public contact_email = 'scheduling@agingplan.com';

    /**
     * The copyright text
     */
    public copyright = '\u00a9 2022 AgingPlan,  ALL RIGHTS ARE RESERVED.';

    constructor(...args: any[]) {
      super(args);
      Object.assign(this, ...args);
      this.casts = 'footer';
    }

    /**
     * Returns a phone link URL based on the contact phone number.
     *
     * @return {string} The phone link URL.
     */
    public get phoneLink(): string {
      return `tel:${this.contact_phone.replaceAll('.', '')}`;
    }
  }

  /**
   * The left align object for Email Templates
   */
  export class LeftAlignContent extends Item {
    /**
     * The URL of our branded footer logo
     */
    public brand_href = 'assets/agingplan/email-footer/AgingPlan_LeftAligned.png';

    constructor(...args: any[]) {
      super(args);
      Object.assign(this, ...args);
      this.casts = 'left_align_content';
    }
  }

  /**
   * A conference item in the email body.
   */
  export class Conference extends Item {
    public line1 = ['Dial-in Conference Number', '1-253-215-8782'];
    public line2 = ['704 280 6813', '504910'];

    constructor(...args: any[]) {
      super(args);
      Object.assign(this, ...args);
      this.casts = 'conference';
    }
  }

  /**
   * A conference item in the email body.
   */
  export class QuillText extends Item {
    /**
     * The Quill Delta
     */
    public delta?: DeltaStatic;

    constructor(...args: any[]) {
      super(args);
      Object.assign(this, ...args);
      this.casts = 'text';
    }
  }
}

export interface EmailTemplateGroups {
  [key: string]: EmailTemplate[];
}

/**
 * The Email Attachment object that can be added to an Email Template
 */
class EmailAttachment extends DatabaseRecord() {
  /**
   * The title of the attachment
   */
  public title = '';

  /**
   * The type of attachment. e.g. 'thrivent', 'non-thrivent' or 'tan'.
   * @default 'thrivent'
   */
  public type: 'thrivent' | 'non-thrivent' | 'tan' = 'thrivent';

  /**
   * The file names of the attachments
   * @default []
   */
  public files: string[] = [];

  /**
   * The target of the attachment. e.g. 'individual', 'agent' or 'both'
   * @default 'individual'
   */
  public target: 'individual' | 'agent' | 'both' = 'individual';
}

export class Macros {
  public clear = true;
  public fill_to = { is_checked: true, fill_to_text: '' };
  public fill_cc = { is_checked: false, fill_cc_text: '' };
  public fill_bcc = { is_checked: false, fill_bcc_text: '' };
  public fill_subject = { is_checked: true, fill_subject_text: '' };

  constructor(data?: Macros) {
    Object.assign(this, data);
  }
}

export class Signature {
  public is_checked = false;
  public signature = '/assets/footer-signature/AgingPlan_Employee_Signatures.png';

  constructor(data?: Signature) {
    Object.assign(this, data);
  }
}

export class LTCISPECIALIST {
  public is_checked = false;
  public contact_number = '';
  public email = '';
  public profile = '';
  public email_type: LTCI_EMAIL_TYPE = 'info_email';
  public id = 0;
  public agentID = 0;
  constructor(data?: Signature) {
    Object.assign(this, data);
  }
}
export class CoBrandLogo {
  public is_checked = false;
  public image = '';

  constructor(data?: CoBrandLogo) {
    Object.assign(this, data);
  }
}
export class Footer {
  public backgound_color = '';
  public contact_number = '';
  public email = '';
  public copyright = '';
  public copyright_color = '';
  public contact_color = '';
  public footer_message = '';

  constructor(data?: Footer) {
    Object.assign(this, data);
  }
}

/**
 * A subset of properties from the EmailTemplate type.
 */
type EmailTemplateSubset = Pick<
  EmailTemplate,
  | 'label'
  | 'template_group'
  | 'html'
  | 'macros'
  | 'footer'
  | 'add_signature'
  | 'ltci_specialist'
  | 'co_brand_logo'
  | 'path'
  | 'email_template_folder_id'
  | 'file_name'
  | 'image_src'
  | 'items'
  | 'order'
>;

/**
 * Represents a tree node structure that includes a subset of email template properties and additional properties.
 */
export interface EmailTemplateNode extends EmailTemplateSubset {
  /**
   * Unique identifier for the tree node.
   */
  node_label: string;

  /**
   * Array of child nodes.
   */
  children: EmailTemplateNode[];

  /**
   * Type of the email template. Can be 'standard', 'left_align', or 'ecard'.
   */
  template_type?: 'standard' | 'left_align' | 'ecard';

  /**
   * Date when the template was last modified.
   */
  modified_date?: string;

  /**
   * Indicates if the node represents a file.
   */
  is_file?: boolean;

  /**
   * Indicates if the node is expanded in the tree view.
   */
  isExpanded?: boolean;

  /**
   * Indicates if the folder node is expanded in the tree view.
   */
  isFolderExpanded?: boolean;

  /**
   * Indicates if the node can be expanded to show children.
   */
  isExpandable?: boolean;

  /**
   * Indicates if the node can be moved within the tree structure.
   */
  isMoveable?: boolean;

  /**
   * Indicates if the node is selected.
   */
  selected?: boolean;

  /**
   * Identifier for the file, if the node represents a file.
   */
  file_id?: number;

  /**
   * Identifier for the folder, if the node represents a folder.
   */
  folder_id?: number;

  /**
   * Identifier for the folder, if the node represents a parent folder.
   */
  parent_folder_id?: null | number;
}

/**
 * Represents a enum for ltci email type
 */
export type LTCI_EMAIL_TYPE = 'info_email' | 'scheduling_email' | 'application_email' | 'client_services_email' | 'commissions_email';

/**
 * Represents a interface for email builder form
 */
export interface EmailTemplateForm {
  label: string;
  theme: '#ffffff' | '#46413d';
  type: 'standard' | 'left_align' | 'ecard';
  subject: string;
  footer_message: string;
  template: boolean;
  add_to_email_group: boolean;
  add_to_ltci_specialist_portal: boolean;
  add_to_compliance_portal: boolean;
  add_to_advisor_portal: boolean;
  html_button: boolean;
  export_group: string;
  attachement_group: string;
  cobranded: boolean;
  template_group: string;
  footer_theme: 'white_footer' | 'black_footer';
  footer_background_color: string;
  header_image: string;
  profileImageName: string;
  footer_contact: string;
  footer_contact_color: string;
  footer_copyright: string;
  footer_copyright_color: string;
  html_elements: any[];
  FooterBg1: string;
  FooterBg2: string;
  FooterBg3: string;
  FooterBg4: string;
  macro_groups: {
    clear: boolean;
    fill_to: {
      is_checked: boolean;
      fill_to_text: string;
    };
    fill_cc: {
      is_checked: boolean;
      fill_cc_text: string;
    };
    fill_bcc: {
      is_checked: boolean;
      fill_bcc_text: string;
    };
    fill_subject: {
      is_checked: boolean;
      fill_subject_text: string;
    };
  };
  co_brand_logo: {
    is_checked: boolean;
    image: string;
  };
  add_signature: {
    is_checked: boolean;
    signature: string;
  };
  add_aginPlan_logo: {
    is_checked: boolean;
    aging_plan_logo: string;
  };
  ltci_specialist: {
    is_checked: boolean;
    contact_number: string;
    email: string;
    profile: string;
  };
}
