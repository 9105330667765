import { Component, ElementRef, HostBinding, Input, OnInit, Optional, Self, ViewChild } from '@angular/core';
import { AbstractControl, ControlValueAccessor, NgControl, Validator, ValidatorFn, Validators } from '@angular/forms';
import { ImagePickerConf } from '@codebuilt/ngp-image-picker';

@Component({
  selector: 'form-img',
  templateUrl: './form-img.component.html',
  styleUrls: ['./form-img.component.scss'],
})
export class FormImgComponent implements ControlValueAccessor, Validator, OnInit {
  @ViewChild('input') inputElement?: ElementRef<HTMLInputElement>;
  @ViewChild('label') labelElement?: ElementRef<HTMLLabelElement>;
  @Input() value = '';
  @HostBinding('attr.label') @Input() label?: string;
  @HostBinding('attr.help_text') @Input() help_text?: string;
  @HostBinding('attr.class') @Input() class = '';
  @HostBinding('attr.disabled') @Input() disabled = false;
  @HostBinding('attr.required') @Input() required = false;
  @HostBinding('attr.id') @Input() id = Math.random().toString(36).substring(7);

  /**
   * The configuration for the image picker
   * Reference: https://github.com/josealejandro2928/ngp-image-picker#more-about-the-component
   */
  @HostBinding('attr._config') @Input() config: ImagePickerConf = {
    borderRadius: '0px',
    width: '100%',
    height: '140px',
    objectFit: 'contain'
  };

  /**
   * Determines if the input has errors
   */
  public get hasErrors() {
    // console.log(this.ngControl.control?.touched, this.ngControl.control?.errors, this.ngControl.control)
    return !this.ngControl.control?.valid && this.ngControl.control?.touched;
  }

  /**
   * @Self() We want to retrieve the dependency only from the local injector, not from the parent or ancestors.
   * @Optional() We want to be able to use the component without a form, so we mark the dependency as optional.
   * @param {NgControl} ngControl - A base class that all FormControl-based directives extend. It binds a FormControl object to a DOM element.
   */
  constructor(@Self() @Optional() public ngControl: NgControl, private elementRef: ElementRef<HTMLElement>) {
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }

  /**
   * The ngOnInit() function is called after the constructor of the component.
   * Sets the validators and update the value and validity of the control
   */
  ngOnInit() {
    const control = this.ngControl.control!;
    const validators: ValidatorFn[] = control.validator ? [control.validator] : [];

    // If the input type is 'image', add the pattern validator
    // if (!validators.length) {
    //   validators.push(Validators.pattern(/^(data:image\/\*;base64,).*/));
    // }

    // If the input is required, add the required validator
    if (this.required) {
      validators.push(Validators.required);
    }

    control.setValidators(validators);
    control.updateValueAndValidity();

    if (this.elementRef.nativeElement && this.class.length) {
      for (let token of this.class.trim().split(' ')) {
        this.elementRef.nativeElement.classList.add(token);
      }
    }
  }

  /**
   * Pass the element's change event to the parent
   */
  public onChange(value: any): void {}

  /**
   * The onTouched() method is called when the control status changes to or from "untouched".
   * This event is fired whenever the value changes, but not when the control status changes to or from "pristine"
   */
  public onTouched(): void {}

  /**
   * Write the value of the input to the model
   * @param {any} value - The value to be set on the model.
   */
  public writeValue(value: any): void {
    this.value = value;
  }

  /**
   * It tells the component that when the value of the input changes, the function onChange will be called.
   * @param {fn: (_: any) => void} fn - any
   */
  public registerOnChange(fn: (_: any) => void): void {
    this.onChange = fn;
  }

  /**
   * The registerOnTouched function is used to register a callback that will be called when the control is touched
   * @param {any} fn - any
   */
  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  /**
   * Set the disabled state of the element
   * @param {boolean} isDisabled - boolean
   */
  public setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  /**
   * Returns an object with a key for each validator that failed
   * @param {AbstractControl} c - AbstractControl
   * @returns Nothing.
   */
  public validate(c: AbstractControl): { [key: string]: any } {
    const validators: ValidatorFn[] = [];
    if (this.required) {
      validators.push(Validators.required);
    }

    return validators;
  }
}
