import { Component } from '@angular/core';

/**
 * The footer for forms
 */
@Component({
  selector: 'form-footer',
  templateUrl: './form-footer.component.html',
  styleUrls: ['./form-footer.component.scss'],
})
export class FormFooterComponent {
  /**
   * The current year
   */
  public get year() {
    return new Date().getFullYear().toString();
  }
}
