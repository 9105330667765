import { NgOption } from '@ng-select/ng-select';

/**
 * The list of height in foot and inchs
 */
export const heights: NgOption[] = [
  {
    label: "4'5\"",
    value: "4'5",
  },
  {
    label: "4'6\"",
    value: "4'6",
  },
  {
    label: "4'7\"",
    value: "4'7",
  },
  {
    label: "4'8\"",
    value: "4'8",
  },
  {
    label: "4'9\"",
    value: "4'9",
  },
  {
    label: "4'10\"",
    value: "4'10",
  },
  {
    label: "4'11\"",
    value: "4'11",
  },
  {
    label: "4'12\"",
    value: "4'12",
  },
  {
    label: "5'",
    value: "5'",
  },
  {
    label: "5'1\"",
    value: "5'1",
  },
  {
    label: "5'2\"",
    value: "5'2",
  },
  {
    label: "5'3\"",
    value: "5'3",
  },
  {
    label: "5'4\"",
    value: "5'4",
  },
  {
    label: "5'5\"",
    value: "5'5",
  },
  {
    label: "5'6\"",
    value: "5'6",
  },
  {
    label: "5'7\"",
    value: "5'7",
  },
  {
    label: "5'8\"",
    value: "5'8",
  },
  {
    label: "5'9\"",
    value: "5'9",
  },
  {
    label: "5'10\"",
    value: "5'10",
  },
  {
    label: "5'11\"",
    value: "5'11",
  },
  {
    label: "5'12\"",
    value: "5'12",
  },
  {
    label: "6'",
    value: "6'",
  },
  {
    label: "6'1\"",
    value: "6'1",
  },
  {
    label: "6'2\"",
    value: "6'2",
  },
  {
    label: "6'3\"",
    value: "6'3",
  },
  {
    label: "6'4\"",
    value: "6'4",
  },
  {
    label: "6'5\"",
    value: "6'5",
  },
  {
    label: "6'6\"",
    value: "6'6",
  },
  {
    label: "6'7\"",
    value: "6'7",
  },
  {
    label: "6'8\"",
    value: "6'8",
  },
  {
    label: "6'9\"",
    value: "6'9",
  },
  {
    label: "6'10\"",
    value: "6'10",
  },
  {
    label: "6'11\"",
    value: "6'11",
  },
];
