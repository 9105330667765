import { Component, ElementRef, HostBinding, Input, ViewChild } from '@angular/core';

@Component({
  selector: 'lib-heading',
  templateUrl: './heading.component.html',
  styleUrls: ['./heading.component.scss']
})
export class HeadingComponent {
  /**
   * The heading text
   */
  @HostBinding('attr.heading') @Input() heading = 'Heading Placeholder';

  /**
   * The subheading text
   */
  @HostBinding('attr.subheading') @Input() subheading?: string;

  /**
   * Apply the padding to various elements which are similar to Lucid's website
   */
  @Input() lucid = false;

  /**
   * Apply the padding to various elements which are similar to article
   */
  @Input() article = false;

  /**
   * Component theme dark | light
   */
  @Input() theme: 'dark' | 'light' = 'light';

  /**
   * The title element
   */
  @ViewChild('h1') h1?: ElementRef<HTMLHeadingElement>;

  /**
   * The subtitle element
   */
  @ViewChild('h2') h2?: ElementRef<HTMLHeadingElement>;

  /**
   * an animation to apply to the `heading`, `subheading`, and first paragraph within `ng-content` elements
   */
  @Input() animation = false;

  constructor(private elementRef: ElementRef<HTMLDivElement>) {}

  ngAfterViewInit(): void {
    // Apply component theme
    this.elementRef.nativeElement.classList.add(this.theme);

    // Add the lucid class if lucid is true
    if (this.lucid) this.elementRef.nativeElement.classList.add('lucid');

    // Add the article class if article is true
    if (this.article) this.elementRef.nativeElement.classList.add('article');
  }
}
