import { Directive, ElementRef, HostBinding, HostListener, Input } from '@angular/core';

/**
 * A directive to handle the error event on an image element
 */
@Directive({
  selector: 'img',
})
export class ImageSrcErrorDirective {
  @HostBinding('attr.src') @Input() src?: string;

  /**
   * Has the error event been triggered?
   */
  @HostBinding('attr.has-error') error?: string;

  /**
   * A placeholder image to use when the image fails to load
   */
  private readonly placeholder = `
    <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100">
      <rect x="0" y="0" width="100" height="100" fill="#cccccc"/>
      <text x="50" y="50" font-size="12" text-anchor="middle" fill="#777777">check path...</text>
    </svg>
  `;

  /**
   * When the image fails to load, replace the image with a placeholder
   */
  @HostListener('error') loadFallbackOnError() {
    // Replace the image with a placeholder if the image fails to load and the placeholder has not already been loaded
    if (!this.elementRef.nativeElement.getAttribute('has-error')) {
      // Set the has-error attribute to true
      this.elementRef.nativeElement.setAttribute('has-error', 'true');

      // Replace the image with a placeholder
      this.elementRef.nativeElement.onerror = () => {
        this.elementRef.nativeElement.src = `data:image/svg+xml;base64,${btoa(this.placeholder)}`;
      };

      if (this.src) {
        this.elementRef.nativeElement.src = this.src;
      }
    }
  }

  constructor(private elementRef: ElementRef<HTMLImageElement>) {}
}
