import { Component, EventEmitter, Output } from '@angular/core';
import { PopoverMenuService } from '../../popover.service';

@Component({
  selector: 'popover-close-button',
  templateUrl: './close-button.component.html',
  styleUrls: ['./close-button.component.scss'],
})
export class CloseButtonComponent {
  @Output() onClosedClick = new EventEmitter<boolean>(false);

  constructor(private popoverSvc: PopoverMenuService) {}

  public close(): void {
    this.popoverSvc.close();
    this.onClosedClick.emit(true);
  }
}
