import { AfterViewInit, Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[smooth-scroll]',
})
export class SmoothScrollDirective implements AfterViewInit {
  @HostListener('window:resize', ['$event']) onResize() {
    document.body.style.height = this.element.nativeElement.scrollHeight + 'px';
  }

  constructor(private element: ElementRef) {}

  ngAfterViewInit(): void {
    // Check weather or not we are using a touch device
    const is_touch_device = 'ontouchstart' in document.documentElement;

    if (!is_touch_device) {
      document.body.style.overflow = '';

      new (<any>window).SmoothScroll({
        target: document.querySelector('.main'),
        scrollEase: 0.08,
        maxOffset: 500,
      });
    }
  }
}
