<div class="footer-bg">
  <div class="container">
    <div class="footer">
      <div class="row footer-content">
        <div class="col-lg-4">
          <div class="brand-logo brand-footer">
            <img src="assets/images/LARGEW-footer.svg" />
            <span>WOLF &amp; ASSOCIATES</span>
          </div>
        </div>
        <div class="col-lg-8 footer-right">
          <div class="row mt-3 mb-md-4 mb-5 d-flex justify-content-center align-items-center">
            <div class="col-lg-4 col-5 text-center">
              <img class="footer-icon" src="assets/images/hippa.png" alt="Hippa" />
            </div>
            <div class="col-lg-4 col-5 text-center">
              <a href="#" target="_blank">
                <img class="footer-icon" src="assets/images/secure.png" alt="Secure" />
              </a>
            </div>
            <!-- <div class="col-4 text-center">
                        <img class="footer-icon" src="assets/images/norton.png" alt="Norton">
                    </div> -->
          </div>
          <h5 class="footer-title text-left px-4 px-md-0 mb-3 mt-5">The security of your personal data is our highest concern!</h5>
          <p class="text-justify px-4 px-md-0 mb-2">
            Wolfltc.com servers are currently using the highest SSL protocols with 256-bit encryption and Secure Sockets Layer (SSL) version 3.0 protocol. These technologies provide the highest level
            of security and privacy when you send secure messages.
          </p>
          <p class="text-justify px-4 px-md-0 m-0">
            Our priority is the protection and reliability of customer data. Our servers are protected by high-end WatchGuard firewall systems, and scans are performed regularly to ensure that any
            vulnerabilities are quickly found and patched.
          </p>
          <!-- <p class="text-right more-info"><a href="">&raquo; more info</a></p> -->
        </div>
      </div>
      <hr />
      <p class="copyright">&copy; 2005-{{ year }} WOLF &amp; ASSOCIATES, ALL RIGHTS RESERVED</p>
    </div>
  </div>
</div>
