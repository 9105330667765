import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse, HttpXsrfTokenExtractor } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, catchError, of, throwError } from 'rxjs';

@Injectable()
export class AuthenticationInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private tokenService: HttpXsrfTokenExtractor,
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const token = this.tokenService.getToken();

    // If the token is not null and the request does not already have the XSRF token header, add it
    if (token !== null && !request.headers.has('X-XSRF-TOKEN')) {
      request = request.clone({ headers: request.headers.set('X-XSRF-TOKEN', token) });
    }

    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        // console.warn(error);

        if (error.status === 401 || error.status === 419) {
          // If the error is a 401 or 419, redirect to the login page if they are not already there
          if (!this.router.url.includes('/auth/login')) {
            this.router.navigate(['/auth/logout']);
          }
        } else if (error.status === 403) {
          // If the error is a 403, redirect to the not verified page
          this.router.navigate(['/errors/not-verified']);
        } else if (error.status === 404) {
          // If the error is a 404, redirect to the not found page
          // this.router.navigate(['/errors/not-found']);
          return of(new HttpResponse({ status: 404 }));
        }

        // Return the error
        return throwError(() => error);
      }),
    );
  }
}
