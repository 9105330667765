import { HeadingModule, PopoverModule } from '@agingplan';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FooterComponent } from './footer.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';

@NgModule({
  declarations: [FooterComponent, PrivacyPolicyComponent, TermsAndConditionsComponent],
  exports: [FooterComponent],
  imports: [CommonModule, PopoverModule, RouterModule, HeadingModule],
})
export class FooterModule {}
