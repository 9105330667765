import { Component, EventEmitter, HostBinding, Input, OnDestroy, Output } from '@angular/core';
import { PopoverMenuService } from '../../popover.service';

/**
 * Component that provides a popover content outlet with configurable behavior
 * including slide animations, navbar spacing, and visibility controls.
 * Handles body scrolling and cleanup automatically.
 */
@Component({
  selector: 'popover-content-outlet',
  templateUrl: './content-outlet.component.html',
  styleUrls: ['./content-outlet.component.scss', './../../scss/global.scss', './../../scss/container.scss'],
})
export class ContentOutletComponent implements OnDestroy {
  /**
   * Is the popover visible?
   */
  public visible = false;

  /**
   * Reference to the navbar element
   */
  private navbarElement: HTMLElement | null = null;

  /**
   * Bound event listener for cleanup
   */
  private escapeListener: (event: KeyboardEvent) => void;

  /**
   * Should there be room for the navbar at the top?
   * @param top - The top spacing in pixels
   */
  @Input() set navbar_spacing(top: number) {
    this.top = `${top}px`;
  }

  /**
   * Hide navbar view content in full screen
   * @default false
   */
  @Input() hide_navbar = false;

  /**
   * Should the close button be visible
   * @default true
   */
  @Input() show_close_button = true;

  /**
   * Set the box shadow of the component.
   * @default 'none'
   */
  @Input() shadow = 'none';

  /**
   * Change the default slide direction
   * @default 'slide-left'
   */
  @Input() slide: 'slide-up' | 'slide-left' | 'slide-down' | 'slide-right' | 'slide-up-reverse' | 'slide-left-reverse' | 'slide-down-reverse' | 'slide-right-reverse' = 'slide-left';

  /**
   * Bind to the top css attribute of the host
   */
  @HostBinding('style.top') top = '0';

  /**
   * Emits when the popover opens
   */
  @Output() onOpened = new EventEmitter<ContentOutletComponent>();

  /**
   * Emits when the popover closes
   */
  @Output() onClosed = new EventEmitter<ContentOutletComponent>();

  constructor(private popoverSvc: PopoverMenuService) {
    // Bind the escape listener to this instance
    this.escapeListener = (event: KeyboardEvent) => {
      if (event.key === 'Escape') this.close();
    };

    // Add escape key listener
    window.addEventListener('keydown', this.escapeListener);

    // Cache navbar element reference
    this.navbarElement = document.querySelector('nav.navbar');
  }

  /**
   * Cleanup event listeners and references on component destroy
   */
  ngOnDestroy(): void {
    window.removeEventListener('keydown', this.escapeListener);
    this.navbarElement = null;
  }

  /**
   * Toggle the visibility of the popover
   */
  public toggle(): void {
    if (!this.visible) {
      this.open();
    } else {
      this.close();
    }
  }

  /**
   * Open the popover and handle associated side effects
   */
  public open(): void {
    try {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });

      this.visible = true;
      this.popoverSvc.disableBodyScrolling();
      this.onOpened.emit(this);

      if (this.hide_navbar && this.navbarElement) {
        this.navbarElement.classList.add('d-none');
      }
    } catch (error) {
      console.error('Error opening popover:', error);
    }
  }

  /**
   * Close the popover with animation and cleanup
   */
  public close(): void {
    try {
      this.slide = `${this.slide}-reverse` as typeof this.slide;

      // Add set timeout for reverse animation
      setTimeout(() => {
        this.visible = false;
        this.popoverSvc.enableBodyScrolling();
        this.onClosed.emit(this);

        if (this.hide_navbar && this.navbarElement) {
          this.navbarElement.classList.remove('d-none');
        }

        // Reset slide direction by removing '-reverse' suffix
        const baseSlide = this.slide.replace('-reverse', '') as typeof this.slide;
        this.slide = baseSlide;
      }, 800);
    } catch (error) {
      console.error('Error closing popover:', error);
    }
  }
}
