import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Optional, Output, Self, ViewChild } from '@angular/core';
import { NgControl } from '@angular/forms';
import { ColorEvent } from 'ngx-color';
import { FormInputBase } from '../form-input/form-input-base';

/**
 * Input type color
 *
 * @example
 * Dark Themed Color Input with selected color code '#00000'
 * <form-input-color value="#B92D2D" class="dark-themed" />
 *
 * Light Themed Color Input with default color code '#000000'
 * <form-input-color class="light-themed" />
 */
@Component({
  selector: 'form-input-color',
  templateUrl: './form-input-color.component.html',
  styleUrls: ['./form-input-color.component.scss'],
})
export class FormInputColorComponent extends FormInputBase implements OnInit, AfterViewInit {
  constructor(
    @Self() @Optional() public ngControl: NgControl,
    elementRef: ElementRef<HTMLElement>,
  ) {
    super(ngControl, elementRef);
  }

  /**
   * Determines if the color selector box show/hide
   */
  show_color_selector: boolean = false;

  /**
   * to store the selected color hexcode;
   */
  selected_color = this.value;

  /**
   * The user's video chosen
   */
  @Output() handleBgColor = new EventEmitter();

  /**
   * this function toggle the color selector box show/hide
   * @returns void
   */
  public openColorSelector() {
    if (!this.disabled) this.show_color_selector = !this.show_color_selector;
  }

  /**
   * this function chnage the color picker value using HEX color code
   * @param selected_color HEX color code
   * @returns void
   */
  public onChangeColorHexCode(selected_color: string) {
    this.value = selected_color;
    this.handleBgColor.emit(this.value);
  }

  /**
   * On chnage the color from color selctor box
   * @param ColorEvent color picker on chnage event
   * @returns void
   */
  public onColorChange($event: ColorEvent) {
    this.selected_color = $event.color.hex;
  }

  /**
   * this function apply the color picker value after section from color selector
   * @returns void
   */
  public applyChangedColor() {
    this.show_color_selector = false;
    if (!this.disabled) {
      this.value = this.selected_color;
      this.onChange(this.value);
    }
  }

  // public onChange(value: any): void {}
  /**
   * this function close the color selector box
   * @returns void
   */
  public closeColorSector() {
    this.show_color_selector = false;
  }

  ngOnInit(): void {
    // Set default color if default color not set.
    if (this.value == '') {
      this.value = '#000000';
    }
  }
}
