import { NgOption } from '@ng-select/ng-select';

/**
 * Phone types
 */
export enum phone_types_options {
  CELL = 'Cell',
  HOME = 'Home',
  WORK = 'Work',
}

/**
 * Available phone type options
 */
export const phone_types: NgOption[] = [
  { label: phone_types_options.CELL, value: phone_types_options.CELL },
  { label: phone_types_options.HOME, value: phone_types_options.HOME },
  { label: phone_types_options.WORK, value: phone_types_options.WORK },
];
