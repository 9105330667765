import { Directive, ElementRef, HostListener, Input, OnInit } from '@angular/core';

@Directive({
  selector: '[format_input]',
})
export class FormatInputDirective implements OnInit {
  /**
   * The format to use
   */
  @Input('format_input') format_type?: FormatInputType;

  /**
   * When the user presses a key, check if the key is allowed. If it is, format the input value
   */
  @HostListener('keyup', ['$event']) private format(): void {
    let value = this.elementRef.nativeElement.value;

    if (value && this.format_type) {
      // Remove all non-numeric characters
      value = value.replace(/\D/g, '');

      if (this.format_type === 'fulldate') {
        value = this.asDate(value);
      } else if (this.format_type === 'shortDate') {
        value = this.asUSDateFormat(value);
      } else if (this.format_type === 'phone') {
        value = this.asPhoneNumber(value);
      } else if (this.format_type === 'height') {
        value = this.asHeight(value);
      } else if (this.format_type === 'zipcode') {
        value = this.asZipcode(value);
      }
      else if (this.format_type === 'phoneNumber') {
        value = this.asContactNumber(value);
      }

      this.elementRef.nativeElement.value = value;
    }
  }

  constructor(private elementRef: ElementRef<HTMLInputElement>) {}

  ngOnInit(): void {
    // Format the control once it loads
    this.format();
  }

  /**
   * Slice up to 8 digits and format them as a date
   * @param {string} value - The value of the input field
   * @returns {string} - The formatted value
   */
  private asDate(value: string): string {
    this.elementRef.nativeElement.setAttribute('maxlength', '10');
    this.elementRef.nativeElement.setAttribute('placeholder', 'MM/DD/YYYY');

    // Date is 8 digits. Remove the last digit if the user has entered more than 8 digits
    if (value.length >= 9) {
      value = value.substring(0, 9);
    }

    // Format the date
    if (value.length <= 2) {
      return value.replace(/^(\d{0,2})/, '$1');
    } else if (value.length <= 5) {
      return value.replace(/^(\d{0,2})(\d{0,2})/, '$1/$2');
    } else if (value.length <= 9) {
      return value.replace(/^(\d{0,2})(\d{0,2})(\d{0,4})/, '$1/$2/$3');
    } else {
      return value.substring(0, 9).replace(/^(\d{0,2})(\d{0,2})(\d{0,4})/, '$1/$2/$3');
    }
  }

  /**
   * Slice up to 8 digits and format them as a date in MM-DD-YYYY format
   * @param {string} value - The value of the input field
   * @returns {string} - The formatted value
   */
  private asUSDateFormat(value: string): string {
    this.elementRef.nativeElement.setAttribute('maxlength', '10');
    this.elementRef.nativeElement.setAttribute('placeholder', 'MM-DD-YYYY');

    // Date is up to 8 digits. Remove the last digit if the user has entered more than 8 digits
    if (value.length > 8) {
      value = value.substring(0, 8);
    }

    // Format the date
    if (value.length <= 2) {
      return value.replace(/^(\d{0,2})/, '$1');
    } else if (value.length <= 5) {
      return value.replace(/^(\d{0,2})(\d{0,2})/, '$1-$2');
    } else if (value.length <= 8) {
      // Format as MM-DD-YYYY
      const formattedValue = value.replace(/^(\d{0,2})(\d{0,2})(\d{0,4})/, '$1-$2-$3');
      return this.validateDate(formattedValue);
    } else {
      return value.substring(0, 8).replace(/^(\d{0,2})(\d{0,2})(\d{0,4})/, '$1-$2-$3');
    }
  }

  /**
   * Validate date input
   * @param {string} date - The value of the input field
   * @returns {string} - The formatted value
   */
  private validateDate(date: string): string {
    const parts = date.split('-');
    if (parts.length === 3) {
      const month = parseInt(parts[0], 10);
      const day = parseInt(parts[1], 10);

      // Validate month and day
      if (month > 12) {
        parts[0] = '12'; // Set to max month if exceeded
      }
      if (day > 31) {
        parts[1] = '31'; // Set to max day if exceeded
      }
    }

    return parts.join('-');
  }

  /**
   * Slice up to 10 digits and format them as a phone number
   * @param {string} value - The value of the input field
   * @returns {string} - The formatted value
   */
  private asPhoneNumber(value: string): string {
    // Phone number is 10 digits. Remove the last digit if the user has entered more than 10 digits
    if (value.length >= 11) {
      value = value.substring(0, 11);
    }

    // Format the phone number
    if (value.length <= 3) {
      return value.replace(/^(\d{0,3})/, '$1');
    } else if (value.length <= 6) {
      return value.replace(/^(\d{0,3})(\d{0,3})/, '($1) $2');
    } else {
      return value.substring(0, 10).replace(/^(\d{0,3})(\d{0,3})(\d{0,4})/, '($1) $2-$3');
    }
  }

  /**
 * Format input text as a phone number in the format 'xxx.xxx.xxxx'
 * or wrap it in a tel anchor tag.
 * @param {string} value - The input text to format
 * @returns {string} - The formatted phone number or an anchor tag with the formatted number
 */
  private asContactNumber(value: string): string {
    // Remove all non-numeric characters
    const cleanNumber = value.replace(/\D/g, '');

    // Phone number should be 10 digits, truncate if necessary
    const formattedNumber = cleanNumber.length > 10
      ? cleanNumber.substring(0, 10)
      : cleanNumber;

    // Format as xxx.xxx.xxxx
    return formattedNumber.toPhoneNumber();
  }


  /**
   * Slice up to 3 digits and format them as height
   * @param {string} value - The value of the input field
   * @returns {string} - The formatted value
   */
  private asHeight(value: string): string {
    // Remove any non-digit characters first
    value = value.replace(/\D/g, '');

    // Ensure the value has a maximum of 3 digits
    if (value.length > 3) {
      value = value.substring(0, 3);
    }

    // Format the height
    if (value.length === 1) {
      return `${value}'`;
    } else if (value.length === 2) {
      return `${value.charAt(0)}'${value.charAt(1)}"`;
    } else if (value.length === 3) {
      return `${value.charAt(0)}'${value.substring(1)}"`;
    }

    return value;
  }

  /**
   * Slice up to 3 digits and format them as height
   * @param {string} value - The value of the input field
   * @returns {string} - The formatted value
   */
  private asZipcode(value: string): string {
    // Zipcode is possibly 9 digits. Remove the last digit if the user has entered more than 9 digits
    if (value.length >= 9) {
      value = value.substring(0, 9);
    }

    // Format the height
    if (value.length <= 5) {
      return value.replace(/^(\d{0,5})/, `$1`);
    } else {
      return value.replace(/^(\d{0,5})(\d{0,4})/, `$1-$2`);
    }
  }
}

export const phone_regex = /^(\(\d{3}\)\s|\d{3}[-.\s]?)\d{3}[-.\s]?\d{4}$/;
export const fulldate_regex = /^((0?[1-9]|1[012])[- /.](0?[1-9]|[12][0-9]|3[01])[- /.](19|20)?[0-9]{2})*$/;
export const height_regex = /^\d'\s?(\d{1,2}")?$/;
export const password_regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&]).{8,}$/;
export const zipcode_regex = /^\d{5}(-\d{4})?$/;
export type FormatInputType = 'height' | 'phone' | 'fulldate' | 'zipcode' | 'shortDate' | 'phoneNumber';
