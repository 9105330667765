import { DatabaseRecord } from '../mixins/database-record';

export class TaxImplication extends DatabaseRecord() {
  public title = '';
  public subtitle = '';
  public content = '';
  public display_creation_date = false;
  public display_date = false;
  public order = 0;
  public published_at = new Date();

  constructor(data: TaxImplication) {
    super(data);
    Object.assign(this, data);
  }
}
