import { Directive, ElementRef, Renderer2, AfterViewInit } from '@angular/core';

@Directive({
  selector: '[agingplan-iframe]',
})
export class IFrameDirective implements AfterViewInit {
  constructor(private iframeEl: ElementRef, private renderer: Renderer2) {}

  ngAfterViewInit() {
    this.renderer.listen(this.iframeEl.nativeElement, 'error', () => {
      console.log('error caught in directive');
    });
  }
}
