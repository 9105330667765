import { NgOption } from "@ng-select/ng-select";

export const scopes: NgOption[] = [
  {value: '0', label: 'Select a scope...'},
  {value: '1', label: 'Thrivent'},
  {value: '2', label: 'TAN'},
  {value: '3', label: 'Independent'},
  {value: '4', label: 'Ronald Blue'},
  {value: '5', label: 'D.A. Davidson'}
]
