import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbTypeaheadConfig } from '@ng-bootstrap/ng-bootstrap';
import { Environment } from '../objects/environment';
import { SharedPipesModule } from '../pipes/shared-pipes.module';
import { FormComponentsModule } from './components/components.module';
import { FormInterceptor } from './shared-form.interceptor';
import { SharedFormService } from './shared-form.service';

/**
 * The module for the form components, interceptors, and service
 *
 * @example
 * import { SharedFormModule } from '@agingplan';
 */
@NgModule({
  declarations: [],
  exports: [FormComponentsModule],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, FormComponentsModule, SharedPipesModule],
})
export class SharedFormModule {
  static forRoot(environment: Environment): ModuleWithProviders<SharedFormModule> {
    return {
      ngModule: SharedFormModule,
      providers: [SharedFormService, NgbTypeaheadConfig, { provide: HTTP_INTERCEPTORS, useClass: FormInterceptor, multi: true }, { provide: 'environment', useValue: environment }],
    };
  }
}
