<label #labelElement class="form-label" [for]="id" [innerText]="label" *ngIf="label?.length && !grouped" [style.padding-inline]="inline_label_padding_small ? '15px' : '30px'"></label>

<ng-container *ngIf="grouped">
  <div class="input-group-prepend">
    <span class="input-group-text">
      <ng-content></ng-content>
      &nbsp;<span *ngIf="label" [innerText]="label"></span>
    </span>
  </div>
</ng-container>

<div class="d-flex flex-nowrap align-items-end">
  <input
    #inputElement
    class="form-control"
    [type]="type"
    [id]="id"
    [value]="ngControl.value"
    [disabled]="ngControl.disabled"
    [readonly]="readonly"
    [placeholder]="placeholder"
    [required]="required"
    [class.is-invalid]="errors"
    [attr.aria-describedby]="id + '_invalid_feedback'"
    [format_input]="formatter"
    (input)="onChange(inputElement.value)"
    (blur)="onTouched()" />

  <input #hiddendate type="date" (change)="onDateChange($event)" class="hidden-date-input" *ngIf="formatter==='shortDate'"/>
  <form-btn class="btn btn-sm btn-white" *ngIf="formatter==='shortDate'" (click)="showNativeDatePicker()"><i class="bi bi-calendar3"></i></form-btn>
</div>

<div [id]="id + '_invalid_feedback'" class="invalid-feedback">
  <ng-container *ngIf="errors?.required">This is required</ng-container>
  <ng-container *ngIf="errors?.pattern">This value is not valid.</ng-container>
  <ng-container *ngIf="errors?.email">Not a valid email address.</ng-container>
  <ng-container *ngIf="errors?.minlength"> Minimum length not met by {{ errors?.minlength.requiredLength - errors?.minlength.actualLength }} characters. </ng-container>
  <ng-container *ngIf="errors?.maxlength"> Maximum length exceeded by {{ errors?.maxlength.actualLength - errors?.maxlength.requiredLength }} characters. </ng-container>
</div>

<div class="p-0 m-0 help-text" [class.input-group]="grouped" *ngIf="help_text">
  <div class="form-text text-muted" [id]="id + '_help_text'" [innerText]="help_text"></div>
</div>
