import { DatabaseRecord, HasRoles } from '../mixins';
import { User } from '../objects/user';
import { ArticleInterfacing } from './article.interfaces';

/**
 * An article for Long Term Care life insurance for either agingplan.com or myagingplan.com
 */
export class Article extends DatabaseRecord(HasRoles()) {
  /**
   * The article title
   */
  public title = '';

  /**
   * The article group
   */
  public group = '';

  /**
   * A web link friendly slug of the article's title
   */
  public slug = '';

  /**
   * The network scope of article or article section is available on
   * 0 = for public its mean every one can view this perticular section
   * 1 = Thrivent only show for thrivent users
   * 2 = TAN only show for tan users
   * 3 = Independent only show for independent users
   * 4 = Ronald blue only show for ronald blue users
   * 5 = D.A. David Son only show for d.a. david son
   */
  public network_scope: string[] = [];

  /**
   * The order which this is displayed
   */
  public order = 0;

  /**
   * If true, the article will be available on myagingplan.com
   */
  public my_aging_plan = false;

  /**
   * The article heading
   */
  public heading: ArticleHeaderSection = new ArticleHeaderSection();

  /**
   * An array of content sections
   */
  public content: ArticleContentSection<SectionTypes>[] = [];

  /**
   * The article footer
   */
  public footer?: ArticleInterfacing.Content.Footer;

  /**Copyright year */
  public copyright_from?: string;
  public copyright_to?: string;
  /**
   * The domain or path the article is available on
   */
  public availability: ArticleInterfacing.Has.Availability = {
    public: {
      ltc_planning: false,
      in_navigation: false,
    },
    my_aging_plan: {
      ltc_planning: false,
      aging_care: false,
      in_navigation: false,
    },
    advisor_portals: {
      our_process: false,
      in_navigation: false,
    },
  };

  /**
   * The Social Network assets is an array of image URIs
   */
  public social_gallery: string[] = [];

  constructor(data?: Article) {
    super(data);
    if (data) {
      Object.assign(this, data);
      this.heading = new ArticleHeaderSection(data.heading);
      // Check if data.content is an array before mapping
      if (Array.isArray(data.content)) {
        this.content = data.content.map((section) => new ArticleContentSection(section));
      } else {
        console.error('Expected Article data.content to be an array but received:', data.content);
        alert(`Expected Article data.content to be an array but got ${typeof data.content}!`);
        this.content = [];
      }
    }
  }

  /**
   * Checks if the current page is available to the domain or path
   * @param {ArticleInterfacing.SectionAvailability} availability - The availability object
   * @returns {boolean}
   *
   * @example
   * if (this.isAvailableToDomain(availability)) {
   *  // Do something
   * }
   */
  public isAvailableToDomain(availability: ArticleInterfacing.Has.Availability): boolean {
    if (availability.advisor_portals && window.location.hostname.includes('agingplan') && window.location.href.includes('advisor-portal')) return true;
    else if (availability.public && window.location.hostname.includes('agingplan')) return true;
    else if (availability.my_aging_plan && window.location.hostname.includes('myagingplan')) return true;

    return false;
  }

  /**
   * Gets the authenticity token from the local storage
   * @returns {User | null}
   *
   * @example
   * const user = this.getAuthenticityToken();
   * if (user) {
   *  // Do something with the user
   * }
   */
  public getAuthenticityToken(): User | null {
    const schema = localStorage.getItem('agingplan.user');
    if (schema) {
      return new User(JSON.parse(schema));
    }
    return null;
  }
}

/**
 * The type of content section
 */
export enum ArticleContentSectionType {
  FULL_WIDTH_IMAGE = 'full-width-image',
  FULL_WIDTH_LOTTIE = 'full-width-lottie',
  HORIZONTAL_RULE = 'horizontal-rule',
  CTA_HORIZONTAL_RULE = 'cta-horizontal-rule',
  IMAGE_OVERLAY = 'image-overlay',
  ONE_TWO_THREE = '123',
  TEXT_OVERLAY = 'text-overlay',
  SEMI_CIRCLE = 'semi-circle',
  IMAGES_TEXT = 'images-text',
  TWO_COLUMN_IMAGE = 'two-column-image',
  PDFS = 'pdfs',
  VIDEO = 'video',
  VIDEO_LANDING_SECTION = 'video-landing-section',
  EMBED_SECTION = 'embed-section',
  LOTTIE_OVERLAY = 'lottie-overlay',
  HEADER = 'header',
}

/**
 * A section of content for an article
 */
export class ArticleContentSection<T> extends HasRoles() {
  /**
   * A mapped type that maps the properties of T to the properties of ArticleContentSection
   */
  public properties: { [K in keyof T]: T[K] } = {} as T;

  /**
   * The type of the content section
   */
  public type = ArticleContentSectionType.TWO_COLUMN_IMAGE;

  /**
   * The platform the article is available on
   */
  public availability = {
    public: true,
    my_aging_plan: false,
    advisor_portals: false,
  };

  /**
   * The network scope of article or article section is available on
   * 0 = for public its mean every one can view this perticular section
   * 1 = Thrivent only show for thrivent users
   * 2 = TAN only show for tan users
   * 3 = Independent only show for independent users
   * 4 = Ronald blue only show for ronald blue users
   * 5 = D.A. David Son only show for d.a. david son
   */
  public scope = '0';

  constructor(data: ArticleContentSection<T>) {
    super();

    Object.assign(this, data);
  }
}

/** Text Sectiom */
export class ArticleTextSection implements ArticleInterfacing.Styles.Text {
  public class = '';
  public style = '';
  public fontFamily = '';
  public fontSize = '';
  public fontStyle = '';
  public fontWeight = '';
  public textAlign = '';
  public animation = '';
  public innerHTML = '';
  public color?: string;

  constructor(data?: ArticleTextSection) {
    Object.assign(this, data);
  }
}

/** HeaderStyle */
export class ArticleHeaderStyle implements ArticleInterfacing.Sets.Classes, ArticleInterfacing.Sets.Style {
  public class = '';
  public style = '';

  constructor(data?: ArticleHeaderStyle) {
    Object.assign(this, data);
  }
}

/** Image Section */
export class ArticleImageSection implements ArticleInterfacing.Styles.Image {
  public backgroundColor = '';
  public backgroundPositionX = '';
  public backgroundPositionY = '';
  public backgroundSize = '';
  public class = '';
  public height = '';
  public imageSrc = '';
  public src?: string;
  public parallax = false;
  public style = '';

  constructor(data?: ArticleImageSection) {
    Object.assign(this, data);
    if (!data?.src) this.src = '';
    if (Array.isArray(this.height)) this.height = '';
  }
}

/** Lottie Section */
export class ArticleLottieSection implements ArticleInterfacing.Styles.Lottie {
  public file_name = '';
  public config = '{}';
  public lottie_height = '';
  public lottie_width = '';
  public section_height = '';
  public section_width = '';
  public backgroundColor = '';

  constructor(data?: ArticleLottieSection) {
    Object.assign(this, data);
  }
}

/** Article Availability */
export class ArticleAvailability {
  public public!: ArticleInterfacing.Has.Public;
  public my_aging_plan!: ArticleInterfacing.Has.MyAgingPlan;
  public advisor_portals!: ArticleInterfacing.Has.AdvisorPortal;

  constructor(data?: ArticleAvailability) {
    Object.assign(this, data);
    if (!data?.public) this.public = { ltc_planning: false, in_navigation: false };
    if (!data?.my_aging_plan) this.my_aging_plan = { ltc_planning: false, aging_care: false, in_navigation: false };
    if (!data?.advisor_portals) this.advisor_portals = { our_process: false, in_navigation: false };
  }
}

/** Section Avaiability */
export class SectionAvailability implements ArticleInterfacing.Content.Availability {
  public advisor_portals = false;
  public my_aging_plan = false;
  public public = false;

  constructor(data?: SectionAvailability) {
    Object.assign(this, data);
    if (!data?.advisor_portals) this.advisor_portals = false;
    if (!data?.my_aging_plan) this.my_aging_plan = false;
    if (!data?.public) this.public = false;
  }
}

/** Article Header Section */
export class ArticleHeaderSection {
  public header = true;
  public footer = false;
  public first_heading = '';
  public h1 = { innerHTML: '' };
  public h2 = { innerHTML: '' };
  public compliance = '';
  public compliance_text_color = '';
  public inlineCSS = '';
  public desktop = {
    container: { ...new ArticleHeaderStyle() },
    h1: { ...new ArticleTextSection() },
    h2: { ...new ArticleTextSection() },
    background: { ...new ArticleImageSection() },
  };
  public tablet = {
    container: { ...new ArticleHeaderStyle() },
    h1: { ...new ArticleTextSection() },
    h2: { ...new ArticleTextSection() },
    background: { ...new ArticleImageSection() },
  };
  public mobile = {
    container: { ...new ArticleHeaderStyle() },
    h1: { ...new ArticleTextSection() },
    h2: { ...new ArticleTextSection() },
    background: { ...new ArticleImageSection() },
  };
  public animation = '';

  constructor(data?: ArticleHeaderSection) {
    Object.assign(this, data);
    if (!this.inlineCSS) this.inlineCSS = '';
    if (!this.compliance_text_color) this.compliance_text_color = '#000000';
    if (typeof data?.compliance === 'object') this.compliance = '';
    if (!this.desktop?.background?.style) this.desktop!.background!.style = '';
    if (!this.tablet?.background?.style) this.tablet!.background!.style = '';
    if (!this.tablet?.background?.height) this.tablet!.background!.height = '';
    if (!this.mobile?.background?.style) this.mobile!.background!.style = '';
  }
}

/** Article Two Column Image Section */
export class ArticleTwoColumnImage implements ArticleInterfacing.Content.TwoColumnImage {
  public type = ArticleContentSectionType.TWO_COLUMN_IMAGE;
  public side = ArticleInterfacing.Has.Orientation.left;
  public scope = '0';
  public title = { ...new ArticleTextSection() };
  public subtitle = { ...new ArticleTextSection() };
  public body = { ...new ArticleTextSection() };
  public desktop = { ...new ArticleImageSection() };
  public tablet = { ...new ArticleImageSection() };
  public mobile = { ...new ArticleImageSection() };
  public availability = { ...new SectionAvailability() };

  constructor(data?: ArticleContentSection<SectionTypes>) {
    Object.assign(this, data);
    if (!this.tablet.style) this.tablet.style = '';
    if (!this.mobile.style) this.mobile.style = '';
  }
}

/** Article Horizontal Rule Section */
export class ArticleHorizontalRule implements ArticleInterfacing.Content.HorizontalRule {
  public type = ArticleContentSectionType.HORIZONTAL_RULE;
  public innerHTML = '';
  public scope = '0';
  public style = '';
  public container = ArticleInterfacing.Has.ContainerOption.container;
  public backgroundColor = '';
  public desktop = { ...new ArticleTextSection() };
  public tablet = { ...new ArticleTextSection() };
  public mobile = { ...new ArticleTextSection() };
  public availability = { ...new SectionAvailability() };
  public animation = '';
  constructor(data?: ArticleContentSection<SectionTypes>) {
    Object.assign(this, data);
    if (Array.isArray(this.backgroundColor)) this.backgroundColor = '';
  }
}

/** Article CTA Horizontal Rule Section */
export class ArticleCtaHorizontalRule implements ArticleInterfacing.Content.CtaHorizontalRule {
  public type = ArticleContentSectionType.CTA_HORIZONTAL_RULE;
  public innerHTML = '';
  public scope = '0';
  public style = '';
  public container = ArticleInterfacing.Has.ContainerOption.container;
  public backgroundColor = '';
  public button_classes = '';
  public button_label = '';
  public button_link = '';
  public desktop = { ...new ArticleTextSection() };
  public tablet = { ...new ArticleTextSection() };
  public mobile = { ...new ArticleTextSection() };
  public availability = { ...new SectionAvailability() };
  public animation = '';
  constructor(data?: ArticleContentSection<SectionTypes>) {
    Object.assign(this, data);
  }
}

/** Article Full Width Image Section */
export class ArticleFullWidthImage implements ArticleInterfacing.Content.FullWidthImage {
  public type = ArticleContentSectionType.FULL_WIDTH_IMAGE;
  public title = '';
  public scope = '0';
  public desktop = { ...new ArticleImageSection() };
  public tablet = { ...new ArticleImageSection() };
  public mobile = { ...new ArticleImageSection() };
  public availability = { ...new SectionAvailability() };

  constructor(data?: ArticleContentSection<SectionTypes>) {
    Object.assign(this, data);
  }
}

/** Article Full Width Lottie Section */
export class ArticleFullWidthLottie implements ArticleInterfacing.Content.FullWidthLottie {
  public type = ArticleContentSectionType.FULL_WIDTH_LOTTIE;
  public scope = '0';
  public desktop = { ...new ArticleLottieSection() };
  public tablet = { ...new ArticleLottieSection() };
  public mobile = { ...new ArticleLottieSection() };
  public availability = { ...new SectionAvailability() };

  constructor(data?: ArticleContentSection<SectionTypes>) {
    Object.assign(this, data);
  }
}

/** Article One Two Three Section */
export class ArticleOneTwoThree implements ArticleInterfacing.Content.OneTwoThree {
  public type = ArticleContentSectionType.ONE_TWO_THREE;
  public scope = '0';
  public one = {
    body: { ...new ArticleTextSection() },
    title: { ...new ArticleTextSection() },
  };
  public two = {
    body: { ...new ArticleTextSection() },
    title: { ...new ArticleTextSection() },
  };
  public three = {
    body: { ...new ArticleTextSection() },
    title: { ...new ArticleTextSection() },
  };
  public availability = { ...new SectionAvailability() };

  constructor(data?: ArticleContentSection<SectionTypes>) {
    Object.assign(this, data);
  }
}

export class ArticlePdf implements ArticleInterfacing.Content.Pdfs {
  public type = ArticleContentSectionType.PDFS;
  public title = '';
  public description = '';
  public style = ArticleInterfacing.Content.PdfStyle.static;
  public scope = '0';
  public popup_button_text = '';
  public pdfs: ArticleInterfacing.Content.Pdf[] = [];
  public backgroundImage = { ...new ArticleImageSection() };
  public backgroundColor = '';
  public availability = { ...new SectionAvailability() };
  public animation = '';
  public btnBackgroundColor = '#ffffff';
  public btnBorderColor = '#ffffff';
  public btnTextColor = '#000000';
  public titleColor = '#ffffff';

  constructor(data?: ArticleContentSection<SectionTypes>) {
    Object.assign(this, data);
    if (!this.backgroundImage.height) this.backgroundImage.height = '';
  }
}

export class ArticleVideo implements ArticleInterfacing.Content.VideoSection {
  public type = ArticleContentSectionType.VIDEO;
  public side = ArticleInterfacing.Has.Orientation.left;
  public scope = '0';
  public backgroundColor = '';
  public title = {
    desktop: { ...new ArticleTextSection() },
    tablet: { ...new ArticleTextSection() },
    mobile: { ...new ArticleTextSection() },
  };
  public background = {
    desktop: { ...new ArticleImageSection() },
    tablet: { ...new ArticleImageSection() },
    mobile: { ...new ArticleImageSection() },
  };
  public thumbnail = {
    desktop: { ...new ArticleImageSection() },
    tablet: { ...new ArticleImageSection() },
    mobile: { ...new ArticleImageSection() },
  };
  public video = {
    videoSrc: '',
  };
  public innerHTML = '';
  public availability = { ...new SectionAvailability() };

  constructor(data?: ArticleContentSection<SectionTypes>) {
    Object.assign(this, data);
    if (Array.isArray(this.thumbnail.desktop.height)) this.thumbnail.desktop.height = '';
    if (Array.isArray(this.thumbnail.tablet.height)) this.thumbnail.tablet.height = '';
    if (Array.isArray(this.thumbnail.mobile.height)) this.thumbnail.mobile.height = '';
  }
}

export class ArticleVideoLanding implements ArticleInterfacing.Content.VideoLanding {
  public type = ArticleContentSectionType.VIDEO_LANDING_SECTION;
  public scope = '0';
  public title = '';
  public subtitle = '';
  public backgroundColor = '';
  public thumbnail = { ...new ArticleImageSection() };
  public video = '';
  public availability = { ...new SectionAvailability() };

  constructor(data?: ArticleContentSection<SectionTypes>) {
    Object.assign(this, data);
    if (!this.thumbnail.height) this.thumbnail.height = '';
  }
}

export class ArticleEmbed implements ArticleInterfacing.Content.Embed {
  public type = ArticleContentSectionType.EMBED_SECTION;
  public scope = '0';
  public innerHTML = '';
  public embedType = '';
  public embed = '';
  public backgroundColor = '#000000';
  public desktop = { ...new ArticleHeaderStyle() };
  public tablet = { ...new ArticleHeaderStyle() };
  public mobile = { ...new ArticleHeaderStyle() };
  public availability = { ...new SectionAvailability() };

  constructor(data?: ArticleContentSection<SectionTypes>) {
    Object.assign(this, data);
  }
}

export class ArticleTextOverlay implements ArticleInterfacing.Content.TextOverlay {
  public type = ArticleContentSectionType.TEXT_OVERLAY;
  public side = ArticleInterfacing.Has.Orientation.left;
  public scope = '0';
  public number = '';
  public title = { ...new ArticleTextSection() };
  public subtitle = { ...new ArticleTextSection() };
  public body = { ...new ArticleTextSection() };
  public desktop = { ...new ArticleImageSection() };
  public tablet = { ...new ArticleImageSection() };
  public mobile = { ...new ArticleImageSection() };
  public availability = { ...new SectionAvailability() };

  constructor(data?: ArticleContentSection<SectionTypes>) {
    Object.assign(this, data);
    if (!this.subtitle.innerHTML) this.subtitle.innerHTML = '';
    if (!this.desktop.height) this.desktop.height = '';
    if (!this.tablet.height) this.tablet.height = '';
    if (!this.mobile.height) this.mobile.height = '';
  }
}

export class ArticleImageOverlay implements ArticleInterfacing.Content.ImageOverlay {
  public type = ArticleContentSectionType.IMAGE_OVERLAY;
  public side = ArticleInterfacing.Has.Orientation.left;
  public scope = '0';
  public grayBottomHeight = '';
  public title = { ...new ArticleTextSection() };
  public body = { ...new ArticleTextSection() };
  public desktop = { ...new ArticleImageSection() };
  public tablet = { ...new ArticleImageSection() };
  public mobile = { ...new ArticleImageSection() };
  public availability = { ...new SectionAvailability() };

  constructor(data?: ArticleContentSection<SectionTypes>) {
    Object.assign(this, data);
    if (!this.grayBottomHeight) this.grayBottomHeight = '';
    if (!this.desktop.height) this.desktop.height = '';
    if (!this.tablet.height) this.tablet.height = '';
    if (!this.mobile.height) this.mobile.height = '';
  }
}

export class ArticleLottieOverlay implements ArticleInterfacing.Content.LottieOverlay {
  public type = ArticleContentSectionType.LOTTIE_OVERLAY;
  public side = ArticleInterfacing.Has.Orientation.left;
  public scope = '0';
  public grayBottomHeight = '';
  public title = { ...new ArticleTextSection() };
  public body = { ...new ArticleTextSection() };
  public desktop = { ...new ArticleLottieSection() };
  public tablet = { ...new ArticleLottieSection() };
  public mobile = { ...new ArticleLottieSection() };
  public availability = { ...new SectionAvailability() };

  constructor(data?: ArticleContentSection<SectionTypes>) {
    Object.assign(this, data);
    if (!this.grayBottomHeight) this.grayBottomHeight = '';
  }
}

export class ArticleImagesText implements ArticleInterfacing.Content.ImagesAndText {
  public type = ArticleContentSectionType.IMAGES_TEXT;
  public side = ArticleInterfacing.Has.Orientation.left;
  public scope = '0';
  public backgroundColor = '';
  public sectionImage = { ...new ArticleImageSection() };
  public centerImage = { ...new ArticleImageSection() };
  public bottomImage = { ...new ArticleImageSection() };
  public title = { ...new ArticleTextSection() };
  public availability = { ...new SectionAvailability() };

  constructor(data?: ArticleContentSection<SectionTypes>) {
    Object.assign(this, data);
  }
}

export class ArticleSemiCircle implements ArticleInterfacing.Content.SemiCircle {
  public type = ArticleContentSectionType.SEMI_CIRCLE;
  public side = ArticleInterfacing.Has.Orientation.left;
  public scope = '0';
  public title = { ...new ArticleTextSection() };
  public body = { ...new ArticleTextSection() };
  public desktop = { ...new ArticleImageSection() };
  public tablet = { ...new ArticleImageSection() };
  public mobile = { ...new ArticleImageSection() };
  public availability = { ...new SectionAvailability() };
  constructor(data?: ArticleContentSection<SectionTypes>) {
    Object.assign(this, data);
  }
}

/**
 * A union of all section class types
 */
export type SectionTypes =
  | ArticleTwoColumnImage
  | ArticleHorizontalRule
  | ArticleCtaHorizontalRule
  | ArticleFullWidthImage
  | ArticleFullWidthLottie
  | ArticleOneTwoThree
  | ArticlePdf
  | ArticleVideo
  | ArticleVideoLanding
  | ArticleEmbed
  | ArticleTextOverlay
  | ArticleImageOverlay
  | ArticleLottieOverlay
  | ArticleImagesText
  | ArticleSemiCircle;
