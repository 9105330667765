import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, ElementRef, EventEmitter, HostBinding, Inject, Input, OnDestroy, Optional, Output, Self, SimpleChanges, ViewChild } from '@angular/core';
import { NgControl } from '@angular/forms';
import { NgOption } from '@ng-select/ng-select';
import { firstValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Environment } from '../../../objects/environment';
import { FormInputBase } from '../form-input/form-input-base';
import { FormSelectComponent, FormSelectPrefillOptions } from '../form-select/form-select.component';

/**
 * Maximum file size in bytes (5MB)
 */
const MAX_FILE_SIZE = 5 * 1024 * 1024;

/**
 * Component for handling file inputs with preview functionality.
 * Supports various file types and provides integration with form controls.
 */
@Component({
  selector: 'form-input-file',
  templateUrl: './form-input-file.component.html',
  styleUrls: ['./form-input-file.component.scss'],
})
export class FormInputFileComponent extends FormInputBase implements AfterViewInit, OnDestroy {
  @ViewChild('formSelect') formSelect!: FormSelectComponent;

  /**
   * Image Background color for preview
   * */
  @Input() image_background_color: string = '#ffffff';

  /**
   * File type for upload
   */
  @Input() type: FILETYPE = 'image/*';

  /**
   * Preview enable or disable for image
   */
  @Input() preview: boolean = true;

  /**
   * Upload button position(left/right) default left
   * */
  @Input() position: BUTTON_POSITION = 'left';

  /**
   * Upload button text. default Choose File
   */
  @Input() label: string = 'Choose File';

  /**
   * Prefill the dropdown with options. default assets
   */
  @HostBinding() @Input() prefill?: FormSelectPrefillOptions;

  /**
   * The options for the select
   * @default []
   */
  @Input() options: NgOption[] = [];

  /**
   * The options are now ready and can be used
   */
  @Output() onOptionsReady = new EventEmitter<NgOption[]>();

  public show_select_file_thumbnail = false;
  public new_file_size = 0;
  public new_file_value: string | undefined = '';
  public old_file_url = '';
  public old_file_size = 0;
  public select_file_type?: FILETYPE;

  /**
   * Error message for file operations
   */
  public errorMessage: string = '';

  constructor(
    @Self() @Optional() ngControl: NgControl,
    elementRef: ElementRef<HTMLElement>,
    @Inject('environment') private environment: Environment,
    private http: HttpClient,
  ) {
    super(ngControl, elementRef);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (typeof this.new_file_value === 'string' && typeof this.environment.google?.bucket === 'string' && this.new_file_value.includes(this.environment.google.bucket)) {
      const form_select_value = this.new_file_value.replace(`${this.environment.google.bucket}/`, '');
      if (this.options.length > 0) {
        const file_exists = this.options.find((obj) => obj.value === form_select_value);
        if (file_exists) {
          this.formSelect.value = file_exists.value;
        }
      }
    }
  }

  ngAfterViewInit() {
    if (!this.ngControl?.valueChanges) {
      console.error('No value changes found');
      return;
    }

    this.subscribeTo(
      this.ngControl.valueChanges.subscribe((value) => {
        if (typeof this.value === 'string' && value) {
          let form_select_value = this.value.replace(`${this.environment.google.bucket}/`, '');
          if (form_select_value.split('/assets/').length > 1) {
            form_select_value = `assets/${form_select_value.split('/assets/')[1]}`;
          } else {
            form_select_value = `${form_select_value.split('/assets/')[0]}`;
          }
          const header_image = form_select_value.substring(form_select_value.lastIndexOf('/') + 1);
          const selected_option: NgOption = {
            label: header_image,
            value: form_select_value,
          };
          this.formControl?.setValue(selected_option);
          this.formSelect.value = selected_option.value;
          if (selected_option.label) this.formSelect.placeholder = selected_option.label;
          this.new_file_value = value;
          // this.preview = true;
        }else{
          this.value = '';
          this.new_file_value = '';
          this.formControl?.setValue('');
          this.formSelect.value = '';
        }
      }),
    );
  }

  /**
   * Handles file upload event and processes the selected file
   * Validates file size and type before processing
   * @param {Event} event - File input change event
   */
  public handleUploadLogo(event: Event): void {
    this.errorMessage = '';
    const inputElement = event.target as HTMLInputElement;

    if (!inputElement.files?.length) {
      this.errorMessage = 'No file selected';
      return;
    }

    const file: File = inputElement.files[0];

    // Validate file size
    if (file.size > MAX_FILE_SIZE) {
      this.errorMessage = 'File size exceeds 5MB limit';
      return;
    }

    // Validate file type
    if (this.type !== 'image/*' && !file.type.includes(this.type.replace('.', ''))) {
      this.errorMessage = `Invalid file type. Expected ${this.type}`;
      return;
    }

    const reader = new FileReader();

    reader.onerror = () => {
      this.errorMessage = 'Error reading file';
      console.error('FileReader error:', reader.error);
    };

    reader.onload = () => {
      try {
        if (!reader.result) {
          throw new Error('Failed to read file');
        }

        const file_exists = this.options.find((obj) => obj.label === file.name);
        if (file_exists) {
          this.show_select_file_thumbnail = true;
          this.preview = false;
          this.new_file_size = Math.round(file.size / 1024);
          this.old_file_url = `${this.environment.google.bucket}/${file_exists.value}`;
          this.getFileSize(file_exists.value);
        }

        const option: NgOption = {
          value: '',
          label: file.name,
          selected: true,
        };

        this.new_file_value = reader.result.toString();
        this.value = `${file.name}|${this.new_file_value}`;
        option.value = this.value;

        if (this.formSelect) {
          this.formSelect.options = [...this.formSelect.options, option];
          this.formControl?.setValue(option);
          this.formSelect.value = option.value;
          this.formSelect.placeholder = file.name;
          this.onChange(option.value);
        }
      } catch (error) {
        console.error('Error processing file:', error);
        this.errorMessage = 'Error processing file';
      }
    };

    reader.readAsDataURL(file);
  }

  /**
   * Gets the file size from the server
   * @param {string} url - URL of the file
   * @returns Subscription that updates old_file_size
   */
  private getFileSize(url: string) {
    return firstValueFrom(this.http.post<string>(`${environment.api_url}/file-size`, { url }));
  }

  /**
   * On Click on image thumbnail selected image
   * @param url
   */
  public onClickSelectThumbnail(url: string | undefined) {
    if (url) this.value = url.toString();
    this.change.emit(url);
    this.onChange(this.value);
  }

  /**
   * Write the value of the input to the model
   * @param {string} value - The value to be set on the model.
   */
  public writeValue(value: string): void {
    this.value = value;
    this.new_file_value = this.value;
    this.change.emit(`${this.value}`);
    // this.onChange(this.value);
    // Update the value in the FormSelectComponent
    if (this.formSelect) {
      this.formSelect.updateValue(this.value);
    }
  }

  /**
   * Onselect the file from the list change the preview image
   * @param {NgOption} option
   */
  public onChangeImage(option: NgOption) {
    this.show_select_file_thumbnail = false;
    this.preview = true;
    if (option?.value?.includes('data:image')) {
      this.value = option.value;
    } else {
      this.change.emit(`${this.environment.google.bucket}/${option?.value}`);
      this.value = `${this.environment.google.bucket}/${option?.value}`;
    }
    this.onChange(this.value);
    this.new_file_value = this.value.toString();
  }
}

type BUTTON_POSITION = 'left' | 'right';
/**
 * TYPE of FILE we can get from parent component
 */
type FILETYPE = 'image/*' | '.jpg' | '.png' | '.svg' | '.mp4' | '.pdf' | '.json' | '.csv' | '.pptx' | '.zip';
