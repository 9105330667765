import { Directive, ElementRef, HostBinding, Input, Renderer2 } from '@angular/core';

@Directive({
  selector: 'hr[label]',
})
export class HrLabelDirective {
  /**
   * The label to display above the <hr> element
   */
  @HostBinding() @Input('label') label?: string;

  /**
   * The position of the label
   */
  @HostBinding() @Input('position') position?: 'left' | 'right' | 'center' = 'center';

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngOnInit() {
    if (!this.label) return;

    // Get and create the elements
    const hr = this.el.nativeElement;
    const div = this.renderer.createElement('div');
    const span = this.renderer.createElement('span');
    const text = this.renderer.createText(this.label);

    // Style the label element
    this.renderer.setStyle(span, 'position', 'absolute');
    this.renderer.setStyle(span, 'backgroundColor', '#fff');
    this.renderer.setStyle(span, 'padding', '0 10px');
    this.renderer.setStyle(span, 'font-weight', 'bold');
    this.renderer.setStyle(span, 'margin', '0 var(--btn-grid-breakpoint)');
    this.renderer.setStyle(span, 'top', 'calc(-1.3 * var(--font-size-base) / 2)');

    // Style the div element
    this.renderer.setStyle(div, 'position', 'relative');
    this.renderer.setStyle(div, 'text-align', this.position);

    // Insert the div element before the <hr> element
    this.renderer.insertBefore(hr.parentNode, div, hr);

    // Insert the <hr> element and the label element into the div element
    this.renderer.appendChild(div, hr);
    this.renderer.appendChild(div, span);
    this.renderer.appendChild(span, text);
  }
}
