import { SharedDirectivesModule } from '@agingplan/src';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgpImagePickerModule } from '@codebuilt/ngp-image-picker';
import { NgbModalModule, NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { ColorChromeModule } from 'ngx-color/chrome';
import { SharedPipesModule } from '../../pipes/shared-pipes.module';
import { FormArrayComponent } from './form-array/form-array.component';
import { FormBtnComponent } from './form-btn/form-btn.component';
import { FormCheckboxComponent } from './form-checkbox/form-checkbox.component';
import { FormFooterComponent } from './form-footer/form-footer.component';
import { FormHeaderComponent } from './form-header/form-header.component';
import { FormImgComponent } from './form-img/form-img.component';
import { FormInputColorComponent } from './form-input-color/form-input-color.component';
import { FormInputFileComponent } from './form-input-file/form-input-file.component';
import { FormInputComponent } from './form-input/form-input.component';
import { FormRadioComponent } from './form-radio/form-radio.component';
import { FormSectionHeadingComponent } from './form-section-heading/form-section-heading.component';
import { FormSectionComponent } from './form-section/form-section.component';
import { FormSelectComponent } from './form-select/form-select.component';
import { FormSuccessComponent } from './form-success/form-success.component';
import { FormTextareaComponent } from './form-textarea/form-textarea.component';
import { FormToggleComponent } from './form-toggle/form-toggle.component';

@NgModule({
  declarations: [
    FormBtnComponent,
    FormInputComponent,
    FormCheckboxComponent,
    FormRadioComponent,
    FormImgComponent,
    FormSelectComponent,
    FormHeaderComponent,
    FormSectionComponent,
    FormSectionHeadingComponent,
    FormArrayComponent,
    FormSuccessComponent,
    FormFooterComponent,
    FormTextareaComponent,
    FormInputColorComponent,
    FormInputFileComponent,
    FormToggleComponent,
  ],
  exports: [
    FormBtnComponent,
    FormInputComponent,
    FormCheckboxComponent,
    FormRadioComponent,
    FormImgComponent,
    FormSelectComponent,
    FormHeaderComponent,
    FormSectionComponent,
    FormSectionHeadingComponent,
    FormArrayComponent,
    FormSuccessComponent,
    FormFooterComponent,
    FormTextareaComponent,
    FormInputColorComponent,
    FormInputFileComponent,
    FormToggleComponent,
  ],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, NgSelectModule, NgbModalModule, NgpImagePickerModule, NgbTypeaheadModule, SharedPipesModule, ColorChromeModule, SharedDirectivesModule],
})
export class FormComponentsModule {}
