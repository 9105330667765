import { Component, ElementRef, Optional, Self, ViewChild } from '@angular/core';
import { NgControl } from '@angular/forms';
import { FormInputBase } from './form-input-base';

/**
 * An input that can be used within a form
 * Available width: (90, 105, 120, 195, 225, 330, 360, 405, 435, 465, 495, 540, 570, 585, 600, 660, 675, 690, 705, 1170)px;
 * height :45px;
 * to use the different size class="from-control-w-90", class="from-control-w-105"
 * @example
 * <form [formGroup]="form">
 *   <form-input formControlName="input">Input</form-input>
 * </form>
 *
 * @example
 * <form [formGroup]="form">
 * <form-input label="Birthday" formControlName="birthday" [formatter]="'fulldate'"></form-input>
 * </form>
 *
 * @example
 * <form [formGroup]="form">
 * <form-input label="Phone Number" formControlName="input" [formatter]="'phone'"></form-input>
 * </form>
 *
 * @example
 * <form [formGroup]="form">
 * <form-input label="Your Height" formControlName="input" [formatter]="'height'"></form-input>
 * </form>
 */
@Component({
  selector: 'form-input',
  templateUrl: './form-input.component.html',
  styleUrls: ['./form-input.component.scss'],
})
export class FormInputComponent extends FormInputBase {
  @ViewChild('hiddendate') hiddendate!: ElementRef;
  constructor(@Self() @Optional() ngControl: NgControl, elementRef: ElementRef<HTMLElement>) {
    super(ngControl, elementRef);
  }

  /**
   * Capture date change and format it to MM-dd-yyyy
   * @param {Event} event change event for input box
  */
  public onDateChange(event: Event) {
    const input = event.target as HTMLInputElement;
    const selectedDate = input.value;
    if (selectedDate) {
      const dateParts = selectedDate.split('-');
      const formattedDate = `${dateParts[1]}-${dateParts[2]}-${dateParts[0]}`;
      this.ngControl?.control?.setValue(formattedDate);
    }
  }

  /**Trigger native date picker on focus or button click */
  public showNativeDatePicker() {
    const nativeDateInput = this.hiddendate.nativeElement;
    nativeDateInput.showPicker();
  }
}
