<div>
  <div class="headings" [class.col-lg-9]="telephone" [class.col-lg-12]="!telephone">
    <h2 class="title" [innerText]="title"></h2>
    <h1 class="subtitle" [innerText]="subtitle"></h1>
    <p *ngIf="lead" class="lead" [innerText]="lead"></p>
  </div>

  <ng-container *ngIf="telephone">
    <ng-container *ngTemplateOutlet="telephoneTemplate"></ng-container>
  </ng-container>
  <ng-content></ng-content>
</div>

<ng-template #telephoneTemplate>
  <div class="col-lg-3">
    <div class="telephone">
      <a href="tel:18007212188">
        <i class="bi bi-phone"></i>
        <p class="text-center text-danger">
          <strong class="phone"></strong>
        </p>
        <p>Request Assistance to answer health questions by phone</p>
      </a>
    </div>
  </div>
</ng-template>
