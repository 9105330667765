import { AdvisorPortal, AdvisorPortalNetwork, AdvisorPortalTemplate, AgencyBlocGroup, StoreAdvisorPortal } from '@agingplan';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgOption } from '@ng-select/ng-select';
import { BehaviorSubject, Observable, firstValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CommonService {

  public footer_disclosure: Disclosure | null = null;

  constructor(private http: HttpClient) { }

  /**
   * Filter Url before submit
   * @param url
   * @returns
   */
  public filterUrl(url: string) {
    if (url.includes('|')) {
      //If selected from local
      return url;
    } else if (url.includes('?')) {
      //if url containing ?
      url = url.split('?')[0];
    }
    if (url.includes(`${environment.google.bucket}`)) {
      url = url.replace(`${environment.google.bucket}`, '');
    }
    return url;
  }

  /**
   * Get file Content from url
   * @param url
   * @returns
   */
  public getFormsFileContent(url: string, record_id?: number): Observable<Blob> {
    return this.http.post(
      `${environment.api_url}/our-data/attachments`,
      {
        url: url,
        record_id: record_id ??0
      },
      { responseType: 'blob' },
    );
  }
}

export interface Disclosure {
  disclosure: string;
  email: string;
  phone: string;
}
