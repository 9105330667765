import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Role, RolePermissions } from '../mixins/has-roles';

@Injectable({
  providedIn: 'root',
})
export class FeatureAuthorizationService {
  /**
   * Roles assigned to the current user. This should be set by the authentication service.
   */
  public user_roles: Role[] = [];

  constructor() {}

  /**
   * Check if the user can access the feature
   *
   * @param {string} feature - Feature name
   * @returns {boolean}
   */
  public canAccessFeature(feature: string): boolean {
    return this.user_roles.some((role) => role.features.includes(feature));
  }

  /**
   * Check if the user can access the guard
   *
   * @param {string} guard - Guard name
   * @returns {boolean}
   */
  public canAccessGuard(guard: string): boolean {
    return this.user_roles.some((role) => role.guards.includes(guard));
  }

  /**
   * Check if the user can access the HIPAA feature
   *
   * This is a convenience method to check if the user has the HIPPA permission
   *
   * @returns {boolean} - True if the user has access to the HIPAA feature
   */
  public canAccessHipaa(): boolean {
    return this.user_roles.some((role) =>  role.permissions && role.permissions[RolePermissions.HIPPA]===true);
  }
}
