import { FeatureAuthorizationService, Role, RoleName, RolePermissions, User } from '@agingplan';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, catchError, map, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  /**
   * The currently authenticated User.
   */
  public user = new BehaviorSubject<User | undefined>(undefined);

  constructor(
    private router: Router,
    private http: HttpClient,
    private featureAuthorizationService: FeatureAuthorizationService,
  ) {
    // Subscribe to the user observable
    this.user
      .pipe(
        map((user) => {
          // Set the user roles in the feature authorization service
          this.featureAuthorizationService.user_roles = user?.roles ?? [];

          // Pass the user downstream
          return user;
        }),
      )
      .subscribe((user) => {
        // Debugging
        if (!environment.production && user) console.log(user);
      });

    const json = localStorage.getItem('agingplan.user');

    if (json) {
      const user = new User(JSON.parse(json));

      if (user.id === 0) {
        localStorage.removeItem('agingplan.user');
      } else {
        this.user.next(user);
      }
    }
  }

  /**
   * Handle the redirect after a user has logged in.
   * @param {User} user - The user that has logged in.
   * @returns {Promise<boolean>} - A promise that resolves to true if the user was redirected, false otherwise.
   */
  public async handleRedirect(user?: User): Promise<boolean> {
    user = user ?? this.user.value;
    // console.log('handleRedirect', user);
    if (user?.hasPermissionTo(RolePermissions.ADMIN_PORTAL)) {
      return await this.router.navigate(['/admin-portal']);
    } else if (user?.portal_access && user?.portal) {
      return await this.router.navigate(['/advisor-portal']);
    }

    return false;
  }

  /**
   * Get authenticated logged user.
   * @returns {User} Observable<User>- A promise that resolves to true if the user was redirected, false otherwise.
   */
  validateUser(): Observable<User> {
    return this.http.get<AuthenticationResponse>(`${environment.api_url}/me`).pipe(
      map((result: AuthenticationResponse) => {
        const user = new User(result.user);

        user.administrator = this.verifyAdminAccess();
        user.employee = this.verifyEmployeeAccess();
        localStorage.setItem('agingplan.user', JSON.stringify(user));
        this.user.next(user);

        return result.user;
      }),
      catchError((error: any) => {
        return throwError(error); // Pass error downstream
      }),
    );
  }

  /**
   * Verify that the user has admin access.
   * @returns {boolean} - True if the user has access to admin, false otherwise.
   */
  public verifyAdminAccess(): boolean {
    return this.user.value?.hasRole(RoleName.ADMINISTRATOR) || false;
  }

  /**
   * Verify that the user has employee access.
   * @returns {boolean} - True if the user has access to employee, false otherwise.
   */
  public verifyEmployeeAccess(): boolean {
    return this.user.value?.hasRole(RoleName.EMPLOYEE) || false;
  }

  /**
   * Get the top role ID from a list of roles.
   *
   * @param {Role[]} [roles] - An optional array of Role objects.
   * @returns {number} - The ID of the role with the smallest ID, or 1000 if no roles are provided.
   */
  public getTopRoleID(roles?: Role[]): number {
    if (roles && roles.length) {
      return roles.reduce((lowest, role) => (role.id < lowest ? role.id : lowest), roles[0].id);
    }
    return 1000;
  }

  /**
   * Get the top role order from a list of roles.
   *
   * @param {Role[]} [roles] - An optional array of Role objects.
   * @returns {number} - The order of the role with the smallest order, or 1000 if no roles are provided.
   */
  public getRoleOrder(roles?: Role[]): number {
    if (roles && roles.length) {
      return roles.reduce(
        (lowest, role) => (role.order < lowest ? role.order : lowest),
        roles[0].order,
      );
    }
    return 1000;
  }
}

/**
 * The response from the authentication API if there is an error.
 */
interface AuthenticationErrorResponse {
  message: string;
  error: { [key: string]: string };
}

/**
 * The response from the authentication API if the user has successfully logged in.
 */
export interface AuthenticationSuccessResponse {
  user: User;
}

/**
 * The credentials to use for authentication.
 */
export interface AuthenticationCredentials {
  email: string;
  password: string;
  remember_me?: boolean;
}

/**
 * The response from the authentication API.
 */
export type AuthenticationResponse = AuthenticationErrorResponse & AuthenticationSuccessResponse;
