import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateOrString',
})
export class DateOrStringPipe implements PipeTransform {
  constructor(private datePipe: DatePipe) {}

  transform(value?: Date | string | number, format?: string): string | number | null | undefined {
    if (value instanceof Date) {
      return this.datePipe.transform(value, format);
    }

    if (value) {

      try {
        const date = new Date(value);
        if (date instanceof Date && !isNaN(date.valueOf())) {
          return this.datePipe.transform(date, format);
        }
      } catch (e) {
        return value;
      }
    }

    return value;
  }
}
