import { Component, HostBinding, Input } from '@angular/core';

/**
 * A section of a form that can be hidden or shown
 *
 * @example
 * <form-section title="Section Title">
 *  <div>Section Content</div>
 * </form-section>
 */
@Component({
  selector: 'form-section',
  templateUrl: './form-section.component.html',
  styleUrls: ['./form-section.component.scss'],
})
export class FormSectionComponent {
  /**
   * Is the section visible?
   */
  @Input() visible = false;

  /**
   * The Form title
   */
  @HostBinding('attr.title') @Input() title = 'Toggle Section';

  /**
   * The Form subtitle
   */
  @HostBinding('attr.subtitle') @Input() subtitle?: string;

  /**
   * Make the section visible to the user
   */
  public onMakeVisibleClick(): void {
    this.visible = true;
  }
}
