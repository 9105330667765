import { ComponentFactoryResolver, Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { NotAuthorizedComponent } from '@agingplan/src/lib/authorization/components/not-authorized/not-authorized.component';
import { FeatureAuthorizationService } from '../services/feature-authorization.service';

@Directive({
  selector: '[featureAuthorization]',
})
export class FeatureAuthorizationDirective implements OnInit {
  /**
   * Features to check for access.
   *
   * The first element is an array of features to check.
   * The second element is a string that determines how the features are checked. It can be 'some' or 'every'.
   */
  @Input('featureAuthorization') features: [string[], 'some' | 'every', boolean] = [[], 'some', false];

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainerRef: ViewContainerRef,
    private featureAuthorizationService: FeatureAuthorizationService,
    private componentFactoryResolver: ComponentFactoryResolver,
  ) {}

  ngOnInit() {
    const features = this.features[0];
    const strictness = this.features[1];
    const display = this.features[2];

    if (strictness === 'some') {
      if (features.some((feature) => this.featureAuthorizationService.canAccessFeature(feature))) {
        this.viewContainerRef.createEmbeddedView(this.templateRef);
      } else {
        this.viewContainerRef.clear();
        if (display) {
          const factory = this.componentFactoryResolver.resolveComponentFactory(NotAuthorizedComponent);
          this.viewContainerRef.createComponent(factory);
        }
      }
    } else {
      if (features.every((feature) => this.featureAuthorizationService.canAccessFeature(feature))) {
        this.viewContainerRef.createEmbeddedView(this.templateRef);
      } else {
        this.viewContainerRef.clear();
        if (display) {
          const factory = this.componentFactoryResolver.resolveComponentFactory(NotAuthorizedComponent);
          this.viewContainerRef.createComponent(factory);
        }
      }
    }
  }
}
