import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { AfterViewInit, Component, ElementRef, EventEmitter, HostBinding, Inject, Input, OnDestroy, OnInit, Optional, Output, Self, ViewChild } from '@angular/core';
import { NgControl } from '@angular/forms';
import { NgOption, NgSelectComponent } from '@ng-select/ng-select';
import { firstValueFrom, takeUntil } from 'rxjs';
import { Environment } from '../../../objects';
import { ContentService } from '../../../services/content.service';
import { PrefillCacheService } from '../../../services/prefill-cache.service';
import { FormInputBase } from '../form-input/form-input-base';
import { advisor_types } from './types/advisor-types';
import { countries } from './types/countries';
import { heights } from './types/height';
import { scopes } from './types/network-scope';
import { phone_types } from './types/phone-types';
import { states } from './types/states';

/**
 * A form select component that provides single and multi-select functionality with various data source options.
 * Supports typeahead, grouping, and prefilled data from multiple sources.
 */
@Component({
  selector: 'form-select',
  templateUrl: './form-select.component.html',
  styleUrls: ['./form-select.component.scss'],
})
export class FormSelectComponent extends FormInputBase implements OnInit, AfterViewInit, OnDestroy {
  /** Reference to the NgSelect component instance */
  @ViewChild('inputElement') inputElement?: NgSelectComponent;

  /**
   * Enable multiple selection mode
   * @default false
   */
  @Input() multiple = false;

  /** Control the open state of the select dropdown */
  @Input() is_open?: boolean;

  /**
   * Show close button for typeahead results
   * @default false
   */
  @Input() close_button = false;

  /**
   * Show search icon in the component
   * @default false
   */
  @Input() search_button = false;

  /** Emits when a value is selected from the dropdown */
  @Output() onValueSelected = new EventEmitter<NgOption>();

  /** Emits when the filter removal is requested */
  @Output() onRemoveFilterClick = new EventEmitter();

  /**
   * Function or property name for grouping options
   * @see https://github.com/ng-select/ng-select#inputs
   */
  @Input() groupBy: string | ((value: any) => any) | null = null;

  /**
   * Enable search/typeahead functionality
   * @default false
   */
  @Input() searchable = false;

  /**
   * Use dark background for co-brand logo
   * @default false
   */
  @Input() is_prefill_dark_logo = false;

  /**
   * Use PNG format for co-brand logo
   * @default false
   */
  @Input() is_prefill_png_logo = false;

  /** Available options for the select component */
  @Input() options: NgOption[] = [];

  /** Emits when options are loaded and ready */
  @Output() onOptionsReady = new EventEmitter<NgOption[]>();

  /** Prefill options configuration */
  @HostBinding() @Input() prefill?: FormSelectPrefillOptions;

  constructor(
    @Self() @Optional() public ngControl: NgControl,
    @Inject('environment') private environment: Environment,
    elementRef: ElementRef<HTMLElement>,
    private http: HttpClient,
    private contentService: ContentService,
    protected prefillCache: PrefillCacheService,
  ) {
    super(ngControl, elementRef);
  }

  async ngOnInit() {
    try {
      super.ngOnInit();

      if (this.prefill) {
        const placeholder = this.placeholder;
        await this.loadPrefillOptions(placeholder);
      }
    } catch (error) {
      console.error('Error in FormSelect initialization:', error);
      // Reset options to empty array in case of error
      this.options = [];
      this.placeholder = 'Error loading options';
    }
  }

  /**
   * Loads prefill options based on the prefill type
   * @param originalPlaceholder - Original placeholder text to restore after loading
   */
  private async loadPrefillOptions(originalPlaceholder: string): Promise<void> {
    if (!this.prefill) return;

    this.placeholder = 'Loading options...';

    try {
      const fetchOptions = async () => {
        if (this.prefill === 'states') {
          return [...states];
        } else if (this.prefill === 'scopes') {
          return [...scopes];
        } else if (this.prefill === 'height') {
          return [...heights];
        } else if (this.prefill === 'phone_types') {
          return [...phone_types];
        } else if (this.prefill === 'countries') {
          return countries.map((country) => ({ label: country, value: country }));
        } else if (this.prefill === 'advisor_types') {
          return advisor_types.map((type) => ({ label: type, value: type }));
        } else if (this.prefill === 'assets') {
          const assets = await firstValueFrom(this.http.get<string[]>('/api/typeahead/assets'));
          return assets.map((asset) => ({ label: asset, value: asset }));
        } else {
          const prefill = await firstValueFrom(this.http.get<NgOption[]>(`/api/typeahead/${this.prefill}`));
          return this.filterAndMapPrefillOptions(prefill);
        }
      };

      this.options = await this.prefillCache.getOrCreateRequest(this.prefill, fetchOptions);
      this.onOptionsReady.emit(this.options);
    } catch (error) {
      const errorMessage = error instanceof HttpErrorResponse ? error.message : 'Unknown error occurred';
      console.error('Error loading prefill options:', errorMessage);
      this.options = [];
    } finally {
      this.placeholder = originalPlaceholder;
    }
  }

  /**
   * Filters and maps prefill options based on configuration
   */
  private filterAndMapPrefillOptions(prefill: NgOption[]): NgOption[] {
    return prefill
      .filter((asset: any) => {
        if (typeof asset === 'string') {
          if (!this.is_prefill_dark_logo && !this.is_prefill_png_logo) {
            return true;
          }
          const isDarkLogo = this.is_prefill_dark_logo ? asset.toLowerCase().includes('dark') : true;
          const isPngLogo = this.is_prefill_png_logo ? asset.toLowerCase().endsWith('.png') : true;
          return isDarkLogo && isPngLogo;
        }
        return true;
      })
      .map((asset: any) => {
        if (typeof asset === 'string' && asset.includes('/')) {
          const segments = asset.split('/');
          return { label: segments[segments.length - 1], value: asset };
        }
        return asset;
      });
  }

  ngOnDestroy(): void {
    this.options = [];
    super.ngOnDestroy();
  }

  ngAfterViewInit(): void {
    if (!this.inputElement) return;

    if (this.groupBy) {
      this.inputElement.groupBy = this.groupBy;
    }

    if (this.ngControl?.control) {
      this.ngControl.control.markAsPristine();
    }

    this.inputElement.changeEvent.pipe(takeUntil(this.destroy$)).subscribe((selected: NgOption | NgOption[]) => {
      this.handleValueChange(selected);
    });
  }

  /**
   * Handles value changes from the select component
   * @param selected - Selected option(s)
   * @protected
   */
  protected handleValueChange(selected: NgOption | NgOption[]): void {
    let newVal = selected;

    if (this.ngControl?.control) {
      if (Array.isArray(selected)) {
        this.ngControl.control.setValue(selected.map((element) => element.value));
        newVal = selected;
      } else {
        newVal = selected?.value;
        this.ngControl.control.setValue(newVal);
      }
    }

    this.change.emit(newVal);
    this.onValueSelected.emit(newVal);
  }

  compareFn(item: NgOption, selected: NgOption | string) {
    if (typeof selected === 'object') {
      return item.value === selected.value;
    } else {
      return item.value === selected;
    }
  }

  /**
   * Set the value of the control to the option with the matching provided value
   */
  public setValue(value: string | string[]) {
    // The ng-select element
    const element = this.inputElement as NgSelectComponent;

    // Find the options with the values of the control
    let selected: NgOption[] = [];

    // If the control is multiple
    if (this.multiple && value.length) {
      // If the value is an array
      if (Array.isArray(value)) {
        // Find the options with the values of the control
        selected = this.options.filter((option) => value.includes(option.value));
      } else {
        throw new Error(`The provided value '${value}' is not an array!`);
      }
    } else {
      // Find the options with the values of the control
      selected = this.options.filter((option) => option.value === value);
    }

    // If the options exist
    if (selected.length) {
      // Set the values of the ng-select
      element.select(selected[0]);

      // Update the changeDetectorRef
      element.detectChanges();
    }
  }

  /**
   * Called when the user clicks the 'x' to remove the search filter. Emits an event to the parent to remove the filter. Resets the value of the typeahead.
   */
  public onRemoveSearchFilter(): void {
    this.value = '';
    this.onRemoveFilterClick.emit();
  }

  /**
   * Expose a method to update the value
   */
  public updateValue(value: string): void {
    this.value = value;
  }
}

/**
 * If a select is grouped, the options will be an Object consisting of the group name and an array of NgOption[]
 */
export interface FormSelectOptions {
  [key: string]: NgOption[];
}

/**
 * The options available for the prefill option
 */
export type FormSelectPrefillOptions =
  | 'states'
  | 'scopes'
  | 'phone_types'
  | 'countries'
  | 'advisor_types'
  | 'assets'
  | 'social-gallery'
  | 'ltci-specialist-agents'
  | 'agents'
  | 'groups'
  | 'individuals'
  | 'form_submissions'
  | 'policies'
  | 'carriers'
  | 'email-templates'
  | 'email-headers'
  | 'image-buttons'
  | 'co-brand-logos'
  | 'marketing'
  | 'height'
  | 'email-template-mugshots'
  | 'roles'
  | 'articles';
