import { Component, HostBinding, Input, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { ControlContainer, FormArray, FormBuilder, FormControl, FormGroup, FormGroupDirective } from '@angular/forms';

/**
 * Display a form for an array of objects using the parent's FormGroup via the FormGroupDirective.
 *
 * The FormGroup is added to the parent FormGroup as `name`.
 *
 * @example
 * <form [formGroup]="form">
 *  <form-array name="addresses" [form]="formAddress" [formTemplate]="formAddressTemplate"></form-array>
 * </form>
 */
@Component({
  selector: 'form-array',
  templateUrl: './form-array.component.html',
  styleUrls: ['./form-array.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
})
export class FormArrayComponent implements OnInit {
  /**
   * The current page of the section
   */
  public page = 0;

  /**
   * An object that contains the FormControls and their Validators for this section
   */
  public parent?: FormGroup;

  /**
   * All the forms in the section
   */
  public forms?: FormArray;

  /**
   * The property name of the FormArray in the parent FormGroup
   */
  @HostBinding() @Input() name!: string;

  /**
   * The form controls for the given TemplateRef
   */
  @Input() form!: FormGroup;

  /**
   * The template for the form
   */
  @Input() formTemplate!: TemplateRef<any>;

  /**
   * The container holding the visible forms
   */
  @ViewChild('formsContainer') formsContainer!: ViewContainerRef;

  constructor(private formGroupDirective: FormGroupDirective, private formBuilder: FormBuilder) {}

  /**
   * The ngOnInit() function is called after the constructor of the component.
   * Sets the validators and update the value and validity of the control
   */
  ngOnInit() {
    this.parent = this.formGroupDirective.form;

    if (!this.parent.get(this.name)) {
      this.forms = this.parent.get(this.name) as FormArray;
    }

    if (!this.forms) {
      this.forms = this.formBuilder.array([]);
      this.parent.setControl(this.name, this.forms);
    }
  }

  /**
   * Creates a new FormGroup for the FormArray and renders the form
   * @returns FormGroup
   */
  private createForm(): FormGroup {
    const group = new FormGroup({});

    for (const key in this.form.controls) {
      const control = new FormControl(key, this.form.controls[key].validator);
      group.addControl(key, control, { emitEvent: false });
    }

    // Render the form
    this.formsContainer.createEmbeddedView(this.formTemplate, { $implicit: this.forms!.at(this.page) });

    return group;
  }

  /**
   * Adds a new page to the FormArray
   */
  public addMore(): void {
    this.ensureForms();
    this.forms?.push(this.createForm());
    this.page = this.forms!.length - 1;
  }

  /**
   * Removes a page from the FormArray
   */
  public removePage(index: number): void {
    this.ensureForms();
    this.forms?.removeAt(index);
    this.page = this.forms!.length - 1;
  }

  /**
   * Sets the page of the FormArray
   * @param index The index of the page to set the FormArray to
   */
  public setPageTo(index: number): void {
    this.page = index;
  }

  /**
   * If the forms array doesn't exist, create it and add it to the parent form
   */
  private ensureForms(): void {
    if (!this.forms) {
      this.forms = this.formBuilder.array([]);
      this.parent!.setControl(this.name, this.forms);
    }
  }
}
