import { DatabaseRecord } from '../mixins/database-record';

export class Employee extends DatabaseRecord() {
  public name = '';
  public title = '';
  public bio?: string;
  public email = '';
  public linkedin = '';
  public phone = '';
  public large_image_url = '';
  public small_image_url = '';

  constructor(data: Employee) {
    super(data);
    Object.assign(this, data);

    // Parse the bio if it is a string
    if (data?.bio && data.bio.constructor !== Object) this.bio = data.bio;
  }
}
