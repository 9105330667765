import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CloseButtonComponent } from './components/close-button/close-button.component';
import { ContentOutletComponent } from './components/content-outlet/content-outlet.component';
import { IFrameComponent } from './components/iframe/iframe.component';
import { MenuOutletComponent } from './components/menu-outlet/menu-outlet.component';
import { MenuComponent } from './components/menu-outlet/menu/menu.component';
import { IFrameDirective } from './directives/i-frame.directive';
import { SafeDirective } from './directives/safe.directive';

@NgModule({
  declarations: [MenuComponent, IFrameComponent, ContentOutletComponent, CloseButtonComponent, SafeDirective, IFrameDirective, MenuOutletComponent],
  imports: [CommonModule, RouterModule],
  exports: [MenuComponent, IFrameComponent, ContentOutletComponent, MenuOutletComponent],
})
export class PopoverModule {}
