import { ActivityLog } from '@agingplan/src';
import { DatabaseRecord } from '../mixins/database-record';
import { HasRoles } from '../mixins/has-roles';
import { AdvisorPortal } from './advisor-portal';
import { AgencyBlocAgent } from './agency-bloc-agent';
import { AgencyBlocIndividual } from './agency-bloc-individual';

export class User extends DatabaseRecord(HasRoles()) {
  /**
   * The user's first name
   */
  public first_name?: string;

  /**
   * The user's full name
   */
  public full_name?: string;

  /**
   * The user's last name
   */
  public last_name?: string;

  /**
   * The user's phone nummber
   */
  public phone = '';

  /**
   * The user's email address
   */
  public email = '';

  /**
   * The user's Birthday. **This is unrelated to AgencyBloc!**
   */
  public birthday?: Date;

  /**
   * Does this user have access to their assigned AdvisorPortal?
   */
  public portal_access = false;

  /**
   * Associated advisor portal id
   */
  public advisor_portal_id?: number;
  /**
   * The user's assigned AdvisorPortal
   */
  public portal?: AdvisorPortal;

  /**
   * The AgencyBlocEntity this user is associated with.
   */
  public agency_bloc_entity?: AgencyBlocAgent | AgencyBlocIndividual;

  /**
   * Associated agencybloc ID
   */
  public agency_bloc_entity_id?: number;

  /**
   * Is this user an administrator?
   */
  public administrator = false;

  /**
   * Is this user an employee?
   */
  public employee = false;

  /**
   * Is this user a Thrivent Management user
   */
  public is_thrivent_management = false;

  /**
   * The activity logs for this user
   */
  public logs: ActivityLog[] = [];

  constructor(data?: User) {
    super(data);

    if (data) {
      Object.assign(this, data);
    }
  }

  /**
   * Get the name of the user
   */
  public get name(): string {
    let name = '';

    if (this.first_name) {
      name += this.first_name;
    }

    if (this.last_name) {
      if (name.length) {
        name += ' ' + this.last_name;
      } else {
        name += this.last_name;
      }
    }

    if (!name.length) {
      name = '??';
    }

    return name;
  }

  /**
   * Generate initials for a user's name
   */
  public initials(): string {
    let initials = '';

    if (this.first_name) {
      initials += this.first_name[0];
    }

    if (this.last_name) {
      initials += this.last_name[0];
    }

    if (!initials.length) {
      initials = '??';
    }

    return initials;
  }
}
