import { scopes } from '@agingplan';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { FormArray, FormBuilder, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { NgOption } from '@ng-select/ng-select';
import { BehaviorSubject, Subject, firstValueFrom } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Article } from '../article';
import { ArticleGroup, ArticleInterfacing, OldPdf } from '../article/article.interfaces';
import { Role, RoleName } from '../mixins/has-roles';
import { AdvisorPortalNetwork, Carrier, CostOfCareState, EmailTemplate, Employee, LandingPage, TaxImplication } from '../objects';

/**
 * Service responsible for managing advisor portal operations including CRUD operations,
 * navigation, and settings management for social and PDF galleries.
 */
@Injectable({
  providedIn: 'root',
})
export class ContentService implements OnDestroy {
  private destroy$ = new Subject<void>();

  /**
   * All Articles
   */
  public articles$ = new BehaviorSubject<Article[]>([]);

  /**
   * All 'Our Process' Articles
   */
  public our_processes$ = new BehaviorSubject<Article[]>([]);

  /**
   * Exclude Article Groups
   */
  public exclude_article_groups: string[] = ['Other', 'OUR Articles'];

  /**
   * The email templates
   */
  public email_templates$ = new BehaviorSubject<EmailTemplate[]>([]);

  /**
   * The current article being viewed
   */
  public article$ = new BehaviorSubject<Article | undefined>(undefined);

  /**
   * The employee currently being viewed
   */
  public employee$ = new BehaviorSubject<Employee | undefined>(undefined);

  /**
   * All available employees
   */
  public employees$ = new BehaviorSubject<Employee[]>([]);

  /**
   * The tax implication currently being viewed
   */
  public tax_implication$ = new BehaviorSubject<TaxImplication | undefined>(undefined);

  /**
   * All available tax implications
   */
  public tax_implications$ = new BehaviorSubject<TaxImplication[]>([]);

  /**
   * All available carriers
   */
  public carriers$ = new BehaviorSubject<Carrier[]>([]);

  /**
   * All COC Map data
   */
  public map_data$ = new BehaviorSubject<CostOfCareState[]>([]);

  /**
   * The booking page
   */
  public booking$ = new BehaviorSubject<LandingPage | undefined>(undefined);

  /**
   * Article disclose
   */
  public disclosure: string | null = null;

  /**
   * AgencyBloc records
   */
  public agencybloc$ = new BehaviorSubject<{ [key: string]: { at: Date; data: Promise<NgOption[]> } }>({});

  // Promise tracking for async operations
  private fetchEmailTemplatesPromise: Promise<EmailTemplate[]> | null = null;

  constructor(
    private http: HttpClient,
    private router: Router,
    private formBuilder: FormBuilder,
  ) {}

  /**
   * Initializes the content service by fetching all necessary data from the API
   * @returns {Promise<void>} A promise that resolves when all content is loaded
   * @throws {Error} If the API request fails
   */
  public async init(): Promise<void> {
    try {
      const content = await firstValueFrom(
        this.http.get<Content>(`/api/content`).pipe(
          catchError((error: HttpErrorResponse) => {
            console.error('Error fetching content:', error);
            throw new Error(`Failed to fetch content: ${error.message}`);
          }),
        ),
      );

      if (content.articles) {
        this.articles$.next(content.articles.map((item: Article) => new Article(item)));
      }
      if (content.our_processes) {
        this.our_processes$.next(content.our_processes.map((item: Article) => new Article(item)));
      }
      if (content.employees) {
        this.employees$.next(content.employees.map((item: Employee) => new Employee(item)));
      }
      if (content.tax_implications) {
        this.tax_implications$.next(content.tax_implications.map((item: TaxImplication) => new TaxImplication(item)));
      }
      if (content.carriers) {
        this.carriers$.next(content.carriers.map((item: Carrier) => new Carrier(item)));
      }
      if (content.map_data) {
        this.map_data$.next(content.map_data);
      }
      if (content.booking) {
        this.booking$.next(new LandingPage(content.booking));
      }
    } catch (error) {
      console.error('Error in init:', error);
      throw error;
    }
  }

  /**
   * Retrieves the AgencyBloc entities of the specified type and returns them as an array of NgOption objects.
   * Implements caching with a 60-second expiry.
   *
   * @param {('individuals' | 'groups' | 'agents' | 'policies')} entity - The type of AgencyBloc entities to retrieve
   * @returns {Promise<NgOption[]>} A promise that resolves to an array of NgOption objects
   * @throws {Error} If the API request fails
   */
  public async getAgencyBlocEntities(entity: 'individuals' | 'groups' | 'agents' | 'policies'): Promise<NgOption[]> {
    try {
      const target = entity.toString().toLowerCase();
      const cachedData = this.agencybloc$.value[target];

      if (cachedData) {
        const secondsSinceLastSync = (new Date().getTime() - cachedData.at.getTime()) / 1000;
        if (secondsSinceLastSync < 60) {
          return cachedData.data;
        }
      }

      this.agencybloc$.next({
        ...this.agencybloc$.value,
        [target]: { at: new Date(), data: Promise.resolve([]) },
      });

      const records = await firstValueFrom(
        this.http.get<NgOption[]>(`/api/typeahead/${target}`).pipe(
          catchError((error: HttpErrorResponse) => {
            console.error(`Error fetching ${target}:`, error);
            throw new Error(`Failed to fetch ${target}: ${error.message}`);
          }),
        ),
      );

      this.agencybloc$.next({
        ...this.agencybloc$.value,
        [target]: { at: new Date(), data: Promise.resolve(records) },
      });

      return records;
    } catch (error) {
      console.error('Error in getAgencyBlocEntities:', error);
      throw error;
    }
  }

  /**
   * Get an article by id
   * @param {number} id
   * @returns {Article | undefined}
   *
   * @example
   * const article = this.pagesService.getArticle(1);
   */
  public getArticle(id: number): Article | undefined {
    this.article$.next(undefined);
    // Get the article from the articles$ observable
    let article = this.articles$.value.find((article) => article.id === id);

    // If not there, check the our_processes$ observable
    if (!article) {
      article = this.our_processes$.value.find((article) => article.id === id);
    }

    if (article) {
      // Check if the article is available to the current domain
      const available = article.isAvailableToDomain(article.availability);

      // Get the current user, if there is one
      const user = article.getAuthenticityToken();

      // Assume the user does not have the role
      let hasRole = false;

      if (available) {
        if (article.hasRole(RoleName.DEFAULT)) {
          hasRole = true;
        } else {
          // Check if the article.roles has a role that matches the user.roles
          if (article.roles && user) {
            hasRole = article.roles.some((role: Role) => {
              return user.roles.includes(role);
            });
          }
        }

        if (hasRole) {
          // filter article content section using the user's roles
          article.content = article.content.filter((section) => {
            // Assume the user does not have the role
            let hasRole = false;

            if (section.hasRole(RoleName.DEFAULT)) {
              hasRole = true;
            } else {
              // Check if the section.roles has a role that matches the user.roles
              if (section.roles && user) {
                hasRole = section.roles.some((role: Role) => {
                  return user.roles.includes(role);
                });
              }
            }

            return hasRole;
          });
        }

        /** Filter article content section by scope and default only when user is login and user view the article from advisor portal*/
        if (user?.portal && window.location.href.includes('advisor-portal')) {
          article.content = article.content.filter((section) => {
            return section.scope === user.portal!.id.toString() || section.scope === '0';
          });
        } else if (this.disclosure) {
          const index = scopes.findIndex((scope) => {
            return this.disclosure?.toLowerCase() === scope.label?.toLowerCase();
          });
          article.content = article.content.filter((section) => {
            return section.scope === index.toString() || section.scope === '0';
          });
        } else {
          article.content = article.content.filter((section) => {
            return section.scope === '0';
          });
        }
      }

      /* map old schema to new schema */
      article = this.convertOldSchemaToNew(article);

      this.article$.next(article);
    }

    return article;
  }

  /**
   *
   * @returns {AdvisorPortalNetwork}
   */
  public getArticleDisclosure() {
    return firstValueFrom(this.http.get<AdvisorPortalNetwork>(`/api/advisor-networks/code/${this.disclosure}`));
  }

  /* convert old schema to new schema */
  private convertOldSchemaToNew(article: any) {
    // declare properties name which we don't want to migrate in properties object
    const properties_names: string[] = ['availability', 'roles', 'properties', 'type', 'scope'];
    article?.content.forEach((section: any) => {
      // migrate for two column image or full-width-image
      if (section.type === 'two-column-image' || section.type === 'full-width-image' || section.type === 'text-overlay' || section.type === 'image-overlay') {
        for (const key in section) {
          if (!properties_names.includes(key)) {
            if (key === ArticleInterfacing.MediaQuery.Types.desktop || key === ArticleInterfacing.MediaQuery.Types.tablet || key === ArticleInterfacing.MediaQuery.Types.mobile) {
              section.properties[key] = {};
              section.properties[key]['background'] = section[key];
            } else {
              section.properties[key] = section[key];
            }
            delete section[key];
          }
        }
      } else if (section.type === 'pdfs') {
        for (const key in section) {
          if (!properties_names.includes(key)) {
            if (key == 'pdfs') {
              section.properties[key] = section[key];
              section.properties[key].forEach((element: OldPdf, index: number) => {
                element.src = `pdfs/${element.src}`;
                const btn = {
                  backgroundColor: element.btnBackgroundColor,
                  borderColor: element.btnBorderColor,
                  color: element.btnTextColor,
                  innerText: element.btnLabelHTML.toString(),
                };
                delete section.properties[key][index]['btnBackgroundColor'];
                delete section.properties[key][index]['btnBorderColor'];
                delete section.properties[key][index]['btnTextColor'];
                delete section.properties[key][index]['btnLabelHTML'];
                element['button'] = btn;
              });
            } else if (key == 'backgroundImage') {
              section.properties[key] = section[key].imageSrc;
            } else {
              section.properties[key] = section[key];
            }
            delete section[key];
          }
        }
      } else if (section.type === '123') {
        for (const key in section) {
          if (!properties_names.includes(key)) {
            section.properties[key] = section[key];
            delete section[key];
          }
        }
      } else if (section.type === 'horizontal-rule' || section.type === 'cta-horizontal-rule') {
        for (const key in section) {
          if (!properties_names.includes(key)) {
            if (key === ArticleInterfacing.MediaQuery.Types.desktop || key === ArticleInterfacing.MediaQuery.Types.tablet || key === ArticleInterfacing.MediaQuery.Types.mobile) {
              section.properties[key] = {};
              section.properties[key]['title'] = section[key];
            } else {
              section.properties[key] = section[key];
            }
            delete section[key];
          }
        }
      } else if (section.type === 'video-landing-section') {
        for (const key in section) {
          if (!properties_names.includes(key)) {
            if (key === 'thumbnail') {
              section.properties['thumbnail'] = section[key].imageSrc;
            } else if (key === 'video') {
              section.properties['video'] = section[key].videoSrc;
            } else {
              section.properties[key] = section[key];
            }
            delete section[key];
          }
        }
      } else if (section.type === 'video') {
        for (const key in section) {
          if (!properties_names.includes(key)) {
            if (key === 'background' || key === 'thumbnail' || key === 'title') {
              const prop = section[key];
              for (const pkey in prop) {
                if (pkey === ArticleInterfacing.MediaQuery.Types.desktop || pkey === ArticleInterfacing.MediaQuery.Types.tablet || pkey === ArticleInterfacing.MediaQuery.Types.mobile) {
                  if (section.properties[pkey] == undefined) {
                    section.properties[pkey] = {};
                  }
                  section.properties[pkey][key] = prop[pkey];
                }
              }
            } else if (key === 'video') {
              section.properties['video'] = {};
              section.properties['video'].src = section[key].videoSrc;
            } else {
              section.properties[key] = section[key];
            }
            delete section[key];
          }
        }
      } else if (section.type === 'embed-section') {
        for (const key in section) {
          if (!properties_names.includes(key)) {
            if (key === 'background') {
              const background = section[key];
              for (const bkey in background) {
                if (bkey === ArticleInterfacing.MediaQuery.Types.desktop || bkey === ArticleInterfacing.MediaQuery.Types.tablet || bkey === ArticleInterfacing.MediaQuery.Types.mobile) {
                  section.properties[bkey] = {};
                  section.properties[bkey]['background'] = background[bkey];
                }
              }
            } else {
              section.properties[key] = section[key];
            }
            delete section[key];
          }
        }
      } else if (section.type === 'images-text') {
        for (const key in section) {
          if (!properties_names.includes(key)) {
            if (key === 'sectionImageSide') {
              section.properties['side'] = section[key];
            } else {
              section.properties[key] = section[key];
            }
            delete section[key];
          }
        }
      }
    });
    return article;
  }

  /**
   * Taking a path and an index, navigates to the page at that index
   * @param {string} path - The path to the page.
   * @param {number} index - The index of the page you want to navigate to.
   */
  public navigateToPageIndex(path: string, index: number): void {
    if (path === 'about') {
      if (index >= this.employees$.value.length) {
        this.employee$.next(this.employees$.value[0]);
        // if index greater then employees length reset index to 0;
        index = 0;
      } else if (index === -1) {
        this.employee$.next(this.employees$.value[this.employees$.value.length + 1]);
      } else {
        this.employee$.next(this.employees$.value[index]);
      }
      this.router.navigate(['/about/employee', index], { replaceUrl: true });
    } else {
      if (index > this.tax_implications$.value.length) {
        this.tax_implication$.next(this.tax_implications$.value[0]);
        // if index greater then tax_implications length reset index to 0;
        index = 0;
      } else if (index === -1) {
        this.tax_implication$.next(this.tax_implications$.value[this.tax_implications$.value.length + 1]);
      } else {
        this.tax_implication$.next(this.tax_implications$.value[index]);
      }
      this.router.navigate(['/tax-implication', index], { replaceUrl: true });
    }
  }

  /**
   * Exclude some articles groups from articles list
   * @param {Article[]} articles - The path to the page.
   * @return {Article[]}
   */
  public excludeArticleGroup(articles: Article[]): Article[] {
    return articles.filter((article) => !this.exclude_article_groups.includes(article.group));
  }

  /**
   * Retrieves and updates email templates in the service
   * Uses Promise deduplication to prevent race conditions
   * @returns {Promise<EmailTemplate[]>} A promise that resolves to an array of email templates
   * @throws {Error} If the API request fails
   */
  public async getEmailTemplates(): Promise<EmailTemplate[]> {
    try {
      // If there's already a fetch in progress, return that promise
      if (this.fetchEmailTemplatesPromise) {
        return this.fetchEmailTemplatesPromise;
      }

      // Start new fetch and store the promise
      this.fetchEmailTemplatesPromise = (async () => {
        try {
          const data = await firstValueFrom(
            this.http.get<EmailTemplate[]>(`${environment.api_url}/email/templates/all`).pipe(
              catchError((error: HttpErrorResponse) => {
                console.error('Error fetching email templates:', error);
                throw new Error(`Failed to fetch email templates: ${error.message}`);
              }),
            ),
          );

          const email_templates = data.map((item: EmailTemplate) => new EmailTemplate(item));
          this.email_templates$.next(email_templates);
          return email_templates;
        } finally {
          // Clear the promise when done, regardless of success/failure
          this.fetchEmailTemplatesPromise = null;
        }
      })();

      return this.fetchEmailTemplatesPromise;
    } catch (error) {
      console.error('Error in getEmailTemplates:', error);
      this.fetchEmailTemplatesPromise = null;
      throw error;
    }
  }

  /**
   * Sets up article checkboxes in a form array and organizes them by groups
   * @param {Article[]} articles_data - Array of articles to process
   * @param {FormArray} articles_array - Form array to populate with checkboxes
   * @param {number[]} selected_ids - Array of pre-selected article IDs
   * @returns {ArticleGroup[]} Array of organized article groups
   */
  public setArticlesCheckbox(articles_data: Article[], articles_array: FormArray, selected_ids: number[] = []): ArticleGroup[] {
    if (!articles_data || !articles_array) {
      throw new Error('Invalid input parameters for setArticlesCheckbox');
    }

    articles_array.clear();
    const excluded_articles = this.excludeArticleGroup(articles_data);
    const article_groups: ArticleGroup[] = [];

    excluded_articles.forEach((article) => {
      if (!article_groups.some((group) => group.group === article.group)) {
        article_groups.push({
          group: article.group,
          title: `${article.group}  (${article.group === 'Long-Term Care' ? 'LTC PLANNING' : 'AGING CARE'})`,
        });
      }

      articles_array.push(
        this.formBuilder.group({
          title: new FormControl(article.title),
          group: new FormControl(article.group),
          value: new FormControl(selected_ids.includes(article.id)),
          id: new FormControl(article.id),
        }),
      );
    });

    return article_groups;
  }

  /**
   * Cleanup method to prevent memory leaks
   */
  public ngOnDestroy(): void {
    // Complete all BehaviorSubjects
    this.articles$.complete();
    this.our_processes$.complete();
    this.email_templates$.complete();
    this.article$.complete();
    this.employee$.complete();
    this.employees$.complete();
    this.tax_implication$.complete();
    this.tax_implications$.complete();
    this.carriers$.complete();
    this.map_data$.complete();
    this.booking$.complete();
    this.agencybloc$.complete();

    // Trigger destroy subject
    this.destroy$.next();
    this.destroy$.complete();
  }
}

/**
 * The public and user role specific content.
 */
interface Content {
  /**
   * The articles that are displayed on the main navigation.
   */
  articles: Article[];

  /**
   * The booking page that is displayed on the main navigation.
   */
  booking: LandingPage;

  /**
   * The carriers that are displayed on the carriers page.
   */
  carriers: Carrier[];

  /**
   * The employees that are displayed on the about page.
   */
  employees: Employee[];

  /**
   * The tax implications that are displayed in the main navigation.
   */
  tax_implications: TaxImplication[];

  /**
   * The articles that are displayed in the advisor portal.
   */
  our_processes: Article[];

  /**
   * The map data that is displayed on the cost of care page.
   */
  map_data: CostOfCareState[];
}
