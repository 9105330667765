/**
 * Advisor Types
 */
export const advisor_types = [
  'Active Advisor Full (Agreement)',
  'Active Advisor LP (Agreement)',
  'Active Advisor JFW (Agreement)',
  'Active Advisor Full (No Agreement)',
  'Active Associate Advisor Full (Agreement)',
  'LTCI Specialist (Wolf)',
  'LTC Specialist (Non-Wolf)',
  'Admin (Wolf EE)',
];

/**
 * The type of Advisors
 */
export enum AdvisoryType {
  ADMIN = 'Admin (Wolf EE)',
  ADVISOR = 'ADVISOR',
  GROUP = 'GROUP',
  LTCI_SPECIALIST = 'LTCI Specialist (Wolf)',
  LTC_SPECIALIST = 'LTC Specialist (Non-Wolf)',
  ACTIVE_ADVISOR_FULL_AGREEMENT = 'Active Advisor Full (Agreement)',
  ACTIVE_ADVISOR_LP_AGREEMENT = 'Active Advisor LP (Agreement)',
  ACTIVE_ADVISOR_JFW_AGREEMENT = 'Active Advisor JFW (Agreement)',
  ACTIVE_ADVISOR_FULL_NO_AGREEMENT = 'Active Advisor Full (No Agreement)',
  ACTIVE_ASSOCIATE_ADVISOR_FULL_AGREEMENT = 'Active Associate Advisor Full (Agreement)',
}

/**
 * Advisor Type Union
 */
type AdvisorType = AdvisoryType;

/**
 * Advisor Type Key Union
 */
type AdvisorTypeKey = keyof typeof AdvisoryType;

/**
 * Advisor Types
 */
export type AdvisorTypes = {
  [key in AdvisorTypeKey]: AdvisorType;
};
