<label #labelElementElement class="form-label" [for]="id" [innerText]="label" *ngIf="label && label.length && !grouped" [style.padding-inline]="inline_label_padding_small ? '15px' : '30px'"></label>

<div [class.input-group]="label && label.length && grouped">
  <ng-container *ngIf="label && label.length && grouped">
    <div class="input-group-prepend">
      <span class="input-group-text" [innerText]="label"></span>
    </div>
  </ng-container>

  <ng-select
    #inputElement
    class="form-control"
    bindLabel="label"
    bindValue="value"
    [id]="id"
    [searchable]="searchable"
    [multiple]="multiple"
    [(ngModel)]="value"
    [placeholder]="placeholder || ''"
    [class.is-invalid]="errors"
    [class.searchable]="search_button"
    [items]="options"
    [compareWith]="compareFn"
    [attr.aria-describedby]="id + '_invalid_feedback'"
    [isOpen]="is_open!"
    (blur)="onTouched()">
  </ng-select>

  <ng-container *ngIf="search_button">
    <div class="input-group-append">
      <button class="btn" type="button">
        <i class="bi bi-search"></i>
      </button>
    </div>
  </ng-container>

  <ng-container *ngIf="close_button">
    <div class="input-group-append" (click)="onRemoveSearchFilter()">
      <button class="btn" type="button">
        <i class="bi bi-x"></i>
      </button>
    </div>
  </ng-container>

  <div [id]="id + '_invalid_feedback'" class="invalid-feedback">
    <ng-container *ngIf="errors?.required">This is required</ng-container>
  </div>
</div>

<div class="p-0 m-0 help-text" [class.input-group]="grouped" *ngIf="help_text">
  <div class="form-text text-muted" [id]="id + '_help_text'" [innerText]="help_text"></div>
</div>
