import { AfterViewInit, Component, ElementRef, EventEmitter, forwardRef, HostBinding, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

/**
 * FormCheckboxComponent provides a customizable checkbox/radio input that implements ControlValueAccessor
 * for seamless integration with Angular's form controls.
 *
 * @implements {ControlValueAccessor}
 * @implements {OnInit}
 * @implements {AfterViewInit}
 * @implements {OnDestroy}
 *
 * @example
 * <form [formGroup]="form">
 *   <form-checkbox formControlName="checkbox" required="true">Checkbox Label</form-checkbox>
 * </form>
 */
@Component({
  selector: 'form-checkbox',
  templateUrl: './form-checkbox.component.html',
  styleUrls: ['./form-checkbox.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormCheckboxComponent),
      multi: true,
    },
  ],
})
export class FormCheckboxComponent implements ControlValueAccessor, OnInit, AfterViewInit, OnDestroy {
  /**
   * Reference to the native input element
   * @private
   */
  @ViewChild('inputElement', { static: false })
  private inputElement?: ElementRef<HTMLInputElement>;

  /**
   * Determines if the input is required
   * @default false
   */
  @HostBinding('attr.required')
  @Input()
  required = false;

  /**
   * The type of input element to render
   * @default 'checkbox'
   */
  @HostBinding('attr.type')
  @Input()
  type: 'checkbox' | 'radio' = 'checkbox';

  /**
   * Unique identifier for the input element
   * @default Generated random string
   */
  @HostBinding('attr.id')
  @Input()
  id = `checkbox-${Math.random().toString(36).substring(2, 9)}`;

  /**
   * Name attribute for the input element
   * @default Generated random string
   */
  @HostBinding('name')
  @Input()
  name = `checkbox-${Math.random().toString(36).substring(2, 9)}`;

  /**
   * The value attribute of the input element
   */
  @HostBinding('value')
  @Input()
  value: unknown = '';

  /**
   * Whether to display the checkbox inline
   * @default false
   */
  @Input()
  inline = false;

  /**
   * The checked state of the input
   * @default false
   */
  @Input()
  checked = false;

  /**
   * Event emitter for value changes
   */
  @Output()
  onValueSelected = new EventEmitter<boolean>();

  /**
   * Whether the input is disabled
   * @default false
   */
  @HostBinding('attr.disabled')
  @Input()
  disabled = false;

  constructor(private elementRef: ElementRef<HTMLElement>) {}

  /**
   * Initializes the component and sets up necessary CSS classes
   */
  ngOnInit(): void {
    try {
      const element = this.elementRef.nativeElement;
      element.classList.add('custom-control');
      element.classList.add(`custom-${this.type}`);

      if (this.inline) {
        element.classList.add('custom-control-inline');
      }
    } catch (error) {
      console.error('Error initializing form-checkbox component:', error);
    }
  }

  /**
   * Sets up ARIA attributes and initial checked state after view initialization
   */
  ngAfterViewInit(): void {
    try {
      if (!this.elementRef.nativeElement) {
        throw new Error('Element reference is not available');
      }

      this.elementRef.nativeElement.setAttribute('aria-describedby', `${this.id}_invalid_feedback`);

      if (this.checked && this.inputElement?.nativeElement) {
        this.inputElement.nativeElement.setAttribute('checked', 'checked');
      }

    } catch (error) {
      console.error('Error in form-checkbox afterViewInit:', error);
    }
  }

  /**
   * Cleanup resources when component is destroyed
   */
  ngOnDestroy(): void {
    this.onValueSelected.complete();
  }

  /**
   * Updates ARIA attributes based on component state
   * @private
   */
  private updateAriaAttributes(): void {
    const element = this.elementRef.nativeElement;
    element.setAttribute('aria-checked', String(this.checked));
    element.setAttribute('aria-disabled', String(this.disabled));
  }

  /**
   * ControlValueAccessor implementation for onChange
   * @param {unknown} _value - The new value
   */
  public onChange(_value: unknown): void {}

  /**
   * ControlValueAccessor implementation for onTouched
   */
  public onTouched(): void {}

  /**
   * ControlValueAccessor implementation for writing value
   * @param {unknown} value - The value to write
   */
  public writeValue(value: unknown): void {
    try {
      this.checked = Boolean(value);
      this.value = value;

      if (this.inputElement?.nativeElement) {
        this.inputElement.nativeElement.checked = this.checked;
      }

      this.updateAriaAttributes();
    } catch (error) {
      console.error('Error writing value to form-checkbox:', error);
    }
  }

  /**
   * Registers the onChange callback
   * @param {Function} fn - The callback function
   */
  public registerOnChange(fn: (_: unknown) => void): void {
    this.onChange = fn;
  }

  /**
   * Registers the onTouched callback
   * @param {Function} fn - The callback function
   */
  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  /**
   * Handles input change events
   * @param {HTMLInputElement} element - The input element that triggered the change
   */
  public onChanged(element: HTMLInputElement): void {
    try {
      if (!this.disabled) {
        this.checked = element.checked;

        if (this.inputElement?.nativeElement) {
          if (this.checked) {
            this.inputElement.nativeElement.setAttribute('checked', 'checked');
          } else {
            this.inputElement.nativeElement.removeAttribute('checked');
          }
        }

        this.updateAriaAttributes();
        this.onValueSelected.emit(this.checked);
        this.onChange(this.checked);
        this.onTouched();
      }
    } catch (error) {
      console.error('Error handling checkbox change:', error);
    }
  }
}
