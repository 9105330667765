import { Constructor } from './constructor';

/**
 * All classes should extend from this base class if it is loading data from the Database
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export function DatabaseRecord<T extends Constructor<{}>>(Base: T = class {} as any) {
  return class extends Base {
    /**
     * A unique ID
     */
    public id = 0;

    /**
     * The timestamp for the creation date.
     */
    public created_at = new Date();

    /**
     * The last timestamp the model was updated.
     */
    public updated_at?: Date;

    /**
     * We use soft deletes so the information is never truly 'lost'.
     */
    public deleted_at?: Date;

    /**
     * Whether or not the record is checked. Used in the UI only.
     */
    public checked = false;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    constructor(...args: any[]) {
      super();
      Object.assign(this, args[0]);
    }
  };
}
