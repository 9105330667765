import { ArticleContentSectionType } from './article';
/**
 * All Article Interfaces that are used to construct the Article model data.
 */
export namespace ArticleInterfacing {

  /**
   * Anything inheriting `Sets` should offer a relevant property
   */
  export namespace Sets {
    /**
     * Setting the height the element
     */
    export interface Height {
      height?: string;
    }

    /**
     * Any additional classes that should be applied HTMLElement's `class` attribute
     */
    export interface Classes {
      class?: string;
    }

    /**
     * Any inline styles that are placed on the HTMLElement's `style` attribute
     */
    export interface Style {
      style?: string;
    }
  }

  /**
   * Properties that are set on the parent element
   */
  export namespace Has {
    /**
     * Availability
     */
    export interface Availability {
      public: Public;
      my_aging_plan: MyAgingPlan;
      advisor_portals: AdvisorPortal;
    }

    /**
     * Base Interface
     */
    export interface BaseAvailability {
      in_navigation: boolean;
    }

    /**
     * Public
     */
    export interface Public extends BaseAvailability {
      ltc_planning: boolean;
    }

    /**
     * MyAgingPlan
     */
    export interface MyAgingPlan extends Public {
      aging_care: boolean;
    }

    /**
     * AdvisorPortal
     */
    export interface AdvisorPortal extends BaseAvailability {
      our_process: boolean;
    }

    /**
     * The button of the section
     */
    export interface Button {
      button?: Styles.Button;
    }

    /**
     * An (AOS) animation type
     */
    export interface Animation {
      animation: string;
    }

    /**
     * They styling for the `h1` and `h2` HTMLHeadings
     */
    export interface Headings {
      h1?: Styles.Text;
      h2?: Styles.Text;
    }

    /**
     * The platform types we support
     */
    export enum Orientation {
      left = 'left',
      right = 'right',
    }

    /**
     * The side of the image that the content will be displayed
     */
    export interface XOrientation {
      side: Orientation;
    }

    /**
     * The Container option enum
     */
    export enum ContainerOption {
      ['container'] = 'container',
      ['container-fluid'] = 'container-fluid',
      ['no-container'] = 'no-container',
    }

    /**
     * The type of Bootstrapped container the section content will reside in
     */
    export interface Container {
      container: ContainerOption;
    }

    /**
     * The domain or path the article or Content is available on
     */
    export interface Background {
      background: Styles.Background;
    }

    /** Section Default Properties */
    export interface SectionProp {
      type: ArticleContentSectionType;
      availability: Content.Availability;
      scope: string;
    }
  }

  /**
   * When styling any kind of HTMLElement visible to the User
   */
  export namespace Styles {
    /**
     * a button
     */
    export interface Button extends Background, Text, Sets.Classes, Sets.Height, Sets.Style {
      /**
       * The label of the button
       */
      label?: string;

      /**
       * The link of the button
       */
      link?: string;
    }

    /**
     * text
     */
    export interface Text extends Sets.Classes, Sets.Height, Sets.Style {
      fontFamily?: string;
      fontSize?: string;
      fontStyle?: string;
      fontWeight?: string;
      innerHTML?: string;
      innerText?: string;
      textAlign?: string;
      color?: string;
    }

    /**
     * backgrounds
     */
    export interface Background extends Sets.Classes, Sets.Height, Sets.Style {
      backgroundColor?: string;
      backgroundPositionX?: string;
      backgroundPositionY?: string;
      backgroundSize?: string;
      parallax?: boolean | string;
      borderColor?: string;
      backgroundImage?: string;
    }

    /**
     * images
     */
    export interface Image extends Background, Sets.Classes, Sets.Height, Sets.Style {
      src?: string;
      imageSrc?: string;
    }

    /**
     * videos
     */
    export interface Video extends Background, Sets.Classes, Sets.Height, Sets.Style {
      src?: string;

      /**
       * The video thumbnail
       */
      thumbnail?: MediaQuery.Platforms;
    }

    /**
     * Lottie
     */
    export interface Lottie {
      file_name?: string;
      config?: string;
      lottie_height?: string;
      lottie_width?: string;
      section_height?: string;
      section_width?: string;
      backgroundColor?: string;
    }

    /**
     * Available Styles as a type
     */
    export type Types = Image & Video & Button & Background & Text & Lottie;

    /**
     * Available HTMLElement types that we offer styling to
     */
    export type ElementTypes = (HTMLDivElement & HTMLHeadingElement) | HTMLImageElement | HTMLButtonElement | HTMLVideoElement | HTMLAnchorElement;
  }

  /**
   * Usually Thrivent specific.
   */
  export namespace Compliance {
    /**
     * If the content section has a compliance number.
     */
    export interface MayHave {
      compliance?: string;
    }
  }

  /**
   * Platform specific configuration
   */
  export namespace MediaQuery {
    /**
     * Platform specific styling for the container
     */
    interface Container extends Sets.Classes, Sets.Style { }

    /**
     * The styling for a given platform
     */
    export interface Platform extends Has.Headings, Has.Button, Styles.Text, Styles.Background, Styles.Lottie {
      container?: Container;
      background?: Styles.Background;
      title?: Styles.Text;
      thumbnail?: Styles.Background;
    }

    /**
     * The available platform keys for a media query
     */
    export type PlatformKeys = keyof typeof Types;

    /**
     * The platform types we support
     */
    export enum Types {
      desktop = 'desktop',
      tablet = 'tablet',
      mobile = 'mobile',
    }

    /**
     * When an HTMLElement requires platform specific styling, it should inherit this interface
     */
    export interface Platforms {
      /**
       * Desktop specific styling
       */
      [Types.desktop]?: Platform;

      /**
       * Tablet specific styling
       */
      [Types.tablet]?: Platform;

      /**
       * Mobile specific styling
       */
      [Types.mobile]?: Platform;
    }
  }

  /**
   * The available types of article content sections
   */
  export namespace Content {
    /**
     * Options for available domains or paths
     */
    export interface Availability {
      public: boolean;
      my_aging_plan: boolean;
      advisor_portals: boolean;
    }

    /**
     * A two-column image section
     */
    export interface TwoColumnImage extends MediaQuery.Platforms, Has.XOrientation, Has.SectionProp {
      /**
       * The title of the section
       */
      title: Styles.Text;

      /**
       * The subtitle of the section
       */
      subtitle: Styles.Text;

      /**
       * The main body of text
       */
      body: Styles.Text;
    }

    /**
     * A Text Overlay section
     */
    export interface TextOverlay extends MediaQuery.Platforms, Has.XOrientation, Has.SectionProp {
      /**
       * Displays the given number next to the title
       */
      number: string;

      /**
       * Adjust styling on text
       */
      is_content?: boolean;

      /**
       * The title of the section
       */
      title: Styles.Text;

      /**
       * The subtitle of the section
       */
      subtitle: Styles.Text;

      /**
       * The main body of text
       */
      body: Styles.Text;
    }

    /**
     * A Image Overlay section
     */
    export interface ImageOverlay extends MediaQuery.Platforms, Has.XOrientation, Has.SectionProp {
      /**
       * Specifies the height of the 'gray' element below the image.
       */
      grayBottomHeight?: string;

      /**
       * The title of the section
       */
      title?: Styles.Text;

      /**
       * The main body of text
       */
      body?: Styles.Text;
    }

    /**
     * A Lottie Overlay section
     */
    export interface LottieOverlay extends MediaQuery.Platforms, Has.XOrientation, Has.SectionProp {
      /**
       * Specifies the height of the 'gray' element below the image.
       */
      grayBottomHeight?: string;

      /**
       * The title of the section
       */
      title?: Styles.Text;

      /**
       * The main body of text
       */
      body?: Styles.Text;
    }

    /**
     * A Horizontal Rule section
     */
    export interface HorizontalRule extends MediaQuery.Platforms, Has.Animation, Has.Container, Has.SectionProp {
      innerHTML: string;
      style: string;
      backgroundColor: string;
    }

    /**
     * A CTA Horizontal Rule section
     */
    export interface CtaHorizontalRule extends HorizontalRule {
      button_classes: string;
      button_label: string;
      button_link: string;
    }

    /**
     * A One Two Three section
     */
    export interface OneTwoThree extends Has.SectionProp {
      /**
       * The 'one' part of the section
       */
      one: OneTwoThreePart;

      /**
       * The 'two' part of the section
       */
      two: OneTwoThreePart;

      /**
       * The 'three' part of the section
       */
      three: OneTwoThreePart;
    }

    /**
     * A One Two Three section part
     */
    interface OneTwoThreePart {
      /**
       * The title of the section
       */
      title: Styles.Text;

      /**
       * The body of the section
       */
      body: Styles.Text;
    }

    /**
     * A Full Width Image section
     */
    export interface FullWidthImage extends MediaQuery.Platforms, Has.SectionProp {
      /**
       * The image source
       */
      src?: string;

      /**
       * The title of the section
       */
      title: string;
    }

    /**
     * A Full Width Video section
     */
    export interface FullWidthVideo extends MediaQuery.Platforms, Has.XOrientation, Has.SectionProp {
      /**
       * The title of the section
       */
      innerHTML: string;

      /**
       * The video background
       */
      video: Styles.Video;

    }

    /**
     * Video Section
     */
    export interface VideoSection extends Has.XOrientation, Has.SectionProp {
      /**
       * Background Color
       */
      backgroundColor: string;

      /**
       * Title
       */
      title: MediaQuery.Platforms;

      /**
       * Background
       */
      background: MediaQuery.Platforms;

      /**
       * Thumbnail
       */
      thumbnail: MediaQuery.Platforms;

      /** Video */
      video: {
        videoSrc: string;
      };

      /** InnerHTML */
      innerHTML: string;
    }

    /**
     * A Video landing section
     */
    export interface VideoLanding extends Has.SectionProp {
      /**
       * The title of the section
       */
      title: string;

      /**
       * The subtitle of the section
       */
      subtitle: string;

      /**
       * The video background
       */
      video: string;

      /** Background Color */
      backgroundColor: string;

      /**
       * Thumbnail
       */
      thumbnail: Styles.Background;
    }

    /**
     * A Semi-circle section
     */
    export interface SemiCircle extends Styles.Background, Has.XOrientation, MediaQuery.Platforms, Has.SectionProp {
      /**
       * The body of the section
       */
      body: Styles.Text;

      /**
       * The title of the section
       */
      title: Styles.Text;
    }

    /**
     * An Images and Text section
     */
    export interface ImagesAndText extends Has.XOrientation, MediaQuery.Platforms, Has.SectionProp {
      /**
       * Background color
       */
      backgroundColor: string;

      /**
       * The section image
       */
      sectionImage: Styles.Image;

      /**
       * The bottom image
       */
      bottomImage: Styles.Image;

      /**
       * The center image
       */
      centerImage: Styles.Image;

      /**
       * The title of the section
       */
      title: Styles.Text;
    }

    /**
     * PDF Style Enum
     */
    export enum PdfStyle {
      static = 'static',
      carousel = 'carousel',
    }

    export interface Pdfs extends Has.Animation, Has.SectionProp {
      pdfs: Pdf[];
      /**
       * The button text in the popup
       */
      popup_button_text: string;

      /**
       * Section Style
       */
      style: PdfStyle;

      /**
       * Description
       */
      description: string;

      /**
       * Title
       */
      title: string;

      /**
       * Title Color
       */
      titleColor: string;

      /**
       * Background Color
       */
      backgroundColor: string;

      /**
       * Background Image
       */
      backgroundImage: Styles.Background;

      /**
       * Button Background Color
       */
      btnBackgroundColor: string;

      /**
       * Button Border Color
       */
      btnBorderColor: string;

      /**
       * Button Text Color
       */
      btnTextColor: string;
    }

    /**
     * A PDF section
     */
    export interface Pdf extends Has.Button, Has.Animation, Has.SectionProp {
      /**
       * The PDF source
       */
      src: string;

      /**
       * The PDF thumbnail
       */
      thumbnail: MediaQuery.Platforms;

      /**
       * The PDF title
       */
      title: string;

      /**
       * The background
       */
      background: Styles.Background;

      /**
       * The PDF description
       */
      description: string;

      /**
       * The HTMLImageElement this is styling
       */
      imageElement?: HTMLImageElement;

      /**
       * The HTMLAnchorElement this is using
       */
      anchorElement?: HTMLAnchorElement;
    }

    /**
     * An Embed section
     */
    export interface Embed extends Has.SectionProp, Sets.Height, MediaQuery.Platforms {
      /**
       * The embed source
       */
      embed: string;

      /**
       * The embed type
       */
      embedType: string;

      /**
       * background color
       */
      backgroundColor: string;
    }

    /**
     * Full Width Lottie
     */
    export interface FullWidthLottie extends MediaQuery.Platforms, Has.SectionProp { }


    export interface Footer {
      /**
       * The disclosure of the article
       */
      disclosure: string;

      /**
       * The email of the affiliate
       */
      email: string;

      /**
       * The phone number of the affiliate
       */
      phone: string;
    }

    /**
     * A union of all section types
     */
    export type Types = TwoColumnImage | TextOverlay | ImageOverlay | HorizontalRule | CtaHorizontalRule | OneTwoThree | FullWidthImage | FullWidthVideo | FullWidthLottie | ImagesAndText | Pdfs | Pdf | Embed | ImagesAndText | SemiCircle | VideoLanding | VideoSection | FullWidthVideo | LottieOverlay;
    // export type Types = TwoColumnImage & TextOverlay & ImageOverlay & HorizontalRule & CtaHorizontalRule & OneTwoThree & FullWidthImage & FullWidthVideo & ImagesAndText & Pdfs & Pdf & Embed;
  }
}


/**
 * OLD PDF Section Schema
 */

export interface OldPdf {
  btnBackgroundColor: string;
  btnBorderColor: string;
  btnLabelHTML: string;
  btnTextColor: string;
  src: string;
  title: string;
  button?: ArticleInterfacing.Styles.Button;
}

export interface ArticleGroup {
  title: string;
  group: string;
}
