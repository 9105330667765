declare global {
  interface String {
    fromSnake(): string;
    camelToSnakeCase(): string;
    camelToHuman(): string;
    camelToHumanWithOutSpace(): string;
    toSnakeCase(): string;
    snakeToCamelCase(): string;
    toCamelCase(): string;
    capitalizeFirstLetter(): string;
    capitalize(): string;
    uppercaseMinLengthWords(length: number): string;
    uppercaseSpecificWords(words: string[]): string;
    camelToCss(): string;
    singularize(): string;
    kababToSnake(): string;
    slug(seperator?: string, language?: string, dictionary?: any): string;
    ascii(language: string): string;
    normalizePlural(): string;
    toPhoneNumber(): string;
  }
}

/**
 * Singularize a string. e.g. 'dogs' = 'dog'
 * @returns {string}
 */
String.prototype.singularize = function () {
  const endings = {
    ves: 'fe',
    ies: 'y',
    i: 'us',
    zes: 'ze',
    ses: 's',
    es: 'e',
    s: '',
  };
  return this.replace(new RegExp(`(${Object.keys(endings).join('|')})$`), (r) => endings[r as keyof typeof endings]);
};

/**
 * Convert a Camel Case string to a human readable string
 */
String.prototype.camelToHuman = function () {
  return (this.match(/[A-Za-z0-9][a-z]*/g) || []).join(' ');
};

/**
 * Convert a Camel Case string to a human readable string without adding spaces between uppercase letters
 */
String.prototype.camelToHumanWithOutSpace = function () {
  return this.replace(/([a-z])([A-Z])/g, '$1 $2');
};

/**
 * Convert a snake case string to camel case
 */
String.prototype.snakeToCamelCase = function () {
  return this.replace(/(_\w)/g, (match) => match[1].toUpperCase());
};

/**
 * Convert a Snake Case string to a human readable
 */
String.prototype.fromSnake = function () {
  return this.replace(/_/g, ' ');
};

/**
 * Convert a Camel Case string to a Snake Case string
 */
String.prototype.camelToSnakeCase = function () {
  return this.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);
};

/**
 * Convert a Human readable string to Snake Case
 */
String.prototype.toSnakeCase = function () {
  return this.split(' ')
    .map((word) => word.toLowerCase())
    .join('_');
};

/**
 * Convert a string to Phone Number
 */
String.prototype.toPhoneNumber = function () {
  return this.replace(/^(\d{3})(\d{3})(\d{4})$/, '$1.$2.$3');
};

/**
 * Convert a Human readable string to Camel Case
 * @returns {string} 'Foo Bar' = 'fooBar'
 */
String.prototype.toCamelCase = function () {
  return this.split(' ')
    .map((word, index) => {
      if (index === 0) {
        return word.toLowerCase();
      }
      return word.capitalizeFirstLetter();
    })
    .join('');
};

/**
 * Capitalize the first letter of a string
 */
String.prototype.capitalizeFirstLetter = function () {
  return this.charAt(0).toUpperCase() + this.slice(1).toLowerCase();
};

/**
 * Capitalize the first letter of every word in a string
 * @returns {string} 'fOo BAR' = 'Foo Bar'
 */
String.prototype.capitalize = function () {
  return this.split(' ')
    .map((word) => word.capitalizeFirstLetter())
    .join(' ');
};

/**
 * Capitalize all words that are equal to or less than the given length
 * @param {number} length - The minimum length of words to capitalize
 * @returns {string}
 */
String.prototype.uppercaseMinLengthWords = function (length: number) {
  return this.split(' ')
    .map((word) => (word.length <= length ? word.toUpperCase() : word))
    .join(' ');
};

/**
 * Capitalize all words that are included in the given array
 * @param {string[]} words - The words to capitalize
 * @returns {string}
 */
String.prototype.uppercaseSpecificWords = function (words: string[]) {
  return this.split(' ')
    .map((word) => (words.includes(word) ? word.toUpperCase() : word))
    .join(' ');
};

/**
 * Convert a Camel Case string to a Css property string
 */
String.prototype.camelToCss = function () {
  return this.replace(/[A-Z]/g, (letter) => `-${letter.toLowerCase()}`);
};

/**
 * Convert a Kabab Case string to a Snake Case string
 */
String.prototype.kababToSnake = function () {
  return this.split('-')
    .map((word, index) => {
      return word;
    })
    .join('_');
};

/**
 * Transliterate a UTF-8 value to ASCII.
 * @param {string} language - The language to transliterate to. Defaults to 'en'
 */
String.prototype.ascii = function (language = 'en') {
  return (
    this.toLowerCase()
      // Transliterate a UTF-8 value to ASCII.
      .normalize(language === 'en' ? 'NFD' : 'NFKD')
  );
};

/**
 * Generate a URL friendly "slug" from a given string.
 * @param {string} separator - The separator to use. Defaults to '-'
 * @param {string} language - The language to transliterate to. Defaults to 'en'
 * @param {any} dictionary - The dictionary to use for replacing words. Defaults to { '@': 'at' }
 * @returns {string}
 */
String.prototype.slug = function (separator = '-', language = 'en', dictionary = { '@': 'at' }) {
  let string = language ? this.ascii(language) : this;

  // Convert all dashes/underscores into separator
  const flip = separator === '-' ? '_' : '-';

  string = string.replace(new RegExp('[' + flip.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') + ']+', 'gu'), separator);

  // Replace dictionary words
  for (const [key, value] of Object.entries(dictionary)) {
    dictionary[key as keyof typeof dictionary] = separator + value + separator;
  }

  string = string.replace(
    new RegExp(
      Object.keys(dictionary)
        .map((key) => {
          // Quote regular expression characters
          return key.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
        })
        .join('|'),
      'gu',
    ),
    (match) => dictionary[match as keyof typeof dictionary],
  );

  // Remove all characters that are not the separator, letters, numbers, or whitespace
  string = string.replace(/[^\p{L}\p{N}\s]+/gu, '').toLowerCase();

  // Replace all separator characters and whitespace by a single separator
  string = string.replace(new RegExp('[' + separator + '\\s]+', 'gu'), separator);

  return string.trim();
};

/**
 * Normalize the string to handle singular and plural forms.
 */
String.prototype.normalizePlural = function () {
  if (!this) return '';
  const string = this.toLowerCase().trim();
  // Handle pluralization by removing common plural suffixes
  if (string.endsWith('ies')) {
    return string.slice(0, -3) + 'y';
  } else if (string.endsWith('es')) {
    return string.slice(0, -2);
  } else if (string.endsWith('s')) {
    return string.slice(0, -1);
  }
  return string;
};

export {};
