<label #labelElement class="form-label" [innerText]="label" *ngIf="label?.length && !grouped"></label>

<ng-container *ngIf="grouped">
  <div class="input-group-prepend">
    <span class="input-group-text">
      <ng-content></ng-content>
      &nbsp;<span *ngIf="label" [innerText]="label"></span>
    </span>
  </div>
</ng-container>

<form-checkbox
  *ngFor="let option of options"
  type="radio"
  [id]="option.id!"
  [name]="name"
  [(ngModel)]="option.checked"
  [checked]="option.value === formControl.value"
  [inline]="inline"
  [value]="option.value"
  (change)="onChanged(option)">
  {{ option.label }}
</form-checkbox>

<ng-content></ng-content>

<div class="invalid-feedback" [id]="name + '_invalid_feedback'">
  <ng-container *ngIf="errors?.required">This is required</ng-container>
</div>
