<ng-container *ngIf="!visible">
  <div class="toggle border-1" (click)="onMakeVisibleClick()">
    <span class="title" [innerText]="title"></span>
    <b class="subtitle" [innerText]="subtitle"></b>
  </div>
</ng-container>

<ng-container *ngIf="visible">
  <ng-content></ng-content>
</ng-container>
